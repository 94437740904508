import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ResetPassword from '../../components/Reset-Password/ResetPassword';
import SetPassword from '../../components/Reset-Password/SetPassword';

class ResetPasswordWrapper extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          key="ResetPassword"
          path="/reset-password/"
          component={ResetPassword}
          exact
        />
        <Route
          key="SetPassword"
          path="/reset-password/token/:code"
          component={SetPassword}
        />
      </Switch>
    );
  }
}

export default ResetPasswordWrapper;
