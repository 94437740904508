import { defineMessages } from 'react-intl';

const messages = defineMessages({
  id: {
    id: 'product.id',
    defaultMessage: 'ID',
    description: 'Admin product ID label',
  },
  key: {
    id: 'product.key',
    defaultMessage: 'Key',
    description: 'Admin product key label',
  },
  name: {
    id: 'product.products.name',
    defaultMessage: 'Name',
    description: 'Admin product name label',
  },
  shortDescription: {
    id: 'product.shortDescription',
    defaultMessage: 'Short description',
    description: 'Admin product shortDescription label',
  },
  description: {
    id: 'product.description',
    defaultMessage: 'Description',
    description: 'Admin product description label',
  },
  createdBy: {
    id: 'product.createdBy',
    defaultMessage: 'Created by',
    description: 'Admin product createdBy label',
  },
  updatedBy: {
    id: 'product.updatedBy',
    defaultMessage: 'Updated by',
    description: 'Admin product updatedBy label',
  },
  createdAt: {
    id: 'product.createdAt',
    defaultMessage: 'Created at',
    description: 'Admin product createdAt label',
  },
  updatedAt: {
    id: 'product.updatedAt',
    defaultMessage: 'Updated at',
    description: 'Admin product licenses/lab label',
  },
  title: {
    id: 'product.title',
    defaultMessage: 'Product',
    description: 'Header of product edit dialog',
  },
  alertSuccessTitle: {
    id: 'product.alertSuccessTitle',
    defaultMessage: 'Success',
    description: 'Header for success alert dialog',
  },
  alertSuccessMessage: {
    id: 'product.alertSuccessMessage',
    defaultMessage: 'Product updated successfully!',
    description: 'Message for success alert dialog',
  },
  alertErrorTitle: {
    id: 'product.alertErrorTitle',
    defaultMessage: 'Error',
    description: 'Header for error alert dialog',
  },
  alertErrorMessage: {
    id: 'product.alertErrorMessage',
    defaultMessage: 'Failed to update product.',
    description: 'Message for error alert dialog',
  },
});

export default messages;
