import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import SweetAlert from 'react-bootstrap-sweetalert';

import gate from 'components/Auth/gate';
import rolesObject from '../../core/roles';
import messages from './messages';
import s from './EditUser.scss'; // eslint-disable-line css-modules/no-unused-class
import Loading from '../modules/common/Loading/Loading';
import EditUserForm from './EditUserForm';

class EditUser extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      username: PropTypes.string,
    }),
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    me: PropTypes.shape({
      id: PropTypes.number,
      userRoles: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  };

  static defaultProps = {
    user: {
      id: '',
      username: '',
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
      closeAfterSaving: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertAndClose = this.hideAlertAndClose.bind(this);
    this.closeAfterSaving = this.closeAfterSaving.bind(this);
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });
  }

  hideAlertAndClose() {
    const { history } = this.props;
    const { closeAfterSaving } = this.state;
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });

    if (closeAfterSaving) {
      history.push({
        pathname: '/admin/users',
      });
    }
  }

  async handleSubmit(formValues) {
    this.hideAlert();
    const {
      updateUser,
      match: { params },
      user,
    } = this.props;
    const { closeAfterSaving } = this.state;

    const { username, password, active, userRoles } = formValues;
    const patch = {
      username,
      password,
      active,
    };

    if (userRoles && !_.isMatchWith(user.userRoles, userRoles, _.isEqual)) {
      patch.userRoles = userRoles;
    }
    const result = await updateUser(params.id, patch);

    if (!result || result.status === 401) {
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.alertErrorTitle} />,
          msg: <FormattedMessage {...messages.alertErrorMessage} />,
          onConfirm: this.hideAlert,
        },
      });
    } else {
      this.setState({
        alertOpts: {
          show: true,
          type: 'success',
          title: <FormattedMessage {...messages.alertSuccessTitle} />,
          msg: <FormattedMessage {...messages.alertSuccessMessage} />,
          onConfirm: closeAfterSaving ? this.hideAlertAndClose : this.hideAlert,
        },
      });
    }
  }

  async closeAfterSaving(shouldClose) {
    return this.setState(
      {
        closeAfterSaving: shouldClose,
      },
      () => Promise.resolve(),
    );
  }

  render() {
    const { loading, user } = this.props;
    const { alertOpts } = this.state;
    const {
      history,
      me,
      match: { params },
    } = this.props;

    const canManageUsers = gate.hasRole({
      user: me,
      role: rolesObject.MANAGE_USERS,
    });

    if (me.id !== parseInt(params.id, 10) && !canManageUsers) {
      history.push({
        pathname: '/admin',
      });
      return <Loading />;
    }

    if (loading || !user) {
      return <Loading />;
    }

    return (
      <div>
        {/* eslint-disable-next-line prettier/prettier */}
        <h2><FormattedMessage {...messages.title} /></h2>
        <EditUserForm
          onSubmit={this.handleSubmit}
          closeAfterSaving={this.closeAfterSaving}
          deleteUser={() => this.props.history.push('/delete-account')}
          initialValues={user}
        />
        {alertOpts.show && (
          <SweetAlert
            type={alertOpts.type}
            title={alertOpts.title}
            onConfirm={alertOpts.onConfirm}
          >
            {alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const userQuery = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      username
      email
      active
      userRoles
    }
  }
`;

const updateUserMutation = gql`
  mutation updateUser($id: ID!, $patch: UserInput!) {
    updateUser(id: $id, patch: $patch) {
      id
      username
      email
      active
      userRoles
    }
  }
`;

const mapStateToProps = state => ({
  me: state.user,
});

export default compose(
  graphql(userQuery, {
    name: 'userQuery',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
    props: ({ userQuery: { loading, user } }) => ({
      loading,
      user,
    }),
  }),
  graphql(updateUserMutation, {
    props: ({ mutate }) => ({
      updateUser: (id, patch) =>
        mutate({
          variables: { id, patch },
        }),
    }),
  }),
)(withStyles(s)(connect(mapStateToProps)(EditUser)));
