import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { Provider as ReduxProvider, connect } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { withRouter } from 'react-router';

import RouteBundler from './RouteBundler';
import routes from '../routes';
import Layout from './Layout';
import AdminLayout from './AdminLayout';

const ContextType = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: PropTypes.func.isRequired,
  // Universal HTTP client
  fetch: PropTypes.func.isRequired,
  query: PropTypes.object,
  // Integrate Redux
  // http://redux.js.org/docs/basics/UsageWithReact.html
  ...ReduxProvider.childContextTypes,
  // Apollo Client
  client: PropTypes.object.isRequired,
  // ReactIntl
  intl: IntlProvider.childContextTypes.intl,
  locale: PropTypes.string,
};

const mapIntlStateToProps = state => ({
  locale: state.intl.locale,
  messages: state.intl.messages[state.intl.locale],
});
const ConnectedIntlProvider = connect(mapIntlStateToProps)(IntlProvider);

/**
 * The top-level React component setting context (global) variables
 * that can be accessed from all the child components.
 *
 * https://facebook.github.io/react/docs/context.html
 *
 * Usage example:
 *
 *   const context = {
 *     history: createBrowserHistory(),
 *     store: createStore(),
 *   };
 *
 *   ReactDOM.render(
 *     <App context={context}>
 *       <Layout>
 *         <LandingPage />
 *       </Layout>
 *     </App>,
 *     container,
 *   );
 */
class App extends React.PureComponent {
  static propTypes = {
    context: PropTypes.shape(ContextType).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  };

  static childContextTypes = ContextType;

  getChildContext() {
    return this.props.context;
  }

  // componentDidMount() {
  //   const store = this.props.context && this.props.context.store;
  //   if (store) {
  //     this.lastLocale = store.getState().intl.locale;
  //     this.unsubscribe = store.subscribe(() => {
  //       const state = store.getState();
  //       const { newLocale, locale } = state.intl;
  //       if (!newLocale && this.lastLocale !== locale) {
  //         console.log('force update');
  //         this.lastLocale = locale;
  //         this.forceUpdate();
  //       }
  //     });
  //   }
  // }

  // componentWillUnmount() {
  //   if (this.unsubscribe) {
  //     this.unsubscribe();
  //     this.unsubscribe = null;
  //   }
  // }

  render() {
    const { client } = this.props.context;
    const { location } = this.props;
    const LayoutComponent =
      location.pathname.indexOf('admin') !== -1 ? AdminLayout : Layout;

    return (
      <ApolloProvider client={client}>
        <ConnectedIntlProvider>
          <LayoutComponent>
            <RouteBundler routes={routes} />
          </LayoutComponent>
        </ConnectedIntlProvider>
      </ApolloProvider>
    );
  }
}

export default withRouter(App);
