import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import TrialActivationModal from 'components/TrialActivationModal';
import StripePayment from 'components/StripePayment';
import productsConfig from 'core/product';

import s from './Dashboard.scss';
import messages from './messages';
import ProductCard from './ProductCard';
import professor from './professor.svg';
import labLogo from './lab.svg';
import forumLogo from './forum.svg';
import adminLogo from './admin.svg';

const products = [
  {
    id: productsConfig.atoms.lab.id,
    name: 'Lab',
    description: <FormattedMessage {...messages.labDescription} />,
    color: '#1a1a1a',
    backgroundColor: '#fff',
    icon: labLogo,
    url: '/lab',
  },
  {
    name: 'Yoda',
    description: <FormattedMessage {...messages.yodaDescription} />,
    color: '#1a1a1a',
    backgroundColor: '#fff',
    icon: adminLogo,
    url: '/admin',
  },
  {
    name: 'Forum',
    description: <FormattedMessage {...messages.forumDescription} />,
    color: '#1a1a1a',
    backgroundColor: '#fff',
    icon: forumLogo,
    url: 'https://forum.waltzing.at',
  },
];

const isLocked = ({ id, me }) => {
  if (id) {
    switch (id) {
      case productsConfig.atoms.lab.id:
        return !me.hasActiveAtomsLabSubscription;
      default:
        return false;
    }
  }

  return false;
};

class Dashboard extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      hasSeenTrialPopup: PropTypes.bool.isRequired,
      hasActiveAtomsLabSubscription: PropTypes.bool.isRequired,
    }).isRequired,
    updateHasSeenTrialPopup: PropTypes.func.isRequired,
    user: PropTypes.shape({
      isLoggedIn: PropTypes.bool.isRequired,
    }),
  };

  static defaultProps = {
    user: null,
  };

  constructor(props) {
    super(props);
    const {
      me: { hasSeenTrialPopup, hasActiveAtomsLabSubscription },
    } = props;

    this.handleProductClick = this.handleProductClick.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleTrialPopupSeen = this.handleTrialPopupSeen.bind(this);

    let showTrialActivatedModal = false;

    if (!hasSeenTrialPopup && hasActiveAtomsLabSubscription) {
      showTrialActivatedModal = true;
    }

    this.state = {
      modals: {
        payment: {
          show: false,
        },
        trialActivated: {
          show: showTrialActivatedModal,
        },
      },
    };
  }

  async handleTrialPopupSeen() {
    const update = { ...this.state };
    update.modals.trialActivated.show = false;
    this.setState(update);
    const { updateHasSeenTrialPopup } = this.props;
    await updateHasSeenTrialPopup({
      id: this.props.me.id,
      hasSeenTrialPopup: true,
    });
  }

  openModal(key) {
    const update = { ...this.state };
    update.modals[key].show = true;
    this.setState(update);
  }

  closeModal(key) {
    const update = { ...this.state };
    update.modals[key].show = false;

    this.setState(update);

    if (key === 'trialActivated') {
      this.handleTrialPopupSeen();
    }
  }

  handleProductClick(product) {
    const { me } = this.props;
    const { id, url } = product;

    if (isLocked({ id, me })) {
      this.openModal('payment');
    } else {
      window.location.href = url;
    }
  }

  render() {
    const { me, user } = this.props;

    if (!user || !user.isLoggedIn) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <Row>
        <Col xs={12} style={{ position: 'relative' }}>
          <img src={professor} alt="" className={s.professor} />
          <h1 className="text-center" style={{ marginBottom: '20px' }}>
            <FormattedMessage {...messages.heading} />
          </h1>
          <div className={s.products}>
            {products.map(product => (
              <div key={product.name}>
                <ProductCard
                  {...product}
                  locked={isLocked({ id: product.id, me })}
                  handleClick={() => this.handleProductClick(product)}
                />
              </div>
            ))}
          </div>
          <Modal
            show={this.state.modals.trialActivated.show}
            onHide={() => this.closeModal('trialActivated')}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage {...messages.trialLicense} />
              </Modal.Title>
              <Modal.Body>
                <TrialActivationModal
                  handleTrialPopupSeen={this.handleTrialPopupSeen}
                />
              </Modal.Body>
            </Modal.Header>
          </Modal>
          <Modal
            show={this.state.modals.payment.show}
            onHide={() => this.closeModal('payment')}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage {...messages.purchaseLicense} />
              </Modal.Title>
              <Modal.Body>
                <StripePayment
                  afterSuccess={() => this.closeModal('payment')}
                />
              </Modal.Body>
            </Modal.Header>
          </Modal>
        </Col>
      </Row>
    );
  }
}

const updateHasSeenTrialPopupMutation = gql`
  mutation updateHasSeenTrialPopup($id: ID!, $hasSeenTrialPopup: Boolean!) {
    updateHasSeenTrialPopup(id: $id, hasSeenTrialPopup: $hasSeenTrialPopup) {
      id
      hasSeenTrialPopup
    }
  }
`;

const mapStateToProps = state => ({
  user: state.user,
});

export default compose(
  AuthenticationWrapper,
  graphql(updateHasSeenTrialPopupMutation, {
    props: ({ mutate }) => ({
      updateHasSeenTrialPopup: data => mutate({ variables: { ...data } }),
    }),
  }),
)(withStyles(s)(connect(mapStateToProps)(Dashboard)));
