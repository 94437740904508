import { defineMessages } from 'react-intl';

const messages = defineMessages({
  alertSuccessTitle: {
    id: 'delete-account.alertSuccessTitle',
    defaultMessage: 'Success',
    description: 'Header for success alert dialog',
  },
  alertSuccessMessage: {
    id: 'delete-account.alertSuccessMessage',
    defaultMessage: 'User deleted successfully!',
    description: 'Message for success alert dialog',
  },
  alertErrorTitle: {
    id: 'delete-account.alertErrorTitle',
    defaultMessage: 'Error',
    description: 'Header for error alert dialog',
  },
  alertErrorMessage: {
    id: 'delete-account.alertErrorMessage',
    defaultMessage: 'Deleting user failed!',
    description: 'Message for error alert dialog',
  },
});

export default messages;
