exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._26pKB{width:100%;background:#000}ul._1_O8I{display:table;margin:0 auto;padding-left:0;list-style-type:none}ul._1_O8I li{display:inline-block;padding:10px}ul._1_O8I li a{color:#dcdcdc}ul._1_O8I li a:active,ul._1_O8I li a:focus,ul._1_O8I li a:hover{color:#aec2cc}", ""]);

// exports
exports.locals = {
	"container": "_26pKB",
	"links": "_1_O8I"
};