import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './ProductInfo.scss';
import TableStyle from '../Table/Table.scss'; // eslint-disable-line css-modules/no-unused-class
import Loading from '../modules/common/Loading/Loading';
import messages from './messages';

class ProductInfo extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      product: PropTypes.object,
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  render() {
    if (this.props.data.loading) {
      return <Loading />;
    }

    const { product } = this.props.data;
    const title = <FormattedMessage {...messages.title} />;

    return (
      <div>
        <h2>{title}</h2>
        <div className={s.rowEven}>
          <span className={s.label}>
            <FormattedMessage {...messages.id} />
          </span>
          {product.id}
        </div>
        <div className={s.rowUneven}>
          <span className={s.label}>
            <FormattedMessage {...messages.key} />
          </span>
          {product.key}
        </div>
        <div className={s.rowEven}>
          <span className={s.label}>
            <FormattedMessage {...messages.name} />
          </span>
          {product.name}
        </div>
        <div className={s.rowUneven}>
          <span className={s.label}>
            <FormattedMessage {...messages.shortDescription} />
          </span>
          {product.shortDescription}
        </div>
        <div className={s.rowEven}>
          <span className={s.label}>
            <FormattedMessage {...messages.description} />
          </span>
          {product.description}
        </div>
      </div>
    );
  }
}

const productQuery = gql`
  query product($id: Int!) {
    product(id: $id) {
      id
      key
      name
      shortDescription
      description
    }
  }
`;

export default compose(
  graphql(productQuery, {
    name: 'data',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
  }),
)(withStyles(s, TableStyle)(ProductInfo));
