exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._16QpB{margin-bottom:15px}._1ZDwl,._1ZDwl:active,._1ZDwl:focus,._1ZDwl:hover{display:block;-webkit-box-sizing:border-box;box-sizing:border-box;margin:0;padding:10px 16px;width:100%;outline:0;border-radius:0;color:#fff;text-align:center;text-decoration:none;line-height:1.3333333;cursor:pointer}._3nNu1{border:2px solid #1eb4aa;background:#fff;color:#1eb4aa;font-weight:700}._3nNu1:hover{background:#1eb4aa;color:#fff}._2nnrO{border-color:#0065dd;background:#0065dd}._2nnrO:hover{background:#001e50}._2ytMX{border-color:#ff320f;background:#ff320f}._2ytMX:hover{background:#f0a014}._2iKKP{display:inline-block;margin:-2px 12px -2px 0;width:20px;height:20px;vertical-align:middle;fill:currentColor}._1-hg6{position:relative;z-index:1;display:block;margin-bottom:15px;width:100%;color:#757575;text-align:center;font-size:80%}._1-hg6:before{position:absolute;top:50%;left:50%;z-index:-1;margin-top:-5px;margin-left:-20px;width:40px;height:10px;background-color:#fff;content:\"\"}._1-hg6:after{position:absolute;top:49%;z-index:-2;display:block;width:100%;border-bottom:1px solid #ddd;content:\"\"}._3BoSD:after{top:43%}._2c53E{display:block;width:100%;margin:0;padding:5px;text-align:right}.w_mQl{color:#646464;font-size:12.8px;font-size:.8rem}", ""]);

// exports
exports.locals = {
	"formGroup": "_16QpB",
	"button": "_1ZDwl",
	"skipButton": "_3nNu1 _1ZDwl",
	"facebook": "_2nnrO _1ZDwl",
	"google": "_2ytMX _1ZDwl",
	"icon": "_2iKKP",
	"lineThrough": "_1-hg6",
	"lineThroughBelowForm": "_3BoSD",
	"passwordResetLinkContainer": "_2c53E",
	"passwordResetLink": "w_mQl"
};