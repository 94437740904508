import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import FaSearchPlus from 'react-icons/lib/fa/search-plus';
import FaEdit from 'react-icons/lib/fa/edit';
import FaDelete from 'react-icons/lib/fa/times-circle';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Table from '../Table/Table';
import TableStyle from '../Table/Table.scss'; // eslint-disable-line css-modules/no-unused-class
import Loading from '../modules/common/Loading/Loading';
import componentMessages from '../messages';

class UsersTable extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      users: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.initColumns = this.initColumns.bind(this);
    this.showDeletePrompt = this.showDeletePrompt.bind(this);

    this.initColumns();
  }
  initColumns() {
    this.columns = [
      {
        Header: 'Benutzername',
        accessor: 'username',
        minWidth: 200,
        filterable: true,
        filterMethod: (filter, row) => {
          const username = row[filter.id];
          const search = filter.value;
          const regexp = new RegExp(search, 'gi');
          return username.match(regexp);
        },
      },
      {
        Header: 'E-Mail-Adresse',
        accessor: 'email',
        minWidth: 300,
        filterable: true,
        filterMethod: (filter, row) => {
          const email = row[filter.id];
          const search = filter.value;
          const regexp = new RegExp(search, 'gi');
          return email.match(regexp);
        },
      },
      {
        Header: 'Gruppen',
        accessor: 'groups',
        minWidth: 200,
      },
      {
        Header: 'Aktiviert',
        accessor: 'activated',
        width: 100,
      },
      {
        Header: 'Gesperrt',
        accessor: 'locked',
        width: 100,
      },
      {
        Header: 'Erstellt am',
        accessor: 'createdAt',
        minWidth: 250,
      },
      {
        Header: 'Benutzer imitieren',
        accessor: 'limit',
        minWidth: 200,
      },
      {
        Header: <FormattedMessage {...componentMessages.actionHeader} />,
        width: 360,
        Cell: row => (
          <div>
            <Link to={`users/${row.original.id}/show`}>
              <button className={TableStyle.buttonLeft}>
                <FaSearchPlus className="fa--prepended" />
                <FormattedMessage {...componentMessages.show} />
              </button>
            </Link>
            <Link to={`users/${row.original.id}/edit`}>
              <button className={TableStyle.buttonMiddle}>
                <FaEdit className="fa--prepended" />
                <FormattedMessage {...componentMessages.edit} />
              </button>
            </Link>
            <button
              className={TableStyle.buttonRight}
              onClick={() => this.showDeletePrompt(row.original.id)}
            >
              <FaDelete className="fa--prepended" />
              <FormattedMessage {...componentMessages.delete} />
            </button>
          </div>
        ),
      },
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  showDeletePrompt() {
    // TODO: implement
  }

  render() {
    if (this.props.data.loading) {
      return <Loading />;
    }

    const data = this.props.data.users.map(user => ({
      ...user,
      createdAt: user.createdAt
        ? moment(user.createdAt).format('DD.MM.YYYY HH:mm:ss')
        : '',
    }));

    return (
      <div>
        <Table isCheckable keyField="id" data={data} columns={this.columns} />
      </div>
    );
  }
}

const usersQuery = gql`
  query users {
    users {
      id
      username
      email
      createdAt
    }
  }
`;

export default compose(
  withStyles(TableStyle),
  graphql(usersQuery, {
    name: 'data',
  }),
)(UsersTable);
