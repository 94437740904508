exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1eAX_>li>a,._1eAX_>li[class^=\"dropdown open\"]>a{padding:2px;color:#000;background:none}._1eAX_>li>a:active,._1eAX_>li>a:focus,._1eAX_>li>a:hover,._1eAX_>li[class^=\"dropdown open\"]>a:active,._1eAX_>li[class^=\"dropdown open\"]>a:focus,._1eAX_>li[class^=\"dropdown open\"]>a:hover{text-decoration:none;color:#000;background:none}", ""]);

// exports
exports.locals = {
	"languageSwitcher": "_1eAX_"
};