import { defineMessages } from 'react-intl';

const messages = defineMessages({
  id: {
    id: 'user.id',
    defaultMessage: 'ID',
    description: 'Admin user ID label',
  },
  username: {
    id: 'user.username',
    defaultMessage: 'Username',
    description: 'Admin user username label',
  },
  password: {
    id: 'user.password',
    defaultMessage: 'Password',
    description: 'Admin user password label',
  },
  passwordConfirmation: {
    id: 'user.passwordConfirmation',
    defaultMessage: 'Confirm password',
    description: 'Admin user password confirmation label',
  },
  role: {
    id: 'user.role',
    defaultMessage: 'Role',
    description: 'Admin user role label',
  },
  active: {
    id: 'user.users.active',
    defaultMessage: 'Active',
    description: 'Admin user active label',
  },
  shortDescription: {
    id: 'user.shortDescription',
    defaultMessage: 'Short description',
    description: 'Admin user shortDescription label',
  },
  description: {
    id: 'user.description',
    defaultMessage: 'Description',
    description: 'Admin user description label',
  },
  createdBy: {
    id: 'user.createdBy',
    defaultMessage: 'Created by',
    description: 'Admin user createdBy label',
  },
  updatedBy: {
    id: 'user.updatedBy',
    defaultMessage: 'Updated by',
    description: 'Admin user updatedBy label',
  },
  createdAt: {
    id: 'user.createdAt',
    defaultMessage: 'Created at',
    description: 'Admin user createdAt label',
  },
  updatedAt: {
    id: 'user.updatedAt',
    defaultMessage: 'Updated at',
    description: 'Admin user licenses/lab label',
  },
  title: {
    id: 'user.title',
    defaultMessage: 'User',
    description: 'Header of user edit dialog',
  },
  alertSuccessTitle: {
    id: 'user.alertSuccessTitle',
    defaultMessage: 'Success',
    description: 'Header for success alert dialog',
  },
  alertSuccessMessage: {
    id: 'user.alertSuccessMessage',
    defaultMessage: 'User updated successfully!',
    description: 'Message for success alert dialog',
  },
  alertErrorTitle: {
    id: 'user.alertErrorTitle',
    defaultMessage: 'Error',
    description: 'Header for error alert dialog',
  },
  alertErrorMessage: {
    id: 'user.alertErrorMessage',
    defaultMessage: 'Updating user failed!',
    description: 'Message for error alert dialog',
  },
  deleteAccount: {
    id: 'user.deleteAccount',
    defaultMessage: 'Delete account',
    description: 'Label for delete account button',
  },
});

export default messages;
