import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaSave from 'react-icons/lib/fa/floppy-o';

import s from './EditClientToken.scss';
import messages from './messages';
import render from '../modules/form/renderField';
import validations from '../modules/form/validations';
import validationMessages from '../modules/form/messages';
import componentMessages from '../messages';

const validate = values => {
  const errors = {};

  if (!validations.required(values.name)) {
    errors.name = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.required(values.shortDescription)) {
    errors.shortDescription = (
      <FormattedMessage {...validationMessages.required} />
    );
  }
  if (!validations.required(values.description)) {
    errors.description = <FormattedMessage {...validationMessages.required} />;
  }

  return errors;
};

class EditProductForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    closeAfterSaving: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  render() {
    return (
      <div>
        <form onSubmit={this.props.handleSubmit}>
          <fieldset>
            <Field
              id="name"
              name="name"
              label={<FormattedMessage {...messages.name} />}
              type="text"
              component={render.renderInput}
            />
            <Field
              id="productId"
              name="productId"
              label={<FormattedMessage {...messages.productName} />}
              component={render.renderSelect}
            >
              <option value="">
                {this.props.intl.formatMessage(componentMessages.chooseOption)}
              </option>
              {this.props.products.map(product => (
                <option key={product.id} value={product.id}>
                  {product.name}
                </option>
              ))}
            </Field>
            <Field
              id="token"
              name="token"
              label={<FormattedMessage {...messages.token} />}
              type="text"
              component={render.renderInput}
            />
            <Field
              id="secret"
              name="secret"
              label={<FormattedMessage {...messages.secret} />}
              type="text"
              component={render.renderInput}
            />
            <Field
              id="redirectUris"
              name="redirectUris"
              label={<FormattedMessage {...messages.redirectUris} />}
              component={render.renderTextarea}
            />
            <Field
              id="grants"
              name="grants"
              label={<FormattedMessage {...messages.grants} />}
              component={render.renderTextarea}
            />
            <Field
              id="accessTokenLifetime"
              name="accessTokenLifetime"
              label={<FormattedMessage {...messages.accessTokenLifetime} />}
              type="number"
              component={render.renderInput}
            />

            {this.props.errors.length === 0 ? null : (
              <ul style={{ padding: '0', listStyleType: 'none' }}>
                {this.props.errors.map(err => (
                  <li key={err} className="bg-danger">
                    {err}
                  </li>
                ))}
              </ul>
            )}

            <button
              type="submit"
              className="btn btn-primary"
              onClick={formValues => {
                this.props.closeAfterSaving(false);
                this.props.handleSubmit(formValues);
              }}
            >
              <FaSave className="fa--prepended" />
              <FormattedMessage {...componentMessages.save} />
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={formValues => {
                this.props.closeAfterSaving(true);
                this.props.handleSubmit(formValues);
              }}
            >
              <FaSave className="fa--prepended" />
              <FormattedMessage {...componentMessages.saveAndClose} />
            </button>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'clientToken',
  validate,
  pure: false, // this is necessary to trigger form re-renders if the locale changes
})(injectIntl(withStyles(s)(EditProductForm)));
