exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "h2{margin-bottom:25px;font-family:Gotham Rounded A,Gotham Rounded B,HelveticaNeue-Light,Arial,sans-serif}._1q5fn{background:#fff}._1mMjL,._1q5fn{padding:10px;border:1px solid #dcdcdc;color:#222}._1mMjL{background:#ff320f}._3-bbU{padding:10px;background:#14a814;color:#222}._3kRcA{font-weight:700;display:inline-block;min-width:250px}hr{display:block;height:1px;border:0;border-top:1px solid #ccc;margin:1em 0;padding:0}", ""]);

// exports
exports.locals = {
	"rowEven": "_1q5fn",
	"notAccepted": "_1mMjL",
	"accepted": "_3-bbU",
	"label": "_3kRcA"
};