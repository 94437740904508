import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './ClientTokenInfo.scss';
import TableStyle from '../Table/Table.scss'; // eslint-disable-line css-modules/no-unused-class
import Loading from '../modules/common/Loading/Loading';
import messages from './messages';

class ClientTokenInfo extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    clientToken: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired,
      product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  render() {
    const { loading, clientToken } = this.props;
    if (loading || !clientToken) {
      return <Loading />;
    }

    return (
      <div>
        {/* eslint-disable-next-line prettier/prettier */}
        <h2><FormattedMessage {...messages.title} /></h2>
        <div className={s.rowEven}>
          <span className={s.label}>
            <FormattedMessage {...messages.id} />
          </span>
          {clientToken.id}
        </div>
        <div className={s.rowUneven}>
          <span className={s.label}>
            <FormattedMessage {...messages.name} />
          </span>
          {clientToken.name}
        </div>
        <div className={s.rowEven}>
          <span className={s.label}>
            <FormattedMessage {...messages.productName} />
          </span>
          {clientToken.product.name}
        </div>
        <div className={s.rowUneven}>
          <span className={s.label}>
            <FormattedMessage {...messages.token} />
          </span>
          {clientToken.token}
        </div>
        <div className={s.rowEven}>
          <span className={s.label}>
            <FormattedMessage {...messages.secret} />
          </span>
          {clientToken.secret}
        </div>
        <div className={s.rowUneven}>
          <span className={s.label}>
            <FormattedMessage {...messages.redirectUris} />
          </span>
          {clientToken.redirectUris}
        </div>
        <div className={s.rowEven}>
          <span className={s.label}>
            <FormattedMessage {...messages.grants} />
          </span>
          {clientToken.grants}
        </div>
        <div className={s.rowUneven}>
          <span className={s.label}>
            <FormattedMessage {...messages.accessTokenLifetime} />
          </span>
          {clientToken.accessTokenLifetime}
        </div>
      </div>
    );
  }
}

const clientTokenQuery = gql`
  query clientToken($id: Int!) {
    clientToken(id: $id) {
      id
      name
      product {
        id
        name
      }
      token
      secret
      redirectUris
      grants
      accessTokenLifetime
    }
  }
`;

export default compose(
  graphql(clientTokenQuery, {
    name: 'data',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
    props: ({ data: { clientToken, loading } }) => ({
      loading,
      clientToken,
    }),
  }),
)(withStyles(s, TableStyle)(ClientTokenInfo));
