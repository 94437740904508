import { defineMessages } from 'react-intl';

const messages = defineMessages({
  products: {
    id: 'sidebar.products',
    defaultMessage: 'Products',
    description: 'Admin sidebar products label',
  },
  productsProducts: {
    id: 'sidebar.products.products',
    defaultMessage: 'Products',
    description: 'Admin sidebar products/products label',
  },
  classroom: {
    id: 'sidebar.classroom',
    defaultMessage: 'Classrooms',
    description: 'Admin sidebar classroom label',
  },
  mySharedClassRooms: {
    id: 'sidebar.classroom.shared',
    defaultMessage: 'Shared Classrooms',
    description: 'Admin sidebar classrooms/sharedclassrooms label',
  },
  myClassroom: {
    id: 'sidebar.classroom.my',
    defaultMessage: 'My Classrooms',
    description: 'Admin sidebar classrooms/myclassrooms label',
  },
  allClassroom: {
    id: 'sidebar.classroom.all',
    defaultMessage: 'All Classrooms',
    description: 'Admin sidebar classrooms/allclassrooms label',
  },
  licenses: {
    id: 'sidebar.licenses',
    defaultMessage: 'Licenses',
    description: 'Admin sidebar licenses label',
  },
  allLicenses: {
    id: 'sidebar.licenses.all',
    defaultMessage: 'All Licenses',
    description: 'Admin sidebar licenses label',
  },
  licensesOffice: {
    id: 'sidebar.licenses.office',
    defaultMessage: 'MS-Office365-AddIn',
    description: 'Admin sidebar licenses/office label',
  },
  licensesLab: {
    id: 'sidebar.licenses.lab',
    defaultMessage: 'Waltzing Atoms Lab',
    description: 'Admin sidebar licenses/lab label',
  },
  subscriptions: {
    id: 'sidebar.subscriptions',
    defaultMessage: 'Subscriptions',
    description: 'Admin sidebar subscriptions label',
  },
  subscriptionsMe: {
    id: 'sidebar.subscriptions.me',
    defaultMessage: 'My Subscriptions',
    description: 'Admin sidebar subscriptions/me label',
  },
  subscriptionsOffice: {
    id: 'sidebar.subscriptions.office',
    defaultMessage: 'MS-Office365-AddIn',
    description: 'Admin sidebar subscriptions/office label',
  },
  subscriptionsLab: {
    id: 'sidebar.subscriptions.lab',
    defaultMessage: 'Waltzing Atoms Lab',
    description: 'Admin sidebar subscriptions/lab label',
  },
  subscriptionsExpiration: {
    id: 'sidebar.subscriptions.expirations',
    defaultMessage: 'Expired Subscriptions',
    description: 'Admin sidebar subscriptions/expirations label',
  },
  addSubscription: {
    id: 'sidebar.subscriptions.add',
    defaultMessage: 'Add Subscription',
    description: 'Admin sidebar add subscription label',
  },
  user: {
    id: 'sidebar.user',
    defaultMessage: 'User',
    description: 'Admin sidebar user label',
  },
  userUser: {
    id: 'sidebar.user.user',
    defaultMessage: 'Me',
    description: 'Admin sidebar user/user label',
  },
  userPrivacy: {
    id: 'sidebar.user.privacy',
    defaultMessage: 'Privacy',
    description: 'Admin sidebar user/privacy label',
  },
  users: {
    id: 'sidebar.users',
    defaultMessage: 'Users',
    description: 'Admin sidebar users label',
  },
  usersUsers: {
    id: 'sidebar.users.users',
    defaultMessage: 'Users',
    description: 'Admin sidebar users/users label',
  },
  api: {
    id: 'sidebar.api',
    defaultMessage: 'API',
    description: 'Admin sidebar api label',
  },
  clientTokens: {
    id: 'sidebar.api.clientTokens',
    defaultMessage: 'Client Tokens',
    description: 'Admin sidebar api/clientTokens label',
  },
  myCockpit: {
    id: 'topnav.myCockpit',
    defaultMessage: 'My Cockpit',
    description: 'Admin topbar myCockpit navigation',
  },
  myClassrooms: {
    id: 'topnav.myClassrooms',
    defaultMessage: 'My Classrooms',
    description: 'Admin topbar myClassrooms navigation',
  },
  mySubscriptions: {
    id: 'topnav.mySubscriptions',
    defaultMessage: 'My Subscriptions',
    description: 'Admin topbar myCockpit navigation',
  },
  logout: {
    id: 'topnav.logout',
    defaultMessage: 'Logout',
    description: 'Admin topbar logout navigation',
  },
  staticPages: {
    id: 'sidebar.staticPages',
    defaultMessage: 'Static Pages',
    description: 'Static Pages sidebar navigation',
  },
  pages: {
    id: 'sidebar.pages',
    defaultMessage: 'Pages',
    description: 'Pages sidebar navigation',
  },
});

export default messages;
