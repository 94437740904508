import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './InvitationsInfo.scss';
import TableStyle from '../Table/Table.scss'; // eslint-disable-line css-modules/no-unused-class
import Loading from '../modules/common/Loading/Loading';
import messages from './messages';

class ClassRoomInfo extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    classRoom: PropTypes.shape({
      id: PropTypes.number.isRequired,
      invites: PropTypes.arrayOf(
        PropTypes.shape({
          token: PropTypes.string,
          accepted: PropTypes.shape({
            user: PropTypes.shape({
              id: PropTypes.string,
            }),
          }),
        }),
      ),
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  static renderInvite(invite) {
    return (
      <div key={invite.token}>
        <div className={s.rowEven}>
          <span className={s.label}>
            <FormattedMessage {...messages.email} />
          </span>
          {invite.email}
        </div>
        {ClassRoomInfo.renderAccepted(invite.accepted)}
        <hr />
      </div>
    );
  }

  static renderAccepted(accepted) {
    if (!accepted) {
      return (
        <div className={s.notAccepted}>
          <span className={s.label}>
            <FormattedMessage {...messages.accepted} />
          </span>
          No
        </div>
      );
    }
    return [
      <div className={s.accepted}>
        <span className={s.label}>
          <FormattedMessage {...messages.accepted} />
        </span>
        {accepted.createdAt}
      </div>,
      <div className={s.accepted}>
        <span className={s.label}>
          <FormattedMessage {...messages.acceptedBy} />
        </span>
        {accepted.user.username}
      </div>,
    ];
  }

  render() {
    const { loading, classRoom } = this.props;
    if (loading) {
      return <Loading />;
    }

    return (
      <div>
        <h2>{classRoom.name}</h2>
        {(!classRoom.invites || classRoom.invites.length) === 0 && (
          <FormattedMessage {...messages.noInvites} />
        )}
        {classRoom.invites &&
          classRoom.invites.length > 0 &&
          classRoom.invites.map(ClassRoomInfo.renderInvite)}
      </div>
    );
  }
}

const classRoomQuery = gql`
  query classRoomQuery($id: String!) {
    classRoom(id: $id) {
      id
      name
      owner {
        id
        username
      }
      students {
        id
        username
      }
      invites {
        token
        email
        accepted {
          user {
            id
            username
            email
          }
          classRoom {
            id
            name
          }
          createdAt
        }
      }
    }
  }
`;

export default compose(
  graphql(classRoomQuery, {
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
    props: ({ data }) => ({
      classRoom: (!data.loading && data.classRoom) || {},
      loading: data.loading,
    }),
  }),
)(withStyles(s, TableStyle)(ClassRoomInfo));
