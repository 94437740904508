exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3_Bgn{margin:15px 0;padding-left:0;list-style-type:none}._3_Bgn li{padding:10px 0;border-top:1px solid #dcdcdc}._3_Bgn li span{position:relative;top:5px}._3_Bgn li button{float:right}._3_Bgn li button,._3_Bgn li button:active,._3_Bgn li button:focus,._3_Bgn li button:hover{color:#ff320f;border:0;background:none}._3_Bgn li:last-child{border-bottom:1px solid #dcdcdc}", ""]);

// exports
exports.locals = {
	"shares": "_3_Bgn"
};