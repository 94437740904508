import React from 'react';

import DeleteAccount from '../../components/DeleteAccount/DeleteAccount';

class DeleteAccountWrapper extends React.Component {
  render() {
    return (
      <div>
        <DeleteAccount />
      </div>
    );
  }
}

export default DeleteAccountWrapper;
