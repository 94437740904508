import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import FaSearchPlus from 'react-icons/lib/fa/search-plus';
import FaEdit from 'react-icons/lib/fa/edit';
import FaDelete from 'react-icons/lib/fa/times-circle';
import FaPlusCircle from 'react-icons/lib/fa/plus-circle';
import SweetAlert from 'react-bootstrap-sweetalert';

import Table from '../Table/Table';
import Loading from '../modules/common/Loading/Loading';
import messages from './messages';
import componentMessages from '../messages';
import TableStyle from '../Table/Table.scss'; // eslint-disable-line css-modules/no-unused-class

class ClientTokenTable extends React.Component {
  static propTypes = {
    deleteClientToken: PropTypes.func.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      clientTokens: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    };

    this.columns = [
      {
        Header: <FormattedMessage {...messages.name} />,
        accessor: 'name',
        minWidth: 200,
      },
      {
        Header: <FormattedMessage {...messages.productName} />,
        minWidth: 300,
        Cell: row => <div>{row.original.product.name}</div>,
      },
      {
        Header: <FormattedMessage {...messages.token} />,
        accessor: 'token',
        minWidth: 300,
      },
      {
        Header: <FormattedMessage {...messages.secret} />,
        accessor: 'secret',
        minWidth: 300,
      },
      {
        Header: <FormattedMessage {...componentMessages.actionHeader} />,
        width: 360,
        Cell: row => (
          <div>
            <Link to={`clients/${row.original.id}/show`}>
              <button className={TableStyle.buttonLeft}>
                <FaSearchPlus className="fa--prepended" />
                <FormattedMessage {...componentMessages.show} />
              </button>
            </Link>
            <Link to={`clients/${row.original.id}/edit`}>
              <button className={TableStyle.buttonMiddle}>
                <FaEdit className="fa--prepended" />
                <FormattedMessage {...componentMessages.edit} />
              </button>
            </Link>
            <button
              className={TableStyle.buttonRight}
              onClick={() => this.showDeletePrompt(row.original.id)}
            >
              <FaDelete className="fa--prepended" />
              <FormattedMessage {...componentMessages.delete} />
            </button>
          </div>
        ),
      },
    ];

    this.hideAlert = this.hideAlert.bind(this);
  }

  showDeletePrompt(id) {
    this.setState({
      alertOpts: {
        show: true,
        type: 'warning',
        title: <FormattedMessage {...messages.alertWarningTitle} />,
        msg: <FormattedMessage {...messages.alertDeleteWarningMessage} />,
        showCancel: true,
        onConfirm: () => this.deleteClientTokenById(id),
        onCancel: this.hideAlert,
      },
    });
  }

  async deleteClientTokenById(id) {
    this.hideAlert();

    const result = await this.props.deleteClientToken(id);

    if (!result || result.status === 401) {
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.alertErrorTitle} />,
          msg: <FormattedMessage {...messages.alertDeleteErrorMessage} />,
          showCancel: false,
          onConfirm: this.hideAlert,
          onCancel: null,
        },
      });
    } else {
      this.setState({
        alertOpts: {
          show: true,
          type: 'success',
          title: <FormattedMessage {...messages.alertSuccessTitle} />,
          msg: <FormattedMessage {...messages.alertDeleteSuccessMessage} />,
          showCancel: false,
          onConfirm: this.hideAlert,
          onCancel: null,
        },
      });
    }
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        showCancel: false,
        onConfirm: null,
        onCancel: null,
      },
    });
  }

  render() {
    if (this.props.data.loading) {
      return <Loading />;
    }

    return (
      <div>
        <Table
          isCheckable
          keyField="id"
          data={this.props.data.clientTokens}
          columns={this.columns}
        />
        <Link to="clients/new">
          <button className={TableStyle.buttonSingleLarge}>
            <FaPlusCircle className="fa--prepended" />
            <FormattedMessage {...componentMessages.add} />
          </button>
        </Link>
        {this.state.alertOpts.show && (
          <SweetAlert
            type={this.state.alertOpts.type}
            title={this.state.alertOpts.title}
            confirmBtnBsStyle={
              this.state.alertOpts.type === 'warning' ? 'danger' : 'primary'
            }
            cancelBtnBsStyle="default"
            showCancel={this.state.alertOpts.showCancel}
            onConfirm={this.state.alertOpts.onConfirm}
            onCancel={this.state.alertOpts.onCancel}
          >
            {this.state.alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

export const clientTokensQuery = gql`
  query clientTokens {
    clientTokens {
      id
      name
      product {
        id
        name
      }
      token
      secret
    }
  }
`;

const deleteClientTokenMutation = gql`
  mutation deleteClientToken($id: ID!) {
    deleteClientToken(id: $id) {
      id
    }
  }
`;

export default compose(
  graphql(clientTokensQuery, {
    name: 'data',
  }),
  graphql(deleteClientTokenMutation, {
    props: ({ mutate }) => ({
      deleteClientToken: id =>
        mutate({
          variables: { id },
          refetchQueries: [{ query: clientTokensQuery }],
        }),
    }),
  }),
)(ClientTokenTable);
