import { defineMessages } from 'react-intl';

const messages = defineMessages({
  id: {
    id: 'clientToken.id',
    defaultMessage: 'ID',
    description: 'Admin clientToken ID label',
  },
  name: {
    id: 'clientToken.name',
    defaultMessage: 'Name',
    description: 'Admin clientToken name label',
  },
  productName: {
    id: 'clientToken.productName',
    defaultMessage: 'Product',
    description: 'Admin clientToken productName label',
  },
  token: {
    id: 'clientToken.token',
    defaultMessage: 'Token',
    description: 'Admin clientToken token label',
  },
  secret: {
    id: 'clientToken.clientTokens.secret',
    defaultMessage: 'Secret',
    description: 'Admin clientToken secret label',
  },
  redirectUris: {
    id: 'clientToken.redirectUris',
    defaultMessage: 'Redirect URIs',
    description: 'Admin clientToken redirectUris label',
  },
  grants: {
    id: 'clientToken.grants',
    defaultMessage: 'Grants',
    description: 'Admin clientToken grants label',
  },
  accessTokenLifetime: {
    id: 'clientToken.accessTokenLifetime',
    defaultMessage: 'AccessToken Lifetime',
    description: 'Admin clientToken accessTokenLifetime label',
  },
  createdAt: {
    id: 'clientToken.createdAt',
    defaultMessage: 'Created at',
    description: 'Admin clientToken createdAt label',
  },
  updatedAt: {
    id: 'clientToken.updatedAt',
    defaultMessage: 'Updated at',
    description: 'Admin clientToken clientTokens/lab label',
  },
  title: {
    id: 'clientToken.title',
    defaultMessage: 'Product',
    description: 'Header of clientToken edit dialog',
  },
  alertSuccessTitle: {
    id: 'clientToken.alertSuccessTitle',
    defaultMessage: 'Success',
    description: 'Header for success alert dialog',
  },
  alertSuccessMessage: {
    id: 'clientToken.alertSuccessMessage',
    defaultMessage: 'ClientToken updated successfully!',
    description: 'Message for success alert dialog',
  },
  alertErrorTitle: {
    id: 'clientToken.alertErrorTitle',
    defaultMessage: 'Error',
    description: 'Header for error alert dialog',
  },
  alertErrorMessage: {
    id: 'clientToken.alertErrorMessage',
    defaultMessage: 'Failed to update ClientToken.',
    description: 'Message for error alert dialog',
  },
  alertDeleteSuccessMessage: {
    id: 'clientToken.alertDeleteSuccessMessage',
    defaultMessage: 'ClientToken deleted successfully!',
    description: 'Message for success delete alert dialog',
  },
  alertDeleteErrorMessage: {
    id: 'clientToken.alertDeleteErrorMessage',
    defaultMessage: 'Failed to delete ClientToken.',
    description: 'Message for error delete alert dialog',
  },
  alertDeleteWarningMessage: {
    id: 'clientToken.alertDeleteWarningMessage',
    defaultMessage: 'Do you really want to delete this token?',
    description: 'Message for warning delete alert dialog',
  },
  alertWarningTitle: {
    id: 'clientToken.alertWarningTitle',
    defaultMessage: 'Warning',
    description: 'Header for warning alert dialog',
  },
});

export default messages;
