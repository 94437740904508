exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3PVTM{display:inline-block}@media (max-width:992px){._3PVTM{display:none}}", ""]);

// exports
exports.locals = {
	"tooltip": "_3PVTM"
};