import {
  SET_LOGGED_IN,
  SET_PREVIOUS_URL,
  CLEAR_PREVIOUS_URL,
} from '../constants';

export default function user(state = {}, action) {
  switch (action.type) {
    case SET_LOGGED_IN:
      return {
        ...state,
        id: action.payload.id, // eslint-disable-line no-underscore-dangle
        email: action.payload.email,
        username: action.payload.username,
        isLoggedIn: action.payload.isLoggedIn,
      };
    case SET_PREVIOUS_URL:
      return {
        ...state,
        previousUrl: action.payload.url,
      };
    case CLEAR_PREVIOUS_URL:
      return {
        ...state,
        previousUrl: null,
      };
    default:
      return state;
  }
}
