// @flow

import { ApolloClient } from 'apollo-client';
import { from, ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import apolloLogger from 'apollo-link-logger';
import createCache from './createCache';

const injectClientAuthenticationMiddleware = new ApolloLink(
  (operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        // FIXME: can't read this from config, so maybe pass it as a global via webpack defineplugin/dotenv?
        'client-id': '6_kyj2pty23OIS4zCBtRsmowyfgxorf1fIgXHubXCcomLxFtrxL',
        'client-secret': '265nK8cyhPXZoHa4LZtwCQttPL0sHMaluyl6XxSgKvHKQIpJhU',
      },
    }));

    return forward(operation);
  },
);

const link = from([
  injectClientAuthenticationMiddleware,
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.warn(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    if (networkError) console.warn(`[Network error]: ${networkError}`);
  }),
  ...(__DEV__ ? [apolloLogger] : []),
  new HttpLink({
    uri: '/graphql',
    credentials: 'include',
  }),
]);

const cache = createCache();

export default function createApolloClient() {
  return new ApolloClient({
    link,
    cache: cache.restore(window.App.apolloState),
    queryDeduplication: true,
    connectToDevTools: true,
  });
}
