import React from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Loading from 'components/modules/common/Loading/Loading';
import gate from 'components/Auth/gate';

import s from './SubscriptionSync.scss'; // eslint-disable-line
import messages from './messages';
// import SubscriptionSharingForm from './SubscriptionSharingForm';
import roles from '../../core/roles';

class SubscriptionSync extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      me: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
      subscription: PropTypes.shape({
        id: PropTypes.string.isRequired,
        sync: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      }),
    }).isRequired,
    createSubscriptionSync: PropTypes.func.isRequired,
    updateSubscriptionSync: PropTypes.func.isRequired,
    deleteSubscriptionSync: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  async handleSubmit(formValues) {
    const {
      data: { subscription },
      createSubscriptionSync,
      updateSubscriptionSync,
    } = this.props;
    if (subscription.sync) {
      await createSubscriptionSync({
        patch: {
          ...formValues,
          subscriptionId: this.props.match.params.id,
        },
      });
    } else {
      await updateSubscriptionSync({
        id: subscription.sync.id,
        patch: {
          ...formValues,
          id: subscription.sync.id,
          subscriptionId: this.props.match.params.id,
        },
      });
    }
  }

  async handleDelete() {
    await this.props.deleteSubscriptionSync({
      id: this.props.data.subscription.sync.id,
    });
  }

  render() {
    const { loading, me, subscription } = this.props.data;
    if (loading) return <Loading />;
    if (!gate.hasRole({ user: me, role: roles.MANAGE_LICENSES })) {
      return <Redirect to="/admin/subscriptions/me" />;
    }

    return (
      <div>
        <h3>{subscription.license.name}</h3>
        <FormattedMessage {...messages.syncHeading} />
        {/* <SubscriptionSyncForm
          onSubmit={this.handleSubmit}
          onDelete={this.handleDelete}
          initialValues={this.props.data.subscription.sync}
        /> */}
      </div>
    );
  }
}

const subscriptionQuery = gql`
  query subscription($id: Int!) {
    me {
      id
      userRoles
    }
    subscription(id: $id) {
      id
      license {
        id
        name
      }
      sync {
        id
        active
        type
        interval
        source
      }
    }
  }
`;

const createSubscriptionSyncMutation = gql`
  mutation createSubscriptionSync($data: SubscriptionSyncInput!) {
    createOrUpdateSubscriptionSync(data: $data) {
      patch {
        active
        type
        interval
        source
      }
    }
  }
`;

const updateSubscriptionSyncMutation = gql`
  mutation updateSubscriptionSync($data: SubscriptionSyncInput!) {
    createOrUpdateSubscriptionSync(data: $data) {
      id
      patch {
        id
        active
        type
        interval
        source
      }
    }
  }
`;

const deleteSubscriptionSyncMutation = gql`
  mutation deleteSubscriptionSync($id: ID!) {
    removeSubscriptionSync(id: $id) {
      id
    }
  }
`;

export default compose(
  graphql(subscriptionQuery, {
    name: 'data',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
  }),
  graphql(createSubscriptionSyncMutation, {
    props: ({ mutate }) => ({
      createSubscriptionSync: data =>
        mutate({
          variables: { data },
        }),
    }),
  }),
  graphql(updateSubscriptionSyncMutation, {
    props: ({ mutate }) => ({
      updateSubscriptionSync: data =>
        mutate({
          variables: { data },
        }),
    }),
  }),
  graphql(deleteSubscriptionSyncMutation, {
    props: ({ mutate }) => ({
      deleteSubscriptionSync: data =>
        mutate({
          variables: { data },
        }),
    }),
  }),
)(withStyles(s)(SubscriptionSync));
