exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".rsR-N{display:-ms-flexbox;display:flex;-ms-flex-flow:column wrap;flex-flow:column wrap;max-height:330px;margin-left:-8px;padding-left:0;list-style-type:none}@media (max-width:600px){.rsR-N{display:block;margin-left:0;max-height:none}.rsR-N>div{width:100%!important;margin:15px 0!important}}.rsR-N>div{position:relative;width:calc(50% - 8px);margin:0 0 8px 8px;opacity:.9;z-index:10}.rsR-N>div:hover{opacity:1;z-index:20}._2sRwC{position:absolute;top:0;left:0;right:0;width:60%;margin:0 auto;opacity:.8;z-index:-1}@media (max-width:480px){._2sRwC{display:none}}", ""]);

// exports
exports.locals = {
	"products": "rsR-N",
	"professor": "_2sRwC"
};