import { defineMessages } from 'react-intl';

const messages = defineMessages({
  username: {
    id: 'auth.username',
    defaultMessage: 'Username or E-Mail Address',
    description: 'E-Mail field placeholder text',
  },
  password: {
    id: 'auth.password',
    defaultMessage: 'Password',
    description: 'Password field placeholder text in',
  },
  invalidCredentials: {
    id: 'auth.invalidCredentials',
    defaultMessage: 'No user in our system matches the given credentials.',
    description: 'Error message for invalid credentials in login component',
  },
  alertActivationErrorMessage: {
    id: 'auth.alertActivationErrorMessage',
    defaultMessage: 'Account activation failed. Invalid activation code',
    description: 'Message for failed activation alert dialog',
  },
  alertActivationSuccessMessage: {
    id: 'auth.alertActivationSuccessMessage',
    defaultMessage: 'Successfully activated account!',
    description: 'Message for activation success dialog',
  },
});

export default messages;
