import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './Loading.scss';
import Spinner from '../Spinner/Spinner';

class Loading extends React.Component {
  render() {
    return (
      <div className={s.loading}>
        <div className={s.spinnerWrapper}>
          <Spinner show />
        </div>
        <span className={s.loadingText}>Loading ...</span>
      </div>
    );
  }
}

export default withStyles(s)(Loading);
