import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Navbar, Nav, NavDropdown, MenuItem } from 'react-bootstrap';
import { compose } from 'react-apollo';
import { FormattedMessage } from 'react-intl';

import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import logo from 'components/Dashboard/admin.svg';
import { withLogout } from '../modules/auth/withLogout';
import s from './TopNavigation.scss';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import messages from './messages';
import BurgerBar from './BurgerBar';

class TopNavigation extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      username: PropTypes.string.isRequired,
      locale: PropTypes.string,
    }).isRequired,
    logout: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.navbar = null;
    this.setNavRef = element => {
      this.navbar = element;
    };

    this.collapse = this.collapse.bind(this);
  }

  handleSelect(eventKey) {
    switch (eventKey) {
      case 2.1:
        this.props.history.push('/dashboard');
        break;
      case 2.2:
        this.props.history.push('/admin/classrooms');
        break;
      case 2.3:
        this.props.history.push('/admin/subscriptions/me');
        break;
      case 2.5:
        this.props.logout();
        break;
      default:
    }
  }

  collapse() {
    this.navbar.inner.handleCollapse();
  }

  render() {
    const userName = this.props.me.username;

    return (
      <Navbar
        ref={this.setNavRef}
        className={s.navigation}
        fixedTop
        fluid
        collapseOnSelect
        onSelect={k => this.handleSelect(k)}
      >
        <Navbar.Header>
          <Navbar.Brand>
            <a href="/admin">
              <img className={s.navLogo} src={logo} alt="Waltzing Admin" />
              <div className={s.navTitle}>
                <div>Yoda</div>
              </div>
            </a>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse className={s.collapsed}>
          <Nav className={s.nav} pullRight>
            <NavDropdown eventKey={2} title={userName} id="nav-dropdown">
              <MenuItem eventKey={2.1}>
                <FormattedMessage {...messages.myCockpit} />
              </MenuItem>
              <MenuItem divider className={s.menuDivider} />
              <MenuItem eventKey={2.2}>
                <FormattedMessage {...messages.myClassrooms} />
              </MenuItem>
              <MenuItem eventKey={2.3}>
                <FormattedMessage {...messages.mySubscriptions} />
              </MenuItem>
              <MenuItem divider className={s.menuDivider} />
              <MenuItem eventKey={2.5}>Logout</MenuItem>
            </NavDropdown>
          </Nav>
          <LanguageSwitcher className={s.nav} pullRight />
          <Nav>
            <BurgerBar collapse={this.collapse} />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default compose(
  AuthenticationWrapper,
  withLogout,
  withStyles(s),
)(TopNavigation);
