/* global __CLIENT_ID__ */
/* global __CLIENT_SECRET__ */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import { setLoggedIn } from '../../../actions/user';
import { to, post } from '../../../util';

const loginQuery = gql`
  query login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      success
      token
    }
  }
`;

const meQuery = gql`
  query me {
    me {
      id
      username
      email
      userRoles
      profile {
        id
        firstName
        lastName
      }
      products {
        id
        key
        ProductUser {
          privacyAgreementAccepted
        }
      }
      hasActiveAtomsLabSubscription
      isEligibleForAtomsTrial
      hasSeenTrialPopup
    }
  }
`;

function setTokenCookie(token) {
  Cookies.set('id_token', token, { expires: 7 });
}

function getTokenCookie() {
  return Cookies.get('id_token');
}

function withLogin(WrappedComponent) {
  class LoginWrapper extends React.Component {
    static contextTypes = {
      client: PropTypes.object.isRequired,
    };

    static propTypes = {
      dispatch: PropTypes.func.isRequired,
    };

    constructor(props) {
      super(props);

      this.login = this.login.bind(this);
      this.loginWithToken = this.loginWithToken.bind(this);
      this.loginOverRest = this.loginOverRest.bind(this);
    }

    async login(username, password) {
      const response = await this.context.client.query({
        query: loginQuery,
        variables: {
          username,
          password,
        },
        fetchPolicy: 'network-only',
      });
      if (response.data.login.success) {
        setTokenCookie(response.data.login.token);
        const me = await this.context.client.query({
          query: meQuery,
          fetchPolicy: 'network-only',
        });
        this.props.dispatch(
          setLoggedIn({
            ...me.data.me,
            isLoggedIn: true,
          }),
        );
        return true;
      }
      return false;
    }

    async loginWithToken(token) {
      if (!token) return false;
      setTokenCookie(token);
      const me = await this.context.client.query({
        query: meQuery,
        fetchPolicy: 'network-only',
      });
      this.props.dispatch(
        setLoggedIn({
          ...me.data.me,
          isLoggedIn: true,
        }),
      );
      return true;
    }

    /* eslint-disable camelcase */
    async loginOverRest(username, password) {
      // eslint-disable-next-line no-unused-vars, prettier/prettier
      const [ignored, return_address] = window.location.href.split('return_address=');
      // if return_address is set, login via browser post to follow redirects in the browser
      if (return_address) {
        post(window.location.href, {
          username,
          password,
          client_id: __CLIENT_ID__,
          client_secret: __CLIENT_SECRET__,
        });
      }
      // otherwise do normal login via fetch
      const formData = new URLSearchParams();
      formData.set('username', username);
      formData.set('password', password);
      formData.set('client_id', __CLIENT_ID__);
      formData.set('client_secret', __CLIENT_SECRET__);

      const result = await fetch('/login', {
        method: 'POST',
        redirect: 'follow',
        headers: {
          Accept:
            'application/json, application/xml, text/plain, text/html, *.*',
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: formData,
      });
      if (result.status === 401) {
        return result;
      }
      const [err, response] = await to(result.json());
      if (err) {
        console.error(err);
        return err;
      }
      const { access_token } = response;
      if (!access_token) {
        return null;
      }
      setTokenCookie(access_token);
      const meResult = await fetch('/api/v1/users/me', {
        method: 'GET',
        headers: {
          Accept:
            'application/json, application/xml, text/plain, text/html, *.*',
          Authorization: `Bearer ${access_token}`,
        },
      });
      const meResponse = await meResult.json();
      this.props.dispatch(
        setLoggedIn({
          ...meResponse.me,
          isLoggedIn: true,
        }),
      );
      return {
        ...result,
        body: response,
      };
    }
    /* eslint-enable camelcase */

    render() {
      return (
        <WrappedComponent
          login={this.login}
          loginWithToken={this.loginWithToken}
          loginOverRest={this.loginOverRest}
          {...this.props}
        />
      );
    }
  }

  return connect()(LoginWrapper);
}

export { withLogin, getTokenCookie, loginQuery, meQuery };
