import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import SweetAlert from 'react-bootstrap-sweetalert';
import { FormattedMessage } from 'react-intl';
import { withLogout } from 'components/modules/auth/withLogout';

import { to } from '../../util';
import messages from './messages';
import DeleteAccountForm from './DeleteAccountForm';

class DeleteAccount extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    logout: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    };

    this.hideAlert = this.hideAlert.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });
  }

  async handleSubmit(formValues) {
    const { deleteAccount, logout } = this.props;

    const [err, result] = await to(
      deleteAccount({
        username: formValues.username,
        password: formValues.password,
      }),
    );

    if (err || (result && !result.data.deleteAccount)) {
      console.error(err);
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.alertErrorTitle} />,
          msg: <FormattedMessage {...messages.alertErrorMessage} />,
          onConfirm: this.hideAlert,
        },
      });
      return;
    }
    this.setState({
      alertOpts: {
        show: true,
        type: 'success',
        title: <FormattedMessage {...messages.alertSuccessTitle} />,
        msg: <FormattedMessage {...messages.alertSuccessMessage} />,
        onConfirm: this.hideAlert,
      },
    });
    // history.push({ pathname: '/logout/login' });
    logout();
  }

  render() {
    const { alertOpts } = this.state;

    return (
      <div>
        <DeleteAccountForm onSubmit={this.handleSubmit} />
        {alertOpts.show && (
          <SweetAlert
            type={alertOpts.type}
            title={alertOpts.title}
            onConfirm={alertOpts.onConfirm}
          >
            {alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const registerMutation = gql`
  mutation deleteAccount($username: String!, $password: String!) {
    deleteAccount(username: $username, password: $password) {
      id
      username
      email
    }
  }
`;

export default compose(
  withRouter,
  withLogout,
  graphql(registerMutation, {
    props: ({ mutate }) => ({
      deleteAccount: user =>
        mutate({
          variables: { ...user },
        }),
    }),
  }),
)(DeleteAccount);
