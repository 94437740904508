const roles = {
  MANAGE_USERS: 'manageUsers',
  MANAGE_ROLES: 'manageRoles',
  MANAGE_LICENSES: 'manageLicenses',
  MANAGE_CLASSROOMS: 'manageClassrooms',
  MANAGE_PRODUCTS: 'manageProducts',
  MANAGE_API_KEYS: 'manageApiKeys',
};

export default roles;
