/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FaLock from 'react-icons/lib/fa/lock';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './ProductCard.scss';
import messages from './messages';

class ProductCard extends React.Component {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.node.isRequired,
    color: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    locked: PropTypes.bool,
    handleClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    locked: false,
  };

  render() {
    const {
      icon,
      name,
      description,
      color,
      backgroundColor,
      locked,
      handleClick,
    } = this.props;

    return (
      <div
        className={`${s.productCard} clearfix`}
        style={{ color, backgroundColor }}
        onClick={handleClick}
      >
        {locked && (
          <div className={s.lock}>
            <FaLock size={48} />
            <FormattedMessage {...messages.buyOrRenewLicense} />
          </div>
        )}
        <div className={s.content}>
          <h2 style={{ color }}>
            {name}
            {locked && <FaLock style={{ marginTop: '-6px' }} />}
          </h2>
          <p>{description}</p>
        </div>
        <div className={s.icon}>
          <img src={icon} alt={name} />
        </div>
      </div>
    );
  }
}

export default withStyles(s)(ProductCard);
