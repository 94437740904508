import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import SweetAlert from 'react-bootstrap-sweetalert';
import messages from './messages';

import { to } from '../../util';
import ClassRoomForm from './ClassRoomForm';
import Loading from '../modules/common/Loading/Loading';

class ClassRoom extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    classRoom: PropTypes.shape({}).isRequired,
    loading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeAfterSaving = this.closeAfterSaving.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertAndClose = this.hideAlertAndClose.bind(this);

    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
      closeAfterSaving: false,
    };
  }

  async handleSubmit(formValues) {
    this.hideAlert();
    const { createOrUpdate, match } = this.props;
    const { closeAfterSaving } = this.state;

    const [err, result] = await to(
      createOrUpdate({
        id: match.params.id,
        patch: {
          name: formValues.name,
          students: formValues.students.map(student => ({
            id: student.id,
            alias: student.alias,
          })),
        },
      }),
    );

    if (err || (result && !result.data.createOrUpdateClassRoom)) {
      console.error(err);
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.alertErrorTitle} />,
          msg: <FormattedMessage {...messages.alertErrorMessage} />,
          onConfirm: this.hideAlert,
        },
      });
    } else {
      this.setState({
        alertOpts: {
          show: true,
          type: 'success',
          title: <FormattedMessage {...messages.alertSuccessTitle} />,
          msg: <FormattedMessage {...messages.alertSuccessMessageUpdate} />,
          onConfirm: closeAfterSaving ? this.hideAlertAndClose : this.hideAlert,
        },
      });
    }
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });
  }

  hideAlertAndClose() {
    const { history } = this.props;
    const { closeAfterSaving } = this.state;
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });

    if (closeAfterSaving) {
      history.push({
        pathname: '/admin/classrooms',
      });
    }
  }

  async closeAfterSaving(shouldClose) {
    return this.setState(
      {
        closeAfterSaving: shouldClose,
      },
      () => Promise.resolve(),
    );
  }

  render() {
    const { loading, classRoom } = this.props;
    if (loading) {
      return <Loading />;
    }
    const { alertOpts } = this.state;

    return (
      <div>
        <h2>
          <FormattedMessage {...messages.title} />
        </h2>
        <ClassRoomForm
          onSubmit={this.handleSubmit}
          initialValues={classRoom}
          closeAfterSaving={this.closeAfterSaving}
        />
        {alertOpts.show && (
          <SweetAlert
            type={alertOpts.type}
            title={alertOpts.title}
            onConfirm={alertOpts.onConfirm}
          >
            {alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const createOrUpdateMutation = gql`
  mutation createOrUpdate($id: ID!, $patch: ClassRoomInput) {
    createOrUpdateClassRoom(id: $id, patch: $patch) {
      id
      name
      students {
        id
        alias(classRoomId: $id)
      }
    }
  }
`;

const classRoomQuery = gql`
  query classRoomQuery($id: ID!) {
    classRoom(id: $id) {
      id
      name
      owner {
        id
        username
      }
      students {
        id
        username
        alias(classRoomId: $id)
      }
    }
  }
`;

export default compose(
  withRouter,
  graphql(createOrUpdateMutation, {
    props: ({ mutate }) => ({
      createOrUpdate: classRoom =>
        mutate({
          variables: { ...classRoom },
        }),
    }),
  }),
  graphql(classRoomQuery, {
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
    props: ({ data }) => ({
      classRoom: (!data.loading && data.classRoom) || {},
      loading: data.loading,
    }),
  }),
)(ClassRoom);
