exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3P88J{margin-bottom:20px!important}h3{font-size:30px;margin-bottom:25px}h3,h4{font-family:Gotham Rounded A,Gotham Rounded B,HelveticaNeue-Light,Arial,sans-serif}h4{margin-bottom:20px}button{margin:10px 5px}", ""]);

// exports
exports.locals = {
	"container": "_3P88J"
};