import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import SweetAlert from 'react-bootstrap-sweetalert';

import Table from '../Table/Table';
import TableStyle from '../Table/Table.scss'; // eslint-disable-line css-modules/no-unused-class
import Loading from '../modules/common/Loading/Loading';

class SharedClassRoomTable extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      mySharedClassRooms: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    };

    this.columns = [
      {
        Header: 'Name',
        accessor: 'name',
      },
    ];
  }

  render() {
    if (this.props.data.loading) {
      return <Loading />;
    }

    return (
      <div>
        <Table
          keyField="id"
          data={this.props.data.mySharedClassRooms}
          columns={this.columns}
        />
        {this.state.alertOpts.show && (
          <SweetAlert
            type={this.state.alertOpts.type}
            title={this.state.alertOpts.title}
            confirmBtnBsStyle={
              this.state.alertOpts.type === 'warning' ? 'danger' : 'primary'
            }
            cancelBtnBsStyle="default"
            showCancel={this.state.alertOpts.showCancel}
            onConfirm={this.state.alertOpts.onConfirm}
            onCancel={this.state.alertOpts.onCancel}
          >
            {this.state.alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const mySharedClassRoomsQuery = gql`
  query mySharedClassRooms {
    mySharedClassRooms {
      id
      name
    }
  }
`;

export default graphql(mySharedClassRoomsQuery, {
  name: 'data',
})(withStyles(TableStyle)(SharedClassRoomTable));
