import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaSave from 'react-icons/lib/fa/floppy-o';
import FaDelete from 'react-icons/lib/fa/times-circle';
import gate from 'components/Auth/gate';

import s from './EditUser.scss';
import messages from './messages';
import render from '../modules/form/renderField';
import validations from '../modules/form/validations';
import validationMessages from '../modules/form/messages';
import componentMessages from '../messages';
import Loading from '../modules/common/Loading/Loading';
import rolesObject from '../../core/roles';

const roles = Object.keys(rolesObject).map(key => rolesObject[key]);

const validate = values => {
  const errors = {};

  if (!validations.required(values.username)) {
    errors.username = <FormattedMessage {...validationMessages.required} />;
  }
  if (
    (values.password && !values.passwordConfirmation) ||
    (values.password && values.password !== values.passwordConfirmation)
  ) {
    errors.passwordConfirmation = (
      <FormattedMessage {...validationMessages.passwordConfirmation} />
    );
  }

  return errors;
};

class EditUserForm extends React.Component {
  static propTypes = {
    readOnly: PropTypes.bool,
    handleSubmit: PropTypes.func,
    closeAfterSaving: PropTypes.func,
    deleteUser: PropTypes.func,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
    me: PropTypes.shape({
      id: PropTypes.number,
      userRoles: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    initialValues: PropTypes.shape({
      id: PropTypes.string,
      userRoles: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  };

  static defaultProps = {
    errors: [],
    readOnly: false,
    handleSubmit: () => {},
    closeAfterSaving: () => {},
    deleteUser: () => {},
  };

  render() {
    const {
      handleSubmit,
      intl,
      errors,
      closeAfterSaving,
      me,
      initialValues,
      readOnly,
    } = this.props;
    if (!initialValues) {
      return <Loading />;
    }
    const canManageUsers = gate.hasRole({
      user: me,
      role: rolesObject.MANAGE_USERS,
    });
    const rolesEnabled = gate.hasRole({
      user: me,
      role: rolesObject.MANAGE_ROLES,
    });
    const editEnabled =
      // eslint-disable-next-line eqeqeq
      me.id == initialValues.id || canManageUsers;

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <fieldset>
            <Field
              id="username"
              name="username"
              label={<FormattedMessage {...messages.username} />}
              type="text"
              component={render.renderInput}
              disabled={!canManageUsers || readOnly}
            />
            {!readOnly && (
              <Field
                id="password"
                name="password"
                label={<FormattedMessage {...messages.password} />}
                type="password"
                component={render.renderInput}
                disabled={readOnly}
                hidden={readOnly}
              />
            )}
            {!readOnly && (
              <Field
                id="passwordConfirmation"
                name="passwordConfirmation"
                type="password"
                label={<FormattedMessage {...messages.passwordConfirmation} />}
                component={render.renderInput}
                disabled={readOnly}
                hidden={readOnly}
              />
            )}
            {canManageUsers && (
              <Field
                id="active"
                name="active"
                label={<FormattedMessage {...messages.active} />}
                type="checkbox"
                component={render.renderCheckbox}
                disabled={!editEnabled || readOnly}
              />
            )}
            <FieldArray
              name="userRoles"
              pushValue=""
              component={render.renderGenericFieldArray}
              renderField={name => (
                <Field
                  name={`${name}`}
                  label={<FormattedMessage {...messages.role} />}
                  type="select"
                  component={render.renderSelect}
                  disabled={!rolesEnabled || readOnly}
                >
                  <option value="">
                    {intl.formatMessage(componentMessages.chooseOption)}
                  </option>
                  {roles.map(role => (
                    // TODO: reduce roles with taken roles
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}
                </Field>
              )}
              singleRows
              withAdd={rolesEnabled && !readOnly}
              withRemove={rolesEnabled && !readOnly}
            />

            {errors.length === 0 ? null : (
              <ul style={{ padding: '0', listStyleType: 'none' }}>
                {errors.map(err => (
                  <li key={err} className="bg-danger">
                    {err}
                  </li>
                ))}
              </ul>
            )}
            {!readOnly &&
              editEnabled && [
                <button
                  key="save-button"
                  type="submit"
                  className="btn btn-primary"
                  onClick={async formValues => {
                    await closeAfterSaving(false);
                    handleSubmit(formValues);
                  }}
                >
                  <FaSave className="fa--prepended" />
                  <FormattedMessage {...componentMessages.save} />
                </button>,
                <button
                  key="save-and-close-button"
                  type="button"
                  className="btn btn-primary"
                  onClick={async formValues => {
                    await closeAfterSaving(true);
                    handleSubmit(formValues);
                  }}
                >
                  <FaSave className="fa--prepended" />
                  <FormattedMessage {...componentMessages.saveAndClose} />
                </button>,
                <button
                  key="delete-account-button"
                  type="button"
                  className="btn btn-danger"
                  onClick={() => this.props.deleteUser()}
                >
                  <FaDelete className="fa--prepended" />
                  <FormattedMessage {...messages.deleteAccount} />
                </button>,
              ]}
          </fieldset>
        </form>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  me: state.user,
});

export default reduxForm({
  form: 'user',
  validate,
  pure: false, // this is necessary to trigger form re-renders if the locale changes
})(injectIntl(withStyles(s)(connect(mapStateToProps)(EditUserForm))));
