import moment from 'moment';

const compareValidityPeriod = (a, b) => {
  if (!a) return -1;
  if (!b) return 1;
  const aSplit = a.split(' ');
  const bSplit = b.split(' ');
  const momentA = moment().add(parseInt(aSplit[0], 10), aSplit[1]);
  const momentB = moment().add(parseInt(bSplit[0], 10), bSplit[1]);
  if (momentA === momentB) {
    return 0;
  }
  return momentA > momentB ? 1 : -1;
};

export default compareValidityPeriod;
