exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "h3{font-size:25px;margin-bottom:25px}h3,h4{font-family:Gotham Rounded A,Gotham Rounded B,HelveticaNeue-Light,Arial,sans-serif}h4{margin-bottom:20px}._33OfQ{margin:15px 0}", ""]);

// exports
exports.locals = {
	"segment": "_33OfQ"
};