import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import Dashboard from 'components/Dashboard';

class Buy extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    return (
      <Switch>
        <Route path={this.props.match.path} component={Dashboard} />
      </Switch>
    );
  }
}

export default Buy;
