import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaSearchPlus from 'react-icons/lib/fa/search-plus';
import FaEdit from 'react-icons/lib/fa/edit';
import FaShareAlt from 'react-icons/lib/fa/share-alt';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Modal, Button } from 'react-bootstrap';

import gate from 'components/Auth/gate';
import roles from 'core/roles';
import StripePayment from 'components/StripePayment';
import messages from './messages';
import componentMessages from '../messages';
import paymentMessages from '../Dashboard/messages';
import Table from '../Table/Table';
import TableStyle from '../Table/Table.scss'; // eslint-disable-line css-modules/no-unused-class
import Loading from '../modules/common/Loading/Loading';

class MySubscriptionsTable extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    me: PropTypes.PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    subscriptions: PropTypes.arrayOf(
      PropTypes.PropTypes.shape({
        id: PropTypes.string.isRequired,
        owner: PropTypes.PropTypes.shape({
          id: PropTypes.string.isRequired,
        }).isRequired,
        license: PropTypes.PropTypes.shape({
          name: PropTypes.string.isRequired,
          allowedNumberOfUsers: PropTypes.number.isRequired,
        }).isRequired,
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
        credits: PropTypes.number,
      }),
    ).isRequired,
  };

  static defaultProps = {
    me: null,
  };

  constructor(props) {
    super(props);

    this.hideAlert = this.hideAlert.bind(this);
    this.createColumns = this.createColumns.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.refetch = this.refetch.bind(this);

    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
      modals: {
        payment: {
          show: false,
        },
      },
    };
  }

  createColumns() {
    const { me } = this.props;
    const columns = [
      {
        Header: <FormattedMessage {...messages.name} />,
        width: 350,
        Cell: ({ original }) => (
          <div>{original.license && original.license.name}</div>
        ),
      },
      {
        Header: <FormattedMessage {...messages.credits} />,
        width: 100,
        Cell: row => (
          <div>{row.original.credits === -1 ? '∞' : row.original.credits}</div>
        ),
      },
      {
        Header: <FormattedMessage {...messages.start} />,
        accessor: 'start',
        width: 300,
      },
      {
        Header: <FormattedMessage {...messages.end} />,
        accessor: 'end',
        width: 300,
      },
      {
        Header: 'Action',
        width: 510,
        Cell: row => {
          const isSubscriptionOwner = gate.isSubscriptionOwner({
            user: me,
            subscription: row.original,
          });
          const canAddUsers =
            isSubscriptionOwner &&
            row.original.license.allowedNumberOfUsers > 1 &&
            row.original.users.length <
              row.original.license.allowedNumberOfUsers;
          return (
            <div>
              <Link to={`${row.original.id}/show`}>
                <button
                  className={
                    isSubscriptionOwner
                      ? TableStyle.buttonLeft
                      : TableStyle.buttonSingle
                  }
                >
                  <FaSearchPlus className="fa--prepended" />
                  <FormattedMessage {...componentMessages.show} />
                </button>
              </Link>
              {gate.hasRole({
                user: me,
                role: roles.MANAGE_LICENSES,
              }) && (
                <Link to={`me/${row.original.id}/edit`}>
                  <button
                    className={
                      canAddUsers
                        ? TableStyle.buttonMiddle
                        : TableStyle.buttonRight
                    }
                  >
                    <FaEdit className="fa--prepended" />
                    <FormattedMessage {...componentMessages.edit} />
                  </button>
                </Link>
              )}
              {canAddUsers && (
                <Link to={`${row.original.id}/share`}>
                  <button className={TableStyle.buttonRight}>
                    <FaShareAlt className="fa--prepended" />
                    <FormattedMessage {...messages.inviteUsers} />
                  </button>
                </Link>
              )}
            </div>
          );
        },
      },
    ];
    return columns;
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        showCancel: false,
        onConfirm: null,
        onCancel: null,
      },
    });
  }

  openModal(key) {
    const update = { ...this.state };
    update.modals[key].show = true;
    this.setState(update);
  }

  closeModal(key) {
    const update = { ...this.state };
    update.modals[key].show = false;
    this.setState(update);
  }

  refetch() {
    this.context.client.query({
      // eslint-disable-next-line no-use-before-define
      query: mySubscriptionsQuery,
      fetchPolicy: 'network-only',
    });
  }

  render() {
    const { loading } = this.props;
    if (loading) {
      return <Loading />;
    }
    const { alertOpts } = this.state;
    const columns = this.createColumns();

    const subscriptionsData = this.props.subscriptions.map(subscription => ({
      ...subscription,
      start: subscription.start
        ? moment(subscription.start).format('DD.MM.YYYY HH:mm:ss')
        : '',
      end: subscription.end
        ? moment(subscription.end).format('DD.MM.YYYY HH:mm:ss')
        : '',
    }));

    return (
      <div>
        <Table keyField="id" data={subscriptionsData} columns={columns} />
        {alertOpts.show && (
          <SweetAlert
            type={alertOpts.type}
            title={alertOpts.title}
            confirmBtnBsStyle={
              alertOpts.type === 'warning' ? 'danger' : 'primary'
            }
            cancelBtnBsStyle="default"
            showCancel={alertOpts.showCancel}
            onConfirm={alertOpts.onConfirm}
            onCancel={alertOpts.onCancel}
          >
            {alertOpts.msg}
          </SweetAlert>
        )}
        <Button onClick={() => this.openModal('payment')}>
          <FormattedMessage {...messages.buySubscription} />
        </Button>
        <Modal
          show={this.state.modals.payment.show}
          onHide={() => this.closeModal('payment')}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage {...paymentMessages.purchaseLicense} />
            </Modal.Title>
            <Modal.Body>
              <StripePayment
                afterSuccess={() => {
                  this.closeModal('payment');
                  this.refetch();
                }}
              />
            </Modal.Body>
          </Modal.Header>
        </Modal>
      </div>
    );
  }
}

const mySubscriptionsQuery = gql`
  query mySubscriptions {
    mySubscriptions {
      id
      owner {
        id
        username
      }
      license {
        id
        name
        allowedNumberOfUsers
      }
      start
      end
      credits
      users {
        id
      }
    }
  }
`;
const mapStateToProps = state => ({
  me: state.user,
});

export default compose(
  graphql(mySubscriptionsQuery, {
    name: 'mySubscriptionsQuery',
    props: ({ mySubscriptionsQuery: { loading, mySubscriptions } }) => ({
      loading,
      subscriptions: (!loading && mySubscriptions) || [],
    }),
  }),
  connect(mapStateToProps),
)(withStyles(TableStyle)(MySubscriptionsTable));
