import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaBack from 'react-icons/lib/fa/arrow-left';

import gate from 'components/Auth/gate';
import rolesObject from '../../core/roles';
import messages from './messages';
import s from './EditUser.scss'; // eslint-disable-line css-modules/no-unused-class
import Loading from '../modules/common/Loading/Loading';
import EditUserForm from './EditUserForm';

class ShowUser extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      username: PropTypes.string,
    }),
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    me: PropTypes.shape({
      id: PropTypes.number,
      userRoles: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  };

  static defaultProps = {
    user: {
      id: '',
      username: '',
    },
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { loading, user } = this.props;
    const {
      history,
      me,
      match: { params },
    } = this.props;

    const canManageUsers = gate.hasRole({
      user: me,
      role: rolesObject.MANAGE_USERS,
    });

    if (me.id !== parseInt(params.id, 10) && !canManageUsers) {
      history.push({
        pathname: '/admin',
      });
      return <Loading />;
    }

    if (loading || !user) {
      return <Loading />;
    }

    return (
      <div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            history.push({
              pathname: '/admin/users',
            });
          }}
        >
          <FaBack class="fa--back" />
        </button>
        {/* eslint-disable-next-line prettier/prettier */}
        <h2><FormattedMessage {...messages.title} /></h2>
        <EditUserForm initialValues={user} readOnly />
      </div>
    );
  }
}

const userQuery = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      username
      email
      active
      userRoles
    }
  }
`;

const mapStateToProps = state => ({
  me: state.user,
});

export default compose(
  graphql(userQuery, {
    name: 'userQuery',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
    props: ({ userQuery: { loading, user } }) => ({
      loading,
      user,
    }),
  }),
)(withStyles(s)(connect(mapStateToProps)(ShowUser)));
