import React from 'react';
import PropTypes from 'prop-types';

import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import roles from 'core/roles';
import RouteBundler from 'components/RouteBundler';
import AdminDashboard from 'components/AdminDashboard';
import UsersTable from 'components/User/UsersTable';
import EditUser from 'components/User/EditUser';
import ShowUser from 'components/User/ShowUser';
import SharedClassRoomTable from 'components/ClassRoom/SharedClassRoomTable';
import ClassRoomTable from 'components/ClassRoom/ClassRoomTable';
import AllClassRoomTable from 'components/ClassRoom/AllClassRoomTable';
import CreateClassRoom from 'components/ClassRoom/CreateClassRoom';
import LicensesTable from 'components/License/LicensesTable';
import LicenseInfo from 'components/License/LicenseInfo';
import EditLicense from 'components/License/EditLicense';
import AddLicense from 'components/License/AddLicense';
import ClassRoom from 'components/ClassRoom/ClassRoom';
import Invite from 'components/ClassRoom/Invite';
import InvitationsInfo from 'components/ClassRoom/InvitationsInfo';
import ProductTable from 'components/Product/ProductTable';
import ProductInfo from 'components/Product/ProductInfo';
import EditProduct from 'components/Product/EditProduct';
import ClientTokenTable from 'components/ClientToken/ClientTokenTable';
import CreateClientToken from 'components/ClientToken/CreateClientToken';
import EditClientToken from 'components/ClientToken/EditClientToken';
import ClientTokenInfo from 'components/ClientToken/ClientTokenInfo';
import AddSubscription from 'components/Subscription/AddSubscription';
import MySubscriptionsTable from 'components/Subscription/MySubscriptionsTable';
import SubscriptionsTable from 'components/Subscription/SubscriptionsTable';
import SubscriptionInfo from 'components/Subscription/SubscriptionInfo';
import SubscriptionSharing from 'components/Subscription/SubscriptionSharing';
import EditSubscription from 'components/Subscription/EditSubscription';
import SubscriptionSync from 'components/Subscription/SubscriptionSync';
import StaticPagesTable from 'components/StaticPages/StaticPagesTable';
import StaticPageInfo from 'components/StaticPages/StaticPageInfo';
import CreateStaticPage from 'components/StaticPages/CreateStaticPage';
import EditStaticPage from 'components/StaticPages/EditStaticPage';
import PrivacyTable from 'components/PrivacyAgreement/PrivacyTable';

/* eslint-disable prettier/prettier */
const routes = [
  { path: '', component: AdminDashboard, exact: true },
  { path: 'users', component: UsersTable, exact: true, requiredRoles: [roles.MANAGE_USERS] },
  { path: 'users/:id/edit', component: EditUser, exact: true },
  { path: 'users/:id/show', component: ShowUser, exact: true },
  { path: 'products', component: ProductTable, exact: true, requiredRoles: [roles.MANAGE_PRODUCTS] },
  { path: 'products/:id/show', component: ProductInfo, exact: true, requiredRoles: [roles.MANAGE_PRODUCTS] },
  { path: 'products/:id/edit', component: EditProduct, exact: true, requiredRoles: [roles.MANAGE_PRODUCTS] },
  { path: 'clients', component: ClientTokenTable, exact: true, requiredRoles: [roles.MANAGE_API_KEYS] },
  { path: 'clients/new', component: CreateClientToken, exact: true, requiredRoles: [roles.MANAGE_API_KEYS] },
  { path: 'clients/:id/show', component: ClientTokenInfo, exact: true, requiredRoles: [roles.MANAGE_API_KEYS] },
  { path: 'clients/:id/edit', component: EditClientToken, exact: true, requiredRoles: [roles.MANAGE_API_KEYS] },
  { path: 'classrooms', component: ClassRoomTable, exact: true },
  { path: 'classrooms/new', component: CreateClassRoom, exact: true },
  { path: 'classrooms/:id/edit', component: ClassRoom, exact: true },
  { path: 'classrooms/:id/invite', component: Invite, exact: true },
  { path: 'classrooms/:id/invitations', component: InvitationsInfo, exact: true },
  { path: 'sharedclassrooms', component: SharedClassRoomTable, exact: true },
  { path: 'allclassrooms', component: AllClassRoomTable, exact: true },
  { path: 'allclassrooms/new', component: CreateClassRoom, exact: true, requiredRoles: [roles.MANAGE_CLASSROOMS] },
  { path: 'allclassrooms/:id/edit', component: ClassRoom, exact: true, requiredRoles: [roles.MANAGE_CLASSROOMS] },
  { path: 'allclassrooms/:id/invite', component: Invite, exact: true, requiredRoles: [roles.MANAGE_CLASSROOMS] },
  { path: 'allclassrooms/:id/invitations', component: InvitationsInfo, exact: true, requiredRoles: [roles.MANAGE_CLASSROOMS] },
  { path: 'licenses', component: LicensesTable, exact: true, requiredRoles: [roles.MANAGE_LICENSES] },
  { path: 'licenses/:id/show', component: LicenseInfo, exact: true, requiredRoles: [roles.MANAGE_LICENSES] },
  { path: 'licenses/:id/edit', component: EditLicense, exact: true, requiredRoles: [roles.MANAGE_LICENSES] },
  { path: 'licenses/new', component: AddLicense, exact: true, requiredRoles: [roles.MANAGE_LICENSES] },
  { path: 'subscriptions/me', component: MySubscriptionsTable, exact: true },
  { path: 'subscriptions/add', component: AddSubscription, exact: true, requiredRoles: [roles.MANAGE_LICENSES] },
  { path: 'subscriptions/:type', component: SubscriptionsTable, exact: true },
  { path: 'subscriptions/:id/show', component: SubscriptionInfo, exact: true },
  { path: 'subscriptions/:type/:id/edit', component: EditSubscription, exact: true },
  { path: 'subscriptions/:id/share', component: SubscriptionSharing, exact: true },
  { path: 'subscriptions/:id/sync', component: SubscriptionSync, exact: true },
  { path: 'staticpages', component: StaticPagesTable, exact: true, requiredRoles: [roles.MANAGE_USERS] },
  { path: 'staticpages/:id/show', component: StaticPageInfo, exact: true, requiredRoles: [roles.MANAGE_USERS] },
  { path: 'staticpages/new', component: CreateStaticPage, exact: true, requiredRoles: [roles.MANAGE_USERS] },
  { path: 'staticpages/:id/edit', component: EditStaticPage, exact: true, requiredRoles: [roles.MANAGE_USERS] },
  { path: 'privacy', component: PrivacyTable, exact: true },
];
/* eslint-enable prettier/prettier */

class Admin extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    return (
      <RouteBundler
        prefix={`${this.props.match.path}/`}
        routes={routes}
        me={this.props.me}
      />
    );
  }
}

export default AuthenticationWrapper(Admin);
