import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { ButtonToolbar } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

class TrialActivationModal extends React.Component {
  static propTypes = {
    handleTrialPopupSeen: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.goToLab = this.goToLab.bind(this);
    this.goToLicenses = this.goToLicenses.bind(this);
  }

  goToLab() {
    this.props.handleTrialPopupSeen();
    window.location.href = '/lab';
  }

  goToLicenses() {
    this.props.handleTrialPopupSeen();
    this.props.history.push({ pathname: '/admin/subscriptions/me' });
  }

  render() {
    return (
      <div>
        <h2>
          <FormattedMessage {...messages.trialActivated} />
        </h2>
        <ButtonToolbar>
          <button className="btn btn-primary" onClick={this.goToLab}>
            <FormattedMessage {...messages.goToLab} />
          </button>
          <button className="btn btn-primary" onClick={this.goToLicenses}>
            <FormattedMessage {...messages.gotToLicenses} />
          </button>
        </ButtonToolbar>
      </div>
    );
  }
}

export default withRouter(TrialActivationModal);
