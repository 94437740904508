exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3bMN6{position:relative;margin-bottom:20px}@media (max-width:480px){._3bMN6{width:100%}}@media (max-width:767px){._3bMN6{margin-left:0;margin-right:0}}._1oL8z{display:block;width:100%;height:150px;padding:50px 0;font-family:Gotham Medium,HelveticaNeue-Light,Arial,sans-serif;color:#000;font-size:32px;font-size:2rem;text-align:center;background:url(\"/header.png\")}._3wxEr{position:absolute;top:150px;right:0;padding:0 25px}._3wxEr>ul,._3wxEr button{float:left}._2i6tn,._19yh_{position:relative;top:-2px;padding-right:0;color:#000}._19yh_:active,._19yh_:focus,._19yh_:hover{color:#000;border:0;background:none;-webkit-text-decoration-line:none;text-decoration-line:none}._2i6tn:active,._2i6tn:focus,._2i6tn:hover{color:#000;border:0;background:none}", ""]);

// exports
exports.locals = {
	"header": "_3bMN6",
	"heading": "_1oL8z",
	"nav": "_3wxEr",
	"logoutButton": "_2i6tn",
	"userProfile": "_19yh_"
};