const products = {
  atoms: {
    lab: {
      id: 1,
      key: 'atoms.lab',
      name: 'Waltzing Atoms Lab',
    },
    app: {
      id: 2,
      key: 'atoms.app',
      name: 'Waltzing Atoms App',
    },
    office365: {
      id: 3,
      key: 'atoms.office365',
      name: 'Waltzing Atoms Office Extension',
    },
  },
  dashboard: {
    id: 5,
    key: 'dashboard',
    name: 'Waltzing Dashboard',
  },
  forum: {
    id: 6,
    key: 'forum',
    name: 'Waltzing Forum',
  },
};

export default products;
