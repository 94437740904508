import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import SweetAlert from 'react-bootstrap-sweetalert';

import Loading from 'components/modules/common/Loading/Loading';

import s from './StaticPageForm.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';
import EditStaticPageForm from './EditStaticPageForm';

class EditStaticPage extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      staticPage: PropTypes.object,
    }).isRequired,
    createOrUpdateStaticPage: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
      closeAfterSaving: false,
    };

    this.submitStaticPage = this.submitStaticPage.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertAndClose = this.hideAlertAndClose.bind(this);
    this.closeAfterSaving = this.closeAfterSaving.bind(this);
  }

  async submitStaticPage(formValues) {
    this.hideAlert();

    const { createOrUpdateStaticPage } = this.props;
    const result = await createOrUpdateStaticPage(
      this.props.data.staticPage.id,
      {
        url: formValues.url,
        title: formValues.title,
        content: formValues.content,
      },
    );

    if (!result || result.status === 401) {
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.errorTitle} />,
          msg: <FormattedMessage {...messages.updateErrorMessage} />,
          onConfirm: this.hideAlert,
        },
      });
    } else {
      this.setState({
        alertOpts: {
          show: true,
          type: 'success',
          title: <FormattedMessage {...messages.successTitle} />,
          msg: <FormattedMessage {...messages.updateSuccessMessage} />,
          onConfirm: this.state.closeAfterSaving
            ? this.hideAlertAndClose
            : this.hideAlert,
        },
      });
    }
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });
  }

  hideAlertAndClose() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });

    if (this.state.closeAfterSaving) {
      this.props.history.push({
        pathname: `/admin/staticpages`,
      });
    }
  }

  closeAfterSaving(shouldClose) {
    this.setState({
      closeAfterSaving: shouldClose,
    });
  }

  render() {
    const { loading, staticPage } = this.props.data;
    if (loading) return <Loading />;

    return (
      <div className={s.container}>
        <h3>
          <FormattedMessage {...messages.edit} />
        </h3>
        <EditStaticPageForm
          onSubmit={this.submitStaticPage}
          closeAfterSaving={this.closeAfterSaving}
          initialValues={staticPage}
        />
        {this.state.alertOpts.show && (
          <SweetAlert
            type={this.state.alertOpts.type}
            title={this.state.alertOpts.title}
            onConfirm={this.state.alertOpts.onConfirm}
          >
            {this.state.alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const staticPageQuery = gql`
  query staticPage($id: Int!) {
    staticPage(id: $id) {
      id
      url
      title
      content
    }
  }
`;

const createOrUpdateStaticPageMutation = gql`
  mutation createOrUpdateStaticPage($id: ID, $patch: StaticPageInput!) {
    createOrUpdateStaticPage(id: $id, patch: $patch) {
      id
      url
      title
      content
    }
  }
`;

export default compose(
  graphql(staticPageQuery, {
    name: 'data',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
  }),
  graphql(createOrUpdateStaticPageMutation, {
    props: ({ mutate }) => ({
      createOrUpdateStaticPage: (id, patch) =>
        mutate({
          variables: { id, patch },
        }),
    }),
  }),
)(withStyles(s)(EditStaticPage));
