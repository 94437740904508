import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaSave from 'react-icons/lib/fa/floppy-o';

import s from './EditSubscription.scss';
import messages from './messages';
import render from '../modules/form/renderField';
import validations from '../modules/form/validations';
import validationMessages from '../modules/form/messages';

const validate = values => {
  const errors = {};

  if (!validations.required(values.name)) {
    errors.name = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.required(values.shortDescription)) {
    errors.shortDescription = (
      <FormattedMessage {...validationMessages.required} />
    );
  }
  if (!validations.required(values.description)) {
    errors.description = <FormattedMessage {...validationMessages.required} />;
  }
  /*
  if (!validations.required(values.price)) {
    errors.price = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.required(values.allowedNumberOfUsers)) {
    errors.allowedNumberOfUsers = (
      <FormattedMessage {...validationMessages.required} />
    );
  }
  if (!validations.required(values.credits)) {
    errors.credits = <FormattedMessage {...validationMessages.required} />;
  }
  */
  if (!validations.required(values.validityPeriod)) {
    errors.validityPeriod = (
      <FormattedMessage {...validationMessages.required} />
    );
  }

  return errors;
};

class EditSubscriptionForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    closeAfterSaving: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  render() {
    return (
      <div>
        <form onSubmit={this.props.handleSubmit}>
          <fieldset>
            <Field
              id="name"
              name="name"
              label={<FormattedMessage {...messages.name} />}
              type="text"
              component={render.renderInput}
              disabled
            />
            <Field
              id="credits"
              name="credits"
              label={<FormattedMessage {...messages.credits} />}
              type="number"
              component={render.renderInput}
            />
            <Field
              id="start"
              name="start"
              label={<FormattedMessage {...messages.start} />}
              component={render.renderDate}
            />
            <Field
              id="end"
              name="end"
              label={<FormattedMessage {...messages.end} />}
              component={render.renderDate}
            />
            {this.props.errors.length === 0 ? null : (
              <ul style={{ padding: '0', listStyleType: 'none' }}>
                {this.props.errors.map(err => (
                  <li key={err} className="bg-danger">
                    {err}
                  </li>
                ))}
              </ul>
            )}

            <button
              type="submit"
              className="btn btn-primary"
              onClick={formValues => {
                this.props.closeAfterSaving(false);
                this.props.handleSubmit(formValues);
              }}
            >
              <FaSave className="fa--prepended" />
              <FormattedMessage {...messages.save} />
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={formValues => {
                this.props.closeAfterSaving(true);
                this.props.handleSubmit(formValues);
              }}
            >
              <FaSave className="fa--prepended" />
              <FormattedMessage {...messages.saveAndClose} />
            </button>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'subscription',
  validate,
  pure: false, // this is necessary to trigger form re-renders if the locale changes
})(injectIntl(withStyles(s)(EditSubscriptionForm)));
