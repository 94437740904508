import { defineMessages } from 'react-intl';

const messages = defineMessages({
  trialActivated: {
    id: 'trialActivated.trialActivated',
    defaultMessage: 'Your 30-day trial has been activated successfully!',
    description: 'Success message for trial activation',
  },
  goToLab: {
    id: 'trialActivated.goToLab',
    defaultMessage: 'Go to the lab',
    description: 'Label for button to redirect to lab after trial activation',
  },
  gotToLicenses: {
    id: 'trialActivated.goToLicenses',
    defaultMessage: 'Display license',
    description:
      'Label for button to redirect to licenses after trial activation',
  },
});

export default messages;
