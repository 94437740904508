import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import user from './user';
import runtime from './runtime';
import tablePage from './tablePage';
import intl from './intl';

export default combineReducers({
  user,
  runtime,
  intl,
  tablePage,
  form: formReducer,
});
