import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'classrooms.title',
    defaultMessage: 'Classroom',
    description: 'Title for classroom form',
  },
  inviteStudent: {
    id: 'classrooms.inviteStudent',
    defaultMessage: 'Invite student',
    description: 'Invite student label for classroom form button',
  },
  showInvitations: {
    id: 'classrooms.showInvitations',
    defaultMessage: 'Show invitations',
    description: 'Show invitations label for classroom form button',
  },
  edit: {
    id: 'classrooms.edit',
    defaultMessage: 'Edit',
    description: 'Edit label for license form button',
  },
  delete: {
    id: 'classrooms.delete',
    defaultMessage: 'Löschen',
    description: 'Delete label for classroom form button',
  },
  new: {
    id: 'classrooms.new',
    defaultMessage: 'New',
    description: 'New label for classroom form button',
  },
  name: {
    id: 'classrooms.name',
    defaultMessage: 'Name',
    description: 'Name label for classroom form',
  },
  add: {
    id: 'classrooms.add',
    defaultMessage: 'Add',
    description: 'Add label for classroom form button',
  },
  addAndClose: {
    id: 'classrooms.addAndClose',
    defaultMessage: 'Add and go back to overview',
    description: 'Add label for classroom form button',
  },
  alertSuccessTitle: {
    id: 'classrooms.alertSuccessTitle',
    defaultMessage: 'Success',
    description: 'Header for success alert dialog',
  },
  alertSuccessMessage: {
    id: 'classrooms.alertSuccessMessage',
    defaultMessage: 'Classroom successfully deleted!',
    description: 'Message for success alert dialog',
  },
  alertSuccessMessageUpdate: {
    id: 'classrooms.alertSuccessMessageUpdate',
    defaultMessage: 'Classroom successfully updated!',
    description: 'Message for success alert dialog',
  },
  alertErrorTitle: {
    id: 'classrooms.alertErrorTitle',
    defaultMessage: 'Error',
    description: 'Header for error alert dialog',
  },
  alertErrorMessage: {
    id: 'classrooms.alertErrorMessage',
    defaultMessage: 'There has been an error while deleting the classroom!',
    description: 'Message for error alert dialog',
  },
  alertWarningTitle: {
    id: 'classrooms.alertWarningTitle',
    defaultMessage: 'Warning',
    description: 'Header for warning alert dialog',
  },
  alertWarningMessage: {
    id: 'classrooms.alertWarningMessage',
    defaultMessage: 'Do you really want to delete this classroom?',
    description: 'Message for warning alert dialog',
  },
  email: {
    id: 'classrooms.email',
    defaultMessage: 'Email',
    description: 'Message for invitation info',
  },
  accepted: {
    id: 'classrooms.accepted',
    defaultMessage: 'Accepted',
    description: 'Message for invitation info',
  },
  id: {
    id: 'classrooms.id',
    defaultMessage: 'Id',
    description: 'Message for invitation info',
  },
  acceptedBy: {
    id: 'classrooms.acceptedBy',
    defaultMessage: 'Accepted by',
    description: 'Message for invitation info',
  },
  noInvites: {
    id: 'classrooms.noInvites',
    defaultMessage: 'No invites sent for this classroom!',
    description: 'No invites message for invitation info',
  },
});

export default messages;
