import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import { compose, graphql } from 'react-apollo';
import SweetAlert from 'react-bootstrap-sweetalert';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';

import { to } from '../../util';
import { activateAtomsTrialMutation } from '../StripePayment';
import RegistrationForm from './RegistrationForm';
import professorImg from './professorForce.svg';
import messages from './messages';

class Registration extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    activateAtomsTrial: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
        confirmBtnBsStyle: 'primary',
      },
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        showCancel: false,
        onConfirm: null,
        onCancel: null,
        confirmBtnBsStyle: 'primary',
      },
    });
  }

  async handleSubmit(formValues) {
    const { register, history } = this.props;

    if (formValues.passwort !== formValues.passwortRepeat) {
      console.error('Passwords do not match!');
      return;
    }

    const [err, result] = await to(
      register({
        username: formValues.username,
        email: formValues.email,
        password: formValues.password,
        newsletter: formValues.newsletter || false,
        hasSeenTrialPopup: !formValues.trialLicense,
        /* no need to display popup, if no trial was started */
      }),
    );

    if (err || (result && !result.data.register)) {
      console.error(err);
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.alertErrorTitle} />,
          msg: <FormattedMessage {...messages.alertErrorMessage} />,
          onConfirm: this.hideAlert,
        },
      });
      return;
    }

    if (formValues.trialLicense) {
      this.props.activateAtomsTrial(result.data.register.id);
    }

    this.setState({
      alertOpts: {
        show: true,
        type: 'success',
        title: <FormattedMessage {...messages.alertSuccessTitle} />,
        msg: <FormattedMessage {...messages.alertSuccessMessage} />,
        showCancel: false,
        onConfirm: () => history.push({ pathname: '/' }),
      },
    });
  }

  render() {
    const { alertOpts } = this.state;
    return (
      <Row>
        <Col md={6} xs={12}>
          <img
            src={professorImg}
            alt="Waltzing Atoms"
            style={{ display: 'block', width: '70%', margin: '15px auto 0' }}
          />
        </Col>
        <Col md={6} xs={12}>
          <RegistrationForm
            onSubmit={this.handleSubmit}
            initialValues={{ trialLicense: true }}
          />
        </Col>
        {alertOpts.show && (
          <SweetAlert
            type={alertOpts.type}
            title={alertOpts.title}
            confirmBtnBsStyle={alertOpts.confirmBtnBsStyle}
            cancelBtnBsStyle="default"
            showCancel={alertOpts.showCancel}
            onConfirm={alertOpts.onConfirm}
            onCancel={alertOpts.onCancel}
          >
            {alertOpts.msg}
          </SweetAlert>
        )}
      </Row>
    );
  }
}

const registerMutation = gql`
  mutation register(
    $username: String!
    $email: String!
    $password: String!
    $newsletter: Boolean
    $hasSeenTrialPopup: Boolean
  ) {
    register(
      username: $username
      email: $email
      password: $password
      newsletter: $newsletter
      hasSeenTrialPopup: $hasSeenTrialPopup
    ) {
      id
      username
      email
    }
  }
`;

export default compose(
  withRouter,
  graphql(registerMutation, {
    props: ({ mutate }) => ({
      register: user =>
        mutate({
          variables: { ...user },
        }),
    }),
  }),
  graphql(activateAtomsTrialMutation, {
    props: ({ mutate }) => ({
      activateAtomsTrial: userId =>
        mutate({
          variables: { userId },
        }),
    }),
  }),
)(Registration);
