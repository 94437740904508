import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

const messages = defineMessages({
  welcome: {
    id: 'admin.welcome',
    defaultMessage: 'Welcome to the admin dashboard!',
    description: 'Welcome message at the admin start page',
  },
});

class AdminDashboard extends React.Component {
  render() {
    return <FormattedMessage {...messages.welcome} />;
  }
}

export default AdminDashboard;
