import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import SweetAlert from 'react-bootstrap-sweetalert';
import { FormattedMessage } from 'react-intl';
import { messagesReset } from './messages';

import ResetPasswordForm from './ResetPasswordForm';
import professorImg from './professorJuggle.svg';

class ResetPassword extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    resetPassword: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
        confirmBtnBsStyle: 'primary',
      },
    };

    this.submitResetPassword = this.submitResetPassword.bind(this);
  }

  setErrors(errors) {
    this.setState({
      errors: errors.map(err => err.message),
    });
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        showCancel: false,
        onConfirm: null,
        onCancel: null,
        confirmBtnBsStyle: 'primary',
      },
    });
  }

  async submitResetPassword(formValues) {
    const { resetPassword, history } = this.props;
    const result = await resetPassword(formValues.username);

    if (!result || result.status === 401) {
      this.setErrors([
        { message: 'No user in our system matches the given credentials' },
      ]);
    } else {
      this.setState({
        errors: [],
        alertOpts: {
          show: true,
          type: 'success',
          title: <FormattedMessage {...messagesReset.alertSuccessTitle} />,
          msg: <FormattedMessage {...messagesReset.alertSuccessMessage} />,
          showCancel: false,
          onConfirm: () => history.push({ pathname: '/' }),
        },
      });
    }
  }

  render() {
    const { alertOpts } = this.state;
    return (
      <Row>
        <Col md={6} xs={12}>
          <img
            src={professorImg}
            alt="Waltzing Atoms"
            style={{ display: 'block', width: '55%', margin: '15px auto 0' }}
          />
        </Col>
        <Col md={6} xs={12}>
          <ResetPasswordForm
            onSubmit={this.submitResetPassword}
            errors={this.state.errors}
          />
        </Col>
        {alertOpts.show && (
          <SweetAlert
            type={alertOpts.type}
            title={alertOpts.title}
            confirmBtnBsStyle={alertOpts.confirmBtnBsStyle}
            cancelBtnBsStyle="default"
            showCancel={alertOpts.showCancel}
            onConfirm={alertOpts.onConfirm}
            onCancel={alertOpts.onCancel}
          >
            {alertOpts.msg}
          </SweetAlert>
        )}
      </Row>
    );
  }
}

const resetPasswordMutation = gql`
  mutation resetPassword($username: String) {
    resetPassword(username: $username) {
      id
      username
      email
    }
  }
`;

export default compose(
  withRouter,
  graphql(resetPasswordMutation, {
    props: ({ mutate }) => ({
      resetPassword: username =>
        mutate({
          variables: { username },
        }),
    }),
  }),
)(ResetPassword);
