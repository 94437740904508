import { defineMessages } from 'react-intl';

const messages = defineMessages({
  heading: {
    id: 'dashboard.heading',
    defaultMessage: 'Welcome to your Cockpit',
    description: 'Heading for dashboard',
  },
  labDescription: {
    id: 'dashboard.apps.lab.description',
    defaultMessage:
      'Living Chemistry, in the digital classroom of the 21st century.',
    description: 'Description for Waltzing Atoms Lab in Dashboard',
  },
  yodaDescription: {
    id: 'dashboard.apps.yoda.description',
    defaultMessage: 'Manage your classrooms and students.',
    description: 'Description for Yoda in Dashboard',
  },
  forumDescription: {
    id: 'dashboard.apps.forum.description',
    defaultMessage: 'FAQs, Tutorials, Feature Requests and more',
    description: 'Description for Forum in Dashboard',
  },
  purchaseLicense: {
    id: 'dashboard.purchaseLicense',
    defaultMessage: 'Get your license',
    description: 'Heading for purchase license dialog',
  },
  trialLicense: {
    id: 'dashboard.trialLicense',
    defaultMessage: 'Free Trial',
    description: 'Heading for trial license activated dialog',
  },
  buyOrRenewLicense: {
    id: 'dashboard.buyOrRenewLicense',
    defaultMessage: 'Click here to purchase a license or renew a existing one!',
    description:
      'Buy or renew license message for locked products in dashboard',
  },
});

export default messages;
