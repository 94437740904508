import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import SweetAlert from 'react-bootstrap-sweetalert';

import s from './CreateClassRoom.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';
import CreateClassRoomForm from './CreateClassRoomForm';

class CreateClassRoom extends React.Component {
  static propTypes = {
    createClassRoom: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
      closeAfterSaving: false,
    };

    this.submitClassRoom = this.submitClassRoom.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertAndClose = this.hideAlertAndClose.bind(this);
    this.closeAfterSaving = this.closeAfterSaving.bind(this);
  }

  async submitClassRoom(formValues) {
    this.hideAlert();
    const { createClassRoom } = this.props;
    const result = await createClassRoom({ name: formValues.name });

    if (!result || result.status === 401) {
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: 'Error',
          msg: 'There has been an error while creating the classroom!',
          onConfirm: this.hideAlert,
        },
      });
    } else {
      this.setState({
        alertOpts: {
          show: true,
          type: 'success',
          title: 'Success',
          msg: 'Classroom successfully created!',
          onConfirm: this.state.closeAfterSaving
            ? this.hideAlertAndClose
            : this.hideAlert,
        },
      });
    }
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });
  }

  hideAlertAndClose() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });

    if (this.state.closeAfterSaving) {
      this.props.history.push({
        pathname: `/admin/classrooms`,
      });
    }
  }

  closeAfterSaving(shouldClose) {
    this.setState({
      closeAfterSaving: shouldClose,
    });
  }

  render() {
    return (
      <div>
        <h2>
          <FormattedMessage {...messages.add} />
        </h2>
        <CreateClassRoomForm
          onSubmit={this.submitClassRoom}
          closeAfterSaving={this.closeAfterSaving}
        />
        {this.state.alertOpts.show && (
          <SweetAlert
            type={this.state.alertOpts.type}
            title={this.state.alertOpts.title}
            onConfirm={this.state.alertOpts.onConfirm}
          >
            {this.state.alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const createClassRoom = gql`
  mutation createClassRoom($patch: ClassRoomInput!) {
    createOrUpdateClassRoom(patch: $patch) {
      id
      name
    }
  }
`;

export default compose(
  graphql(createClassRoom, {
    props: ({ mutate }) => ({
      createClassRoom: patch =>
        mutate({
          variables: { patch },
          refetchQueries: ['classRooms'],
        }),
    }),
  }),
)(withStyles(s)(CreateClassRoom));
