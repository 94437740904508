exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "h3{font-size:30px;margin-bottom:25px;font-family:Gotham Rounded A,Gotham Rounded B,HelveticaNeue-Light,Arial,sans-serif}._2RxJf{margin-bottom:20px}", ""]);

// exports
exports.locals = {
	"staticPageBody": "_2RxJf"
};