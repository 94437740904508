import React from 'react';
import PropTypes from 'prop-types';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import render from '../modules/form/renderField';
import validations from '../modules/form/validations';
import validationMessages from '../modules/form/messages';

const messages = defineMessages({
  heading: {
    id: 'invite.heading',
    defaultMessage: 'Invite student',
    description: 'Heading for login form',
  },
  isAcitve: {
    id: 'invite.isAcitve',
    defaultMessage: 'Invitation is active',
    description: 'Name field placeholder text in classRoom form',
  },
  email: {
    id: 'invite.email',
    defaultMessage: 'Student E-Mail',
    description: 'Name field placeholder text in classRoom form',
  },
  submit: {
    id: 'invite.submit',
    defaultMessage: 'Invite',
    description: 'Submit button text in classRoom form',
  },
});

const validate = values => {
  const errors = {};
  if (!validations.required(values.email)) {
    errors.email = <FormattedMessage {...validationMessages.required} />;
  }
  if (values.email && values.email.split(';').length > 0) {
    values.email.split(';').forEach(email => {
      if (!validations.email(email)) {
        errors.email = <FormattedMessage {...validationMessages.email} />;
      }
    });
  }

  return errors;
};

class InviteForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  render() {
    return (
      <div>
        <FormattedMessage {...messages.heading} />
        <form onSubmit={this.props.handleSubmit}>
          <fieldset>
            <Field
              id="email"
              name="email"
              type="text"
              placeholder={this.props.intl.formatMessage(messages.email)}
              component={render.renderInput}
            />
            {this.props.errors.length === 0 ? null : (
              <ul style={{ padding: '0', listStyleType: 'none' }}>
                {this.props.errors.map(err => (
                  <li key={err} className="bg-danger">
                    {err}
                  </li>
                ))}
              </ul>
            )}
            <button type="submit" className="btn btn-primary btn--full-width">
              <FormattedMessage {...messages.submit} />
            </button>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'emailInvitation',
  validate,
})(injectIntl(InviteForm));
