import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './ReactCheckbox.scss';

class ReactCheckbox extends React.Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.shape({
        id: PropTypes.string,
      }),
      warning: PropTypes.bool,
    }).isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    type: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
  };

  render() {
    const {
      input,
      meta: { touched, error, warning },
      id,
      label,
      type,
      disabled,
    } = this.props;

    return (
      <div className="checkbox">
        <label htmlFor={id} className={s.checkcontainer}>
          <div className={s.checklabel}>{label} </div>
          <input {...input} id={id} type={type} disabled={disabled} />
          <span className={s.checkmark} />
        </label>
        {touched &&
          ((error && <p className="bg-danger">{error}</p>) ||
            (warning && <p className="bg-warning">{warning}</p>))}
      </div>
    );
  }
}

export default withStyles(s)(ReactCheckbox);
