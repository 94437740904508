import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field, FieldArray, reduxForm } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaSave from 'react-icons/lib/fa/floppy-o';

import s from './EditLicense.scss';
import messages from './messages';
import render from '../modules/form/renderField';
import * as normalizers from '../modules/form/normalizers';
import validations from '../modules/form/validations';
import validationMessages from '../modules/form/messages';
import componentMessages from '../messages';

const validate = values => {
  const errors = {};

  if (!validations.required(values.name)) {
    errors.name = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.required(values.shortDescription)) {
    errors.shortDescription = (
      <FormattedMessage {...validationMessages.required} />
    );
  }
  if (!validations.required(values.description)) {
    errors.description = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.required(values.price)) {
    errors.price = <FormattedMessage {...validationMessages.required} />;
  }
  if (
    values.allowedNumberOfUsers !== 0 &&
    !validations.required(values.allowedNumberOfUsers)
  ) {
    errors.allowedNumberOfUsers = (
      <FormattedMessage {...validationMessages.required} />
    );
  }
  if (!validations.required(values.credits)) {
    errors.credits = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.required(values.validityNumber)) {
    errors.validityNumber = (
      <FormattedMessage {...validationMessages.required} />
    );
  }

  if (!validations.requiredArray(values.products)) {
    errors.products = {
      _error: <FormattedMessage {...validationMessages.required} />,
    };
  }

  return errors;
};

class AddLicenseForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    closeAfterSaving: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  render() {
    return (
      <div>
        <form onSubmit={this.props.handleSubmit}>
          <fieldset>
            <Field
              id="name"
              name="name"
              label={<FormattedMessage {...messages.name} />}
              type="text"
              component={render.renderInput}
            />
            <Field
              id="shortDescription"
              name="shortDescription"
              label={<FormattedMessage {...messages.shortDescription} />}
              type="text"
              component={render.renderInput}
            />
            <Field
              id="description"
              name="description"
              label={<FormattedMessage {...messages.description} />}
              type="text"
              component={render.renderInput}
            />
            <Field
              id="price"
              name="price"
              label={<FormattedMessage {...messages.price} />}
              type="number"
              component={render.renderInput}
            />
            <Field
              id="allowedNumberOfUsers"
              name="allowedNumberOfUsers"
              label={<FormattedMessage {...messages.allowedNumberOfUsers} />}
              type="number"
              component={render.renderInput}
            />
            <Field
              id="credits"
              name="credits"
              label={<FormattedMessage {...messages.credits} />}
              type="number"
              component={render.renderInput}
            />
            <Field
              id="validityNumber"
              name="validityNumber"
              label={<FormattedMessage {...messages.validityPeriod} />}
              type="number"
              component={render.renderInput}
            />
            <Field
              id="validityPeriod"
              name="validityPeriod"
              component={render.renderSelect}
            >
              {['days', 'months', 'year', 'years'].map((period, i) => (
                <option key={i} value={period}> { /* eslint-disable-line */}
                  {period}
                </option>
              ))}
            </Field>
            <Field
              id="visible"
              name="visible"
              label={<FormattedMessage {...messages.visible} />}
              type="checkbox"
              component={render.renderCheckbox}
              normalize={normalizers.booleanNormalizer}
            />
            <Field
              id="enabled"
              name="enabled"
              label={<FormattedMessage {...messages.enabled} />}
              type="checkbox"
              component={render.renderCheckbox}
              normalize={normalizers.booleanNormalizer}
            />
            <strong>Products</strong>
            <p className="help-text">
              <FormattedMessage {...messages.chooseProduct} />
            </p>
            <FieldArray
              id="products"
              name="products"
              component={render.renderGenericFieldArray}
              renderField={(name, index) => (
                <Field
                  name={`${name}`}
                  id="productName"
                  pushValue=""
                  label={
                    <FormattedMessage
                      {...messages.product}
                      values={{ number: `${index + 1}` }}
                    />
                  }
                  // label={`Product ${index + 1}`}
                  component={render.renderSelect}
                >
                  <option value="">
                    {this.props.intl.formatMessage(
                      componentMessages.chooseOption,
                    )}
                  </option>
                  {this.props.products.map(product => (
                    <option key={product.id} value={product.id}>
                      {product.name}
                    </option>
                  ))}
                </Field>
              )}
              singleRows
              withAdd
              withRemove
            />

            {this.props.errors.length === 0 ? null : (
              <ul style={{ padding: '0', listStyleType: 'none' }}>
                {this.props.errors.map(err => (
                  <li key={err} className="bg-danger">
                    {err}
                  </li>
                ))}
              </ul>
            )}

            <button
              type="submit"
              className="btn btn-primary"
              onClick={formValues => {
                this.props.closeAfterSaving(false);
                this.props.handleSubmit(formValues);
              }}
            >
              <FaSave className="fa--prepended" />
              <FormattedMessage {...messages.save} />
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={formValues => {
                this.props.closeAfterSaving(true);
                this.props.handleSubmit(formValues);
              }}
            >
              <FaSave className="fa--prepended" />
              <FormattedMessage {...messages.saveAndClose} />
            </button>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'addLicense',
  validate,
  pure: false,
})(injectIntl(withStyles(s)(AddLicenseForm)));
