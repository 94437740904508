import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import { Row, Col } from 'react-bootstrap';

import baseStyles from '../../styles/app.scss';
import TopNavigation from './TopNavigation';
import Sidebar from './Sidebar';

class AdminLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <div>
        <Row style={{ margin: '0px' }}>
          <TopNavigation />
          <Col lg={2} md={4}>
            <Sidebar />
          </Col>
          <Col lg={10} md={8} style={{ paddingTop: '75px' }}>
            {this.props.children}
          </Col>
        </Row>
      </div>
    );
  }
}

export default AuthenticationWrapper(withStyles(baseStyles)(AdminLayout));
