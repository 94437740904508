import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import SweetAlert from 'react-bootstrap-sweetalert';

import messages from './messages';
import s from './EditLicense.scss'; // eslint-disable-line css-modules/no-unused-class
import Loading from '../modules/common/Loading/Loading';
import EditLicenseForm from './EditLicenseForm';

class EditLicense extends React.Component {
  static propTypes = {
    updateLicense: PropTypes.func.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      license: PropTypes.object,
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    products: PropTypes.shape({
      products: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
      closeAfterSaving: false,
    };

    this.submitLicenseChanges = this.submitLicenseChanges.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertAndClose = this.hideAlertAndClose.bind(this);
    this.closeAfterSaving = this.closeAfterSaving.bind(this);
  }

  async submitLicenseChanges(formValues) {
    this.hideAlert();
    const productIds = formValues.products.filter(
      v => !_.isEmpty(v) && /^\d+$/.test(v),
    );

    const validityPeriod = `+${formValues.validityNumber} ${
      formValues.validityPeriod
    }`;

    const result = await this.props.updateLicense(this.props.match.params.id, {
      name: formValues.name,
      shortDescription: formValues.shortDescription,
      description: formValues.description,
      price: formValues.price,
      allowedNumberOfUsers: formValues.allowedNumberOfUsers,
      credits: formValues.credits,
      validityPeriod,
      visible: formValues.visible,
      enabled: formValues.enabled,
      productIds,
    });

    if (!result || result.status === 401) {
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.alertErrorTitle} />,
          msg: <FormattedMessage {...messages.alertErrorMessage} />,
          onConfirm: this.hideAlert,
        },
      });
    } else {
      this.setState({
        alertOpts: {
          show: true,
          type: 'success',
          title: <FormattedMessage {...messages.alertSuccessTitle} />,
          msg: <FormattedMessage {...messages.alertSuccessMessage} />,
          onConfirm: this.state.closeAfterSaving
            ? this.hideAlertAndClose
            : this.hideAlert,
        },
      });
    }
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });
  }

  hideAlertAndClose() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });

    if (this.state.closeAfterSaving) {
      this.props.history.push({
        pathname: '/admin/licenses/',
      });
    }
  }

  closeAfterSaving(shouldClose) {
    this.setState({
      closeAfterSaving: shouldClose,
    });
  }

  render() {
    if (this.props.data.loading) {
      return <Loading />;
    }

    const {
      data: { license },
      products,
    } = this.props;

    // initial values of product select field
    const productValues = {};
    // list of select fields to be displayed
    const licenseProducts = [{}];
    if (license && !_.isEmpty(license.products)) {
      let i = 0;
      license.products.forEach(value => {
        productValues[`product${i}`] = value.id;
        licenseProducts[i] = value.id;
        i += 1;
      });
    }

    const validityPeriod = license.validityPeriod.split(' ');
    const initialValues = {
      ...license,
      validityNumber: validityPeriod[0].split('+')[1], // remove + before number
      validityPeriod: validityPeriod[1],
      products: licenseProducts,
      ...productValues,
    };

    return (
      <div>
        <h2>
          <FormattedMessage {...messages.editTitle} />
        </h2>
        <EditLicenseForm
          onSubmit={this.submitLicenseChanges}
          closeAfterSaving={this.closeAfterSaving}
          initialValues={initialValues}
          products={products.products}
        />
        {this.state.alertOpts.show && (
          <SweetAlert
            type={this.state.alertOpts.type}
            title={this.state.alertOpts.title}
            onConfirm={this.state.alertOpts.onConfirm}
          >
            {this.state.alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const productsQuery = gql`
  query products {
    products {
      id
      name
    }
  }
`;

const licenseQuery = gql`
  query license($id: Int!) {
    license(id: $id) {
      id
      name
      shortDescription
      description
      allowedNumberOfUsers
      price
      validityPeriod
      credits
      visible
      enabled
      products {
        id
        name
      }
    }
  }
`;

const updateLicenseMutation = gql`
  mutation updateLicense($id: ID!, $patch: LicenseInput!) {
    createOrUpdateLicense(id: $id, patch: $patch) {
      id
      name
      shortDescription
      description
      allowedNumberOfUsers
      price
      validityPeriod
      credits
      visible
      enabled
    }
  }
`;

export default compose(
  graphql(productsQuery, {
    name: 'products',
  }),
  graphql(licenseQuery, {
    name: 'data',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
  }),
  graphql(updateLicenseMutation, {
    props: ({ mutate }) => ({
      updateLicense: (id, patch) =>
        mutate({
          variables: { id, patch },
        }),
    }),
  }),
)(withStyles(s)(EditLicense));
