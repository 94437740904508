import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { HelpBlock } from 'react-bootstrap';

import render from '../modules/form/renderField';
import validations from '../modules/form/validations';
import validationMessages from '../modules/form/messages';
import { messagesReset } from './messages';

const validate = values => {
  const errors = {};
  if (!validations.required(values.username)) {
    errors.username = <FormattedMessage {...validationMessages.required} />;
  }

  return errors;
};

class ResetPasswordFrom extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  render() {
    return (
      <div>
        <h1 style={{ margin: '0 0 5px 0' }}>
          <FormattedMessage {...messagesReset.heading} />
        </h1>
        <HelpBlock>
          <FormattedMessage {...messagesReset.info} />
        </HelpBlock>
        <form onSubmit={this.props.handleSubmit}>
          <fieldset>
            <Field
              id="username"
              name="username"
              type="text"
              placeholder={this.props.intl.formatMessage(
                messagesReset.username,
              )}
              component={render.renderInput}
            />
            {this.props.errors.length === 0 ? null : (
              <ul style={{ padding: '0', listStyleType: 'none' }}>
                {this.props.errors.map(err => (
                  <li key={err} className="bg-danger">
                    {err}
                  </li>
                ))}
              </ul>
            )}
            <div className="btn-group btn-group-justified">
              <div className="btn-group">
                <button type="submit" className="btn btn-primary">
                  <FormattedMessage {...messagesReset.submit} />
                </button>
              </div>
              <div className="btn-group">
                <Link to="/login" className="btn btn-primary">
                  <FormattedMessage {...messagesReset.cancel} />
                </Link>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 're-set-password',
  validate,
  pure: false, // this is necessary to trigger form re-renders if the locale changes
})(injectIntl(ResetPasswordFrom));
