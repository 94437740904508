import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, FieldArray } from 'redux-form';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';

import render from '../modules/form/renderField';
import validations from '../modules/form/validations';
import validationMessages from '../modules/form/messages';

const messages = defineMessages({
  inviteEmail: {
    id: 'subscriptionSharingForm.inviteEmail',
    defaultMessage: 'E-Mail',
    description: 'Label for email field in subscription sharing form',
  },
  submit: {
    id: 'subscriptionSharingForm.submit',
    defaultMessage: 'Save changes',
    description: 'Submit button text in subscriptionSharingForm form',
  },
});

const validate = values => {
  const errors = {};
  if (!validations.required(values.name)) {
    errors.username = <FormattedMessage {...validationMessages.required} />;
  }

  return errors;
};

class SubscriptionSharingForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <fieldset>
          <FieldArray
            name="invites"
            pushValue=""
            component={render.renderGenericFieldArray}
            renderField={name => (
              <Field
                name={name}
                label={<FormattedMessage {...messages.inviteEmail} />}
                type="text"
                component={render.renderInput}
              />
            )}
            singleRows
          />
          <button
            type="submit"
            className="btn btn-primary"
            disabled={this.props.submitting}
          >
            <FormattedMessage {...messages.submit} />
          </button>
        </fieldset>
      </form>
    );
  }
}

export default reduxForm({
  form: 'subscriptionSharingForm',
  validate,
  enableReinitialize: true,
  pure: false, // this is necessary to trigger form re-renders if the locale changes
})(injectIntl(SubscriptionSharingForm));
