import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { AppContainer } from 'react-hot-loader';
import { addLocaleData } from 'react-intl';
// This is so bad: requiring all locale if they are not needed?
/* @intl-code-template import ${lang} from 'react-intl/locale-data/${lang}'; */
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import fa from 'react-intl/locale-data/fa';
/* @intl-code-template-end */
import App from './components/App';
import createFetch from './createFetch';
import configureStore from './store/configureStore';
import history from './history';
import createApolloClient from './core/createApolloClient';
import { getIntl } from './actions/intl';

/* @intl-code-template addLocaleData(${lang}); */
addLocaleData(en);
addLocaleData(de);
addLocaleData(fa);
/* @intl-code-template-end */

// Universal HTTP client
const fetch = createFetch(window.fetch, {
  baseUrl: window.App.apiUrl,
});

const apolloClient = createApolloClient();

// Initialize a new Redux store
// http://redux.js.org/docs/basics/UsageWithReact.html
const store = configureStore(window.App.state, {
  apolloClient,
  fetch,
  history,
});

// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const context = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: (...styles) => {
    // eslint-disable-next-line no-underscore-dangle
    const removeCss = styles.map(x => x._insertCss());
    return () => {
      removeCss.forEach(f => f());
    };
  },
  // For react-apollo
  client: apolloClient,
  store,
  storeSubscription: null,
  // Universal HTTP client
  fetch,
  // intl instance as it can be get with injectIntl
  intl: store.dispatch(getIntl()),
};

const container = document.getElementById('app');
let initialRender = true;

const render = () => {
  const renderReactApp = initialRender ? ReactDOM.hydrate : ReactDOM.render;
  initialRender = false;
  renderReactApp(
    <AppContainer>
      <BrowserRouter>
        <App context={context} />
      </BrowserRouter>
    </AppContainer>,
    container,
  );
};

export default function main() {
  render();
}

// globally accesible entry point
window.RSK_ENTRY = main;

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept('./components/App', () => {
    render();
  });
}
