/**
 * HOC that checks if the current user is logged in.
 * If so, it renders the wrapped component and
 * passes the current user's information to
 * it via the 'me' prop.
 *
 * Otherwise, it redirects to the homepage.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';
import products from 'core/product';
import { meQuery } from 'components/modules/auth/withLogin';
import Loading from 'components/modules/common/Loading/Loading';
import { setPreviousUrl } from 'actions/user';

const AuthenticationWrapper = WrappedComponent => {
  class WithAuthentication extends React.Component {
    static propTypes = {
      meData: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        me: PropTypes.shape({
          id: PropTypes.string.isRequired,
          roles: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
            }),
          ),
        }),
      }).isRequired,
      match: PropTypes.shape({
        path: PropTypes.string.isRequired,
      }).isRequired,
      dispatch: PropTypes.func.isRequired,
    };

    render() {
      const {
        meData: { loading, me },
        match: { path },
      } = this.props;

      if (loading) return <Loading />;
      if (!me) {
        return <Redirect to="/login" />;
      }

      // check if user has accepted the privacy agreement for the dashboard
      const dashboardProduct = me.products.filter(
        product => parseInt(product.id, 10) === products.dashboard.id,
      )[0];
      if (
        !dashboardProduct ||
        !dashboardProduct.ProductUser.privacyAgreementAccepted
      ) {
        this.props.dispatch(setPreviousUrl(path));
        return <Redirect to="/privacy-agreement" />;
      }

      return <WrappedComponent {...this.props} me={me} />;
    }
  }

  return compose(
    graphql(meQuery, {
      name: 'meData',
    }),
    connect(),
  )(withRouter(WithAuthentication));
};

export default AuthenticationWrapper;
