exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "h3{margin-bottom:30px}._1d_b0{margin:15px 0;padding-left:0;list-style-type:none}._1d_b0 li{padding:10px 0;border-top:1px solid #dcdcdc}._1d_b0 li span{position:relative;top:5px}._1d_b0 li:last-child{border-bottom:1px solid #dcdcdc}._3r-7N{margin:15px 0}", ""]);

// exports
exports.locals = {
	"shares": "_1d_b0",
	"segment": "_3r-7N"
};