import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaSearchPlus from 'react-icons/lib/fa/search-plus';
import FaPlusCircle from 'react-icons/lib/fa/plus-circle';
import FaEdit from 'react-icons/lib/fa/edit';
import FaDelete from 'react-icons/lib/fa/times-circle';
import SweetAlert from 'react-bootstrap-sweetalert';

import messages from './messages';
import compareValidityPeriod from './LicenseComparator';
import Table from '../Table/Table';
import TableStyle from '../Table/Table.scss'; // eslint-disable-line css-modules/no-unused-class
import Loading from '../modules/common/Loading/Loading';

class LicensesTable extends React.Component {
  static propTypes = {
    deleteLicense: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      licenses: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    };

    this.columns = [
      {
        Header: 'Id',
        accessor: 'id',
        width: 75,
      },
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 250,
      },
      {
        Header: 'Price',
        accessor: 'price',
        width: 100,
      },
      {
        Header: 'Credits',
        accessor: 'credits',
        width: 200,
      },
      {
        Header: 'Validity Period',
        accessor: 'validityPeriod',
        width: 125,
        sortMethod: compareValidityPeriod,
      },
      {
        Header: 'License Count',
        accessor: 'allowedNumberOfUsers',
        width: 125,
      },
      {
        Header: 'Enabled',
        accessor: 'enabled',
        width: 100,
        Cell: row => {
          const labelStyle = row.value
            ? TableStyle.labelYes
            : TableStyle.labelNo;
          const label = (
            <FormattedMessage {...(row.value ? messages.yes : messages.no)} />
          );
          return <div className={labelStyle}> {label} </div>;
        },
      },
      {
        Header: 'Roles Required',
        accessor: 'rolesRequired',
        minWidth: 250,
      },
      {
        Header: 'Action',
        width: 360,
        Cell: row => (
          <div>
            <Link to={`/admin/licenses/${row.original.id}/show`}>
              <button className={TableStyle.buttonLeft}>
                <FaSearchPlus className="fa--prepended" />
                <FormattedMessage {...messages.show} />
              </button>
            </Link>
            <Link to={`/admin/licenses/${row.original.id}/edit`}>
              <button className={TableStyle.buttonMiddle}>
                <FaEdit className="fa--prepended" />
                <FormattedMessage {...messages.edit} />
              </button>
            </Link>
            <button
              className={TableStyle.buttonRight}
              onClick={() => this.showDeleteLicensePropmt(row.original.id)}
            >
              <FaDelete className="fa--prepended" />
              <FormattedMessage {...messages.delete} />
            </button>
          </div>
        ),
      },
    ];

    this.showDeleteLicensePropmt = this.showDeleteLicensePropmt.bind(this);
    this.deleteLicenseById = this.deleteLicenseById.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  showDeleteLicensePropmt(id) {
    this.setState({
      alertOpts: {
        show: true,
        type: 'warning',
        title: <FormattedMessage {...messages.alertWarningTitle} />,
        msg: <FormattedMessage {...messages.alertDeleteWarningMessage} />,
        showCancel: true,
        onConfirm: () => this.deleteLicenseById(id),
        onCancel: this.hideAlert,
      },
    });
  }

  async deleteLicenseById(id) {
    this.hideAlert();

    const result = await this.props.deleteLicense(id);

    if (!result || result.status === 401) {
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.alertErrorTitle} />,
          msg: <FormattedMessage {...messages.alertDeleteErrorMessage} />,
          showCancel: false,
          onConfirm: this.hideAlert,
          onCancel: null,
        },
      });
    } else {
      this.setState({
        alertOpts: {
          show: true,
          type: 'success',
          title: <FormattedMessage {...messages.alertSuccessTitle} />,
          msg: <FormattedMessage {...messages.alertDeleteSuccessMessage} />,
          showCancel: false,
          onConfirm: this.hideAlert,
          onCancel: null,
        },
      });
    }
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        showCancel: false,
        onConfirm: null,
        onCancel: null,
      },
    });
  }

  render() {
    if (this.props.data.loading) {
      return <Loading />;
    }

    return (
      <div>
        <Table
          keyField="id"
          data={this.props.data.licenses}
          columns={this.columns}
        />
        <Link to="/admin/licenses/new">
          <button className={TableStyle.buttonSingleLarge}>
            <FaPlusCircle className="fa--prepended" />
            <FormattedMessage {...messages.add} />
          </button>
        </Link>
        {this.state.alertOpts.show && (
          <SweetAlert
            type={this.state.alertOpts.type}
            title={this.state.alertOpts.title}
            confirmBtnBsStyle={
              this.state.alertOpts.type === 'warning' ? 'danger' : 'primary'
            }
            cancelBtnBsStyle="default"
            showCancel={this.state.alertOpts.showCancel}
            onConfirm={this.state.alertOpts.onConfirm}
            onCancel={this.state.alertOpts.onCancel}
          >
            {this.state.alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const licensesQuery = gql`
  query licenses {
    licenses {
      id
      name
      price
      allowedNumberOfUsers
      validityPeriod
      credits
      enabled
    }
  }
`;

const deleteLicenseMutation = gql`
  mutation deleteLicense($id: ID!) {
    deleteLicense(id: $id) {
      id
    }
  }
`;

export default compose(
  graphql(licensesQuery, {
    name: 'data',
  }),
  graphql(deleteLicenseMutation, {
    props: ({ mutate }) => ({
      deleteLicense: id =>
        mutate({
          variables: { id },
          refetchQueries: [{ query: licensesQuery }],
        }),
    }),
  }),
)(withStyles(TableStyle)(LicensesTable));
