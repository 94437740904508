import React from 'react';
import PropTypes from 'prop-types';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import { Button } from 'react-bootstrap';

const messages = defineMessages({
  heading: {
    id: 'invite.heading',
    defaultMessage: 'Invite student',
    description: 'Heading for login form',
  },
  isActive: {
    id: 'invite.isActive',
    defaultMessage: 'Invite Active',
    description: 'Is Active button text in classRoom',
  },
  notActive: {
    id: 'invite.notActive',
    defaultMessage: 'Invite Inactive',
    description: 'Not Active button text in classRoom',
  },
  warning: {
    id: 'invite.warning',
    defaultMessage:
      'The classroom is now public joinable!!! As long as this setting is active, users can join this classroom !!!',
    description: 'ClassRoom QRInvite warning',
  },
  warningHeading: {
    id: 'invite.warningHeading',
    defaultMessage: 'WARNING',
    description: 'ClassRoom QRInvite warning',
  },
});

class QRForm extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
    initialValues: PropTypes.shape({
      isActive: PropTypes.bool.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  constructor(props, context) {
    super(props, context);
    const {
      initialValues: { isActive },
    } = props;
    this.state = {
      isActive,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const { onSubmit } = this.props;
    const { isActive } = this.state;
    this.setState(
      {
        isActive: !isActive,
      },
      () => onSubmit(this.state),
    );
  }

  render() {
    const { isActive } = this.state;
    const buttonMsg = (isActive && messages.isActive) || messages.notActive;

    return (
      <div>
        {isActive && (
          <div id="tdx-flash-container" className="text-center">
            <div className="alert alert-warning alert-dismissable">
              <h3>
                <FormattedMessage {...messages.warningHeading} />:
              </h3>
              <FormattedMessage {...messages.warning} />
            </div>
          </div>
        )}
        <Button
          bsStyle="primary"
          bsSize="large"
          onClick={this.handleSubmit}
          active={isActive}
        >
          <FormattedMessage {...buttonMsg} />
        </Button>
      </div>
    );
  }
}

export default injectIntl(QRForm);
