import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';

import render from '../modules/form/renderField';
import validations from '../modules/form/validations';
import validationMessages from '../modules/form/messages';

const messages = defineMessages({
  license: {
    id: 'addSubscription.license',
    defaultMessage: 'License',
    description: 'Label for license field in add subscription form',
  },
  owner: {
    id: 'addSubscription.owner',
    defaultMessage: 'Owner',
    description: 'Label for owner field in add subscription form',
  },
  submit: {
    id: 'addSubscription.submit',
    defaultMessage: 'Submit',
    description: 'Submit button text in add subscription form',
  },
});

const validate = values => {
  const errors = {};

  if (!validations.required(values.license)) {
    errors.license = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.required(values.owner)) {
    errors.owner = <FormattedMessage {...validationMessages.required} />;
  }

  return errors;
};

class AddSubscriptionForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    licenses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        username: PropTypes.string.isRequired,
      }),
    ).isRequired,
    errors: PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string,
      }),
    ),
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  render() {
    const { licenses, users, errors } = this.props;

    return (
      <form onSubmit={this.props.handleSubmit}>
        <fieldset>
          <Field
            id="license"
            name="license"
            label={this.props.intl.formatMessage(messages.license)}
            component={render.renderReactSelect}
            options={licenses.map(license => ({
              value: license.id,
              label: license.name,
            }))}
          />
          <Field
            id="owner"
            name="owner"
            label={this.props.intl.formatMessage(messages.owner)}
            component={render.renderReactSelect}
            options={users.map(user => ({
              value: user.id,
              label: `${user.username} (${user.email})`,
            }))}
          />
          {errors.length > 0 && (
            <ul className="bg-danger">
              {errors.map(error => (
                <li>{error.message}</li>
              ))}
            </ul>
          )}
          <button
            type="submit"
            className="btn btn-primary btn--full-width"
            disabled={this.props.submitting}
          >
            <FormattedMessage {...messages.submit} />
          </button>
        </fieldset>
      </form>
    );
  }
}

export default reduxForm({
  form: 'addSubscription',
  validate,
})(injectIntl(AddSubscriptionForm));
