import React from 'react';

import Registration from '../../components/Registration/Registration';

class RegistrationWrapper extends React.Component {
  render() {
    return (
      <div>
        <Registration />
      </div>
    );
  }
}

export default RegistrationWrapper;
