import React from 'react';
import { Panel, PanelGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FaFolder from 'react-icons/lib/fa/folder';
import FaFolderOpen from 'react-icons/lib/fa/folder-open';
import FaChevronDown from 'react-icons/lib/fa/chevron-down';
import FaChevronLeft from 'react-icons/lib/fa/chevron-left';
import FaChevronRight from 'react-icons/lib/fa/chevron-right';
import FaGroup from 'react-icons/lib/fa/group';
import FaUser from 'react-icons/lib/fa/user';
import FaExchange from 'react-icons/lib/fa/exchange';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './PanelNavTree.scss';
import messages from '../AdminLayout/messages';
import roles from '../../core/roles';

const navTree = [
  {
    name: messages.products,
    roles: [roles.MANAGE_PRODUCTS],
    subNavs: [
      {
        name: messages.productsProducts,
        path: '/admin/products',
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.classroom,
    filter: user => user.hasActiveAtomsLabSubscription,
    subNavs: [
      {
        name: messages.myClassroom,
        path: '/admin/classrooms',
      },
      {
        name: messages.mySharedClassRooms,
        path: '/admin/sharedclassrooms',
      },
      {
        name: messages.allClassroom,
        path: '/admin/allclassrooms',
        roles: [roles.MANAGE_CLASSROOMS],
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.licenses,
    roles: [roles.MANAGE_LICENSES],
    subNavs: [
      {
        name: messages.allLicenses,
        path: '/admin/licenses',
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.subscriptions,
    subNavs: [
      {
        name: messages.subscriptionsMe,
        path: '/admin/subscriptions/me',
      },
      {
        name: messages.subscriptionsOffice,
        path: '/admin/subscriptions/ms',
        roles: [roles.MANAGE_LICENSES],
      },
      {
        name: messages.subscriptionsLab,
        path: '/admin/subscriptions/wal',
        roles: [roles.MANAGE_LICENSES],
      },
      {
        name: messages.subscriptionsExpiration,
        path: '/admin/subscriptions/exp',
        roles: [roles.MANAGE_LICENSES],
      },
      {
        name: messages.addSubscription,
        path: '/admin/subscriptions/add',
        roles: [roles.MANAGE_LICENSES],
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.users,
    roles: [roles.MANAGE_USERS],
    subNavs: [
      {
        name: messages.usersUsers,
        path: '/admin/users',
      },
    ],
    openIcon: FaGroup,
    closedIcon: FaGroup,
  },
  {
    name: messages.api,
    roles: [roles.MANAGE_API_KEYS],
    subNavs: [
      {
        name: messages.clientTokens,
        path: '/admin/clients',
      },
    ],
    openIcon: FaExchange,
    closedIcon: FaExchange,
  },
  {
    name: messages.staticPages,
    roles: [roles.MANAGE_USERS],
    subNavs: [
      {
        name: messages.pages,
        path: '/admin/staticpages',
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  user => ({
    name: messages.user,
    roles: [],
    subNavs: [
      {
        name: messages.userUser,
        path: `/admin/users/${user.id}/edit`,
      },
      {
        name: messages.userPrivacy,
        path: `/admin/privacy`,
      },
    ],
    openIcon: FaUser,
    closedIcon: FaUser,
  }),
];
class PanelNavTree extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      userRoles: PropTypes.array.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    panelId: PropTypes.string.isRequired,
    collapse: PropTypes.func,
  };

  static defaultProps = {
    collapse: () => {},
  };

  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      activeKey: null,
    };
  }
  handleSelect(activeKey) {
    this.setState({ activeKey });
  }

  render() {
    const { me } = this.props;
    const { userRoles } = me;
    return (
      <PanelGroup
        accordion
        id={this.props.panelId}
        activeKey={this.state.activeKey}
        onSelect={this.handleSelect}
      >
        {navTree.map((nav, index) => {
          if (typeof nav === 'function') {
            // eslint-disable-next-line no-param-reassign
            nav = nav(me);
          }
          const showNav =
            (!nav.roles || nav.roles.every(r => userRoles.includes(r))) &&
            (!nav.filter || nav.filter(this.props.me));
          if (!showNav) return null;

          const key = `nav_${index}`;
          const Icon =
            this.state.activeKey === index ? nav.openIcon : nav.closedIcon;
          const IconChevron =
            this.state.activeKey === index ? FaChevronDown : FaChevronLeft;
          const navTitle =
            this.state.activeKey === index ? s.navTitleSelected : s.navTitle;

          return (
            <Panel className={s.navPanel} key={key} eventKey={index}>
              <Panel.Heading className={s.navHeading}>
                <Panel.Title className={navTitle} toggle>
                  <Icon className="fa--prepended" />
                  <span>
                    <FormattedMessage {...nav.name} />
                  </span>
                  <IconChevron className={s.iconRight} />
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body className={s.navBody} collapsible>
                {nav.subNavs
                  .filter(
                    subNav =>
                      !subNav.roles ||
                      subNav.roles.every(r => userRoles.includes(r)),
                  )
                  .map((subNav, subIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className={s.subNav} key={`subNav_${subIndex}`}>
                      <Link to={subNav.path} onClick={this.props.collapse}>
                        <FaChevronRight className="fa--prepended" />
                        <FormattedMessage {...subNav.name} />
                      </Link>
                    </div>
                  ))}
              </Panel.Body>
            </Panel>
          );
        })}
      </PanelGroup>
    );
  }
}
export default withStyles(s)(PanelNavTree);
