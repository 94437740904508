exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3dXGV{display:block;width:100%;padding:15px;-webkit-box-shadow:2px 2px 5px hsla(0,0%,39%,.7);box-shadow:2px 2px 5px hsla(0,0%,39%,.7)}._3dXGV h2{margin:0 0 5px}._3dXGV img{width:100%}._3dXGV ._1sT-C{display:none;position:absolute;top:0;left:0;width:100%;height:100%;color:#fff;text-align:center;line-height:1em;background:rgba(30,180,170,.95)}._3dXGV ._1sT-C svg{display:block;margin:20px auto 0}._3dXGV ._1sT-C span{font-size:.8em}._3dXGV .UWOHq{width:70%;margin-right:10%;float:left}._3dXGV ._3Sgaj{width:20%;float:left}._3dXGV:hover{cursor:pointer}._3dXGV:hover ._1sT-C{display:block}", ""]);

// exports
exports.locals = {
	"productCard": "_3dXGV",
	"lock": "_1sT-C",
	"content": "UWOHq",
	"icon": "_3Sgaj"
};