import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import SweetAlert from 'react-bootstrap-sweetalert';
import fetch from 'node-fetch';

import messages from './messages';
import registraionMessages from '../Registration/messages';
import LoginForm from './LoginForm';
import { withLogin } from '../modules/auth/withLogin';
import { to } from '../../util';
import professorImg from './professor.svg';

class Login extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    loginOverRest: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
    user: PropTypes.shape({
      isLoggedIn: PropTypes.bool.isRequired,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    user: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
        confirmBtnBsStyle: 'primary',
      },
    };

    this.hideAlert = this.hideAlert.bind(this);
    this.submitLoginForm = this.submitLoginForm.bind(this);
  }

  componentDidMount() {
    // check if url contains activation code parameter
    const getParameter = this.props.location.search;
    let activationCode = '';
    if (getParameter) {
      const params = getParameter.split('=');
      if (params[0].replace('?', '') === 'code') {
        activationCode = params[1];
        this.activateUser(activationCode);
      }
    }
  }

  setErrors(errors) {
    this.setState({
      errors: errors.map(err => err.message),
    });
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        showCancel: false,
        onConfirm: null,
        onCancel: null,
        confirmBtnBsStyle: 'primary',
      },
    });
  }

  async activateUser(activationCode) {
    let result;
    if (activationCode) {
      result = await fetch(`/api/v1/public/activate/${activationCode}`, {
        method: 'GET',
      });
    }
    if (!result || result.status === 404) {
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...registraionMessages.alertErrorTitle} />,
          msg: <FormattedMessage {...messages.alertActivationErrorMessage} />,
          onConfirm: this.hideAlert,
        },
      });
    } else {
      this.setState({
        alertOpts: {
          show: true,
          type: 'success',
          title: (
            <FormattedMessage {...registraionMessages.alertSuccessTitle} />
          ),
          msg: <FormattedMessage {...messages.alertActivationSuccessMessage} />,
          showCancel: false,
          onConfirm: this.hideAlert,
        },
      });
    }
  }

  async submitLoginForm({ username, password }) {
    const { loginOverRest, history, intl } = this.props;
    const [err, result] = await to(loginOverRest(username, password));

    if (err) {
      console.error(err);
      return;
    }

    if (!result || result.status === 401) {
      this.setErrors([
        { message: intl.formatMessage(messages.invalidCredentials) },
      ]);
    } else {
      history.push({ pathname: '/dashboard' });
    }
  }

  render() {
    const { user } = this.props;
    const { alertOpts } = this.state;

    if (user && user.isLoggedIn) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <Row>
        <Col md={6} xs={12} smHidden xsHidden>
          <img
            src={professorImg}
            alt="Waltzing Atoms"
            style={{ display: 'block', width: '80%', margin: '15px auto 0' }}
          />
        </Col>
        <Col md={6} xs={12}>
          <LoginForm
            onSubmit={this.submitLoginForm}
            errors={this.state.errors}
          />
        </Col>
        {alertOpts.show && (
          <SweetAlert
            type={alertOpts.type}
            title={alertOpts.title}
            confirmBtnBsStyle={alertOpts.confirmBtnBsStyle}
            cancelBtnBsStyle="default"
            showCancel={alertOpts.showCancel}
            onConfirm={alertOpts.onConfirm}
            onCancel={alertOpts.onCancel}
          >
            {alertOpts.msg}
          </SweetAlert>
        )}
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default withRouter(
  withLogin(injectIntl(connect(mapStateToProps)(Login))),
);
