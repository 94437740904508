import React from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import FaTrash from 'react-icons/lib/fa/trash';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Loading from 'components/modules/common/Loading/Loading';
import gate from 'components/Auth/gate';

import s from './SubscriptionSharing.scss';
import messages from './messages';
import SubscriptionSharingForm from './SubscriptionSharingForm';

class SubscriptionSharing extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      me: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
      subscription: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
    shareSubscription: PropTypes.func.isRequired,
    removeShareFromSubscription: PropTypes.func.isRequired,
    removeInviteFromSubscription: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.removeSubscriptionShare = this.removeSubscriptionShare.bind(this);
    this.removeSubscriptionInvite = this.removeSubscriptionInvite.bind(this);
  }

  async handleSubmit(formValues) {
    const emails = formValues.invites;
    await this.props.shareSubscription({
      subscriptionId: this.props.match.params.id,
      emails,
    });
  }

  async removeSubscriptionShare({ userId }) {
    await this.props.removeShareFromSubscription({
      subscriptionId: this.props.match.params.id,
      userId,
    });
  }

  async removeSubscriptionInvite({ email }) {
    await this.props.removeInviteFromSubscription({
      subscriptionId: this.props.match.params.id,
      email,
    });
  }

  render() {
    const { loading, me, subscription } = this.props.data;
    if (loading) return <Loading />;
    if (!gate.isSubscriptionOwner({ user: me, subscription })) {
      return <Redirect to="/admin/subscriptions/me" />;
    }

    return (
      <div>
        <h3>{subscription.license.name}</h3>
        <FormattedMessage {...messages.sharedUsers} />
        <ul className={s.shares}>
          {subscription.users.length > 0 &&
            subscription.users.map(user => (
              <li key={user.id} className="clearfix">
                <span>{user.email}</span>
                <button
                  type="button"
                  className="btn"
                  onClick={() =>
                    this.removeSubscriptionShare({ userId: user.id })
                  }
                >
                  <FaTrash />
                </button>
              </li>
            ))}
          {subscription.users.length === 0 && (
            <div>
              <FormattedMessage {...messages.noEntries} />
              <hr />
            </div>
          )}
        </ul>
        <h4>
          <FormattedMessage {...messages.pendingInvitations} />
        </h4>
        <ul className={s.shares}>
          {subscription.invites.length > 0 &&
            subscription.invites.map(invite => (
              <li key={invite} className="clearfix">
                <span>{invite}</span>
                <button
                  type="button"
                  className="btn"
                  onClick={() =>
                    this.removeSubscriptionInvite({ email: invite })
                  }
                >
                  <FaTrash />
                </button>
              </li>
            ))}
          {subscription.invites.length === 0 && (
            <div>
              <FormattedMessage {...messages.noEntries} />
              <hr />
            </div>
          )}
        </ul>
        <h4>
          <FormattedMessage {...messages.inviteUsers} />
        </h4>
        <SubscriptionSharingForm
          onSubmit={this.handleSubmit}
          initialValues={{
            invites: [''],
          }}
        />
      </div>
    );
  }
}

const subscriptionQuery = gql`
  query subscription($id: Int!) {
    me {
      id
    }
    subscription(id: $id) {
      id
      owner {
        id
      }
      license {
        name
        allowedNumberOfUsers
      }
      users {
        id
        username
        email
      }
      invites
    }
  }
`;

const shareSubscriptionMutation = gql`
  mutation shareSubscription($data: ShareSubscriptionInput!) {
    shareSubscription(data: $data) {
      id
      users {
        id
        username
        email
      }
      invites
    }
  }
`;

const removeShareFromSubscriptionMutation = gql`
  mutation removeShareFromSubscription($data: RemoveShareSubscriptionInput!) {
    removeShareFromSubscription(data: $data) {
      id
      users {
        id
        username
        email
      }
    }
  }
`;

const removeInviteFromSubscriptionMutation = gql`
  mutation removeInviteFromSubscription($data: RemoveInviteSubscriptionInput!) {
    removeInviteFromSubscription(data: $data) {
      id
      invites
    }
  }
`;

export default compose(
  graphql(subscriptionQuery, {
    name: 'data',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
  }),
  graphql(shareSubscriptionMutation, {
    props: ({ mutate }) => ({
      shareSubscription: data =>
        mutate({
          variables: { data },
        }),
    }),
  }),
  graphql(removeShareFromSubscriptionMutation, {
    props: ({ mutate }) => ({
      removeShareFromSubscription: data =>
        mutate({
          variables: { data },
        }),
    }),
  }),
  graphql(removeInviteFromSubscriptionMutation, {
    props: ({ mutate }) => ({
      removeInviteFromSubscription: data =>
        mutate({
          variables: { data },
        }),
    }),
  }),
)(withStyles(s)(SubscriptionSharing));
