import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import SweetAlert from 'react-bootstrap-sweetalert';

import messages from './messages';
import s from './EditClientToken.scss'; // eslint-disable-line css-modules/no-unused-class
import Loading from '../modules/common/Loading/Loading';
import EditClientTokenForm from './EditClientTokenForm';
import { clientTokensQuery } from './ClientTokenTable';

class EditClientToken extends React.Component {
  static propTypes = {
    updateClientToken: PropTypes.func.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      products: PropTypes.object,
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
      closeAfterSaving: false,
    };

    this.submitClientTokenChanges = this.submitClientTokenChanges.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertAndClose = this.hideAlertAndClose.bind(this);
    this.closeAfterSaving = this.closeAfterSaving.bind(this);
  }

  async submitClientTokenChanges(formValues) {
    this.hideAlert();

    const result = await this.props.updateClientToken(
      this.props.match.params.id,
      {
        name: formValues.name,
        productId: formValues.productId,
        token: formValues.token,
        secret: formValues.secret,
        redirectUris: formValues.redirectUris,
        grants: formValues.grants,
        accessTokenLifetime: formValues.accessTokenLifetime,
      },
    );

    if (!result || result.status === 401) {
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.alertErrorTitle} />,
          msg: <FormattedMessage {...messages.alertErrorMessage} />,
          onConfirm: this.hideAlert,
        },
      });
    } else {
      this.setState({
        alertOpts: {
          show: true,
          type: 'success',
          title: <FormattedMessage {...messages.alertSuccessTitle} />,
          msg: <FormattedMessage {...messages.alertSuccessMessage} />,
          onConfirm: this.state.closeAfterSaving
            ? this.hideAlertAndClose
            : this.hideAlert,
        },
      });
    }
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });
  }

  hideAlertAndClose() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });

    if (this.state.closeAfterSaving) {
      this.props.history.push({
        pathname: '/admin/clients',
      });
    }
  }

  closeAfterSaving(shouldClose) {
    this.setState({
      closeAfterSaving: shouldClose,
    });
  }

  render() {
    if (this.props.data.loading) {
      return <Loading />;
    }

    const { products } = this.props.data;

    const title = <FormattedMessage {...messages.title} />;

    return (
      <div>
        <h2>{title}</h2>
        <EditClientTokenForm
          onSubmit={this.submitClientTokenChanges}
          closeAfterSaving={this.closeAfterSaving}
          initialValues={{}}
          products={products}
        />
        {this.state.alertOpts.show && (
          <SweetAlert
            type={this.state.alertOpts.type}
            title={this.state.alertOpts.title}
            onConfirm={this.state.alertOpts.onConfirm}
          >
            {this.state.alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const productsQuery = gql`
  query products {
    products {
      id
      name
    }
  }
`;

const updateClientTokenMutation = gql`
  mutation updateClientToken($patch: ClientTokenInput!) {
    createOrUpdateClientToken(patch: $patch) {
      id
      name
      productId
      token
      secret
      redirectUris
      grants
      accessTokenLifetime
    }
  }
`;

export default compose(
  graphql(productsQuery, {
    name: 'data',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
  }),
  graphql(updateClientTokenMutation, {
    props: ({ mutate }) => ({
      updateClientToken: (id, patch) =>
        mutate({
          variables: { id, patch },
          refetchQueries: [
            {
              query: clientTokensQuery,
            },
          ],
        }),
    }),
  }),
)(withStyles(s)(EditClientToken));
