import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import FaSearchPlus from 'react-icons/lib/fa/search-plus';
import FaEdit from 'react-icons/lib/fa/edit';

import Table from '../Table/Table';
import Loading from '../modules/common/Loading/Loading';
import messages from './messages';
import componentMessages from '../messages';
import TableStyle from '../Table/Table.scss'; // eslint-disable-line css-modules/no-unused-class

const columns = [
  {
    Header: <FormattedMessage {...messages.key} />,
    accessor: 'key',
    minWidth: 100,
  },
  {
    Header: <FormattedMessage {...messages.name} />,
    accessor: 'name',
    minWidth: 200,
  },
  {
    Header: <FormattedMessage {...messages.shortDescription} />,
    accessor: 'shortDescription',
    minWidth: 500,
  },
  {
    Header: <FormattedMessage {...componentMessages.actionHeader} />,
    width: 360,
    Cell: row => (
      <div>
        <Link to={`products/${row.original.id}/show`}>
          <button className={TableStyle.buttonLeft}>
            <FaSearchPlus className="fa--prepended" />
            <FormattedMessage {...componentMessages.show} />
          </button>
        </Link>
        <Link to={`products/${row.original.id}/edit`}>
          <button className={TableStyle.buttonRight}>
            <FaEdit className="fa--prepended" />
            <FormattedMessage {...componentMessages.edit} />
          </button>
        </Link>
      </div>
    ),
  },
];

class ProductTable extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      products: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  };

  render() {
    if (this.props.data.loading) {
      return <Loading />;
    }

    return (
      <div>
        <Table
          isCheckable
          keyField="id"
          data={this.props.data.products}
          columns={columns}
        />
      </div>
    );
  }
}

const productsQuery = gql`
  query products {
    products {
      id
      key
      name
      shortDescription
      description
      createdAt
    }
  }
`;

export default compose(
  graphql(productsQuery, {
    name: 'data',
  }),
)(ProductTable);
