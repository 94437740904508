import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import s from './BurgerBar.scss';
import PanelNavTree from '../PanelNavTree';

class BurgerBar extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      userRoles: PropTypes.array.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    collapse: PropTypes.func.isRequired,
  };

  render() {
    const { me } = this.props;
    const { userRoles } = me;

    return (
      <div className={s.burgerNav}>
        <PanelNavTree
          me={me}
          userRoles={userRoles}
          panelId="burgerNav"
          collapse={this.props.collapse}
        />
      </div>
    );
  }
}

export default AuthenticationWrapper(withStyles(s)(BurgerBar));
