/* global __SERVER_URL__ */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose, graphql } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import gql from 'graphql-tag';
import QRcode from 'qrcode';
import { Tabs, Tab } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { defineMessages, FormattedMessage } from 'react-intl';

import s from './CreateClassRoom.scss'; // eslint-disable-line css-modules/no-unused-class

import { to } from '../../util';
import InviteForm from './InviteForm';
import Loading from '../modules/common/Loading/Loading';
import QRForm from './QRForm';

const messages = defineMessages({
  alertSuccessTitleInvite: {
    id: 'invite.alertSuccessTitleInvite',
    defaultMessage: 'Success',
    description: 'Header for success alert dialog',
  },
  alertSuccessMessageInvite: {
    id: 'invite.alertSuccessMessageInvite',
    defaultMessage: 'Invites where sent successfully!',
    description: 'Message for success alert dialog',
  },
  alertErrorTitleInvite: {
    id: 'invite.alertErrorTitleInvite',
    defaultMessage: 'Error',
    description: 'Header for error alert dialog',
  },
  alertErrorMessageInvite: {
    id: 'invite.alertErrorMessageInvite',
    defaultMessage: 'There has been an error while sending the Invites!',
    description: 'Message for error alert dialog',
  },
  alertErrorTitleActive: {
    id: 'invite.alertErrorTitleActive',
    defaultMessage: 'Error',
    description: 'Header for error alert dialog',
  },
  alertErrorMessageActive: {
    id: 'invite.alertErrorMessageActive',
    defaultMessage:
      'There has been an error while changing the classrooms active state!',
    description: 'Message for error alert dialog',
  },
});

class Invite extends Component {
  static propTypes = {
    classRoom: PropTypes.shape({}).isRequired,
    loading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      dataURL: '',
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitIsActiveChange = this.handleSubmitIsActiveChange.bind(
      this,
    );
    this.handleSelect = this.handleSelect.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  async handleSubmit(formValues) {
    this.hideAlert();
    const { createInvite, match } = this.props;
    const emails = formValues.email.split(';');
    const [err, result] = await to(
      createInvite({
        id: match.params.id,
        emails,
      }),
    );
    if (err || (result && !result.data.createInvitation)) {
      console.error(err);
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.alertErrorTitleInvite} />,
          msg: <FormattedMessage {...messages.alertErrorMessageInvite} />,
          onConfirm: this.hideAlert,
        },
      });
    } else {
      this.setState({
        alertOpts: {
          show: true,
          type: 'success',
          title: <FormattedMessage {...messages.alertSuccessTitleInvite} />,
          msg: <FormattedMessage {...messages.alertSuccessMessageInvite} />,
          onConfirm: this.hideAlert,
        },
      });
    }
  }

  async handleSubmitIsActiveChange({ isActive }) {
    const { createOrUpdate, match } = this.props;

    const [err, result] = await to(
      createOrUpdate({
        id: match.params.id,
        patch: {
          isActive,
        },
      }),
    );
    if (err || (result && !result.data.createOrUpdateClassRoom)) {
      console.error(err);
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.alertErrorTitleInvite} />,
          msg: <FormattedMessage {...messages.alertErrorMessageInvite} />,
          onConfirm: this.hideAlert,
        },
      });
      return;
    }
    this.setState({
      dataURL: await QRcode.toDataURL(
        `${__SERVER_URL__}/api/v1/invitation/${
          result.data.createOrUpdateClassRoom.joinToken
        }`,
      ),
    });
  }

  async handleSelect(key) {
    const { classRoom } = this.props;
    const patch = {
      key,
    };
    if (classRoom.joinToken) {
      patch.dataURL = await QRcode.toDataURL(
        `${__SERVER_URL__}/api/v1/invitation/${classRoom.joinToken}`,
      );
    }
    this.setState(patch);
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        showCancel: false,
        onConfirm: null,
        onCancel: null,
      },
    });
  }

  render() {
    const { loading, classRoom } = this.props;
    if (loading) {
      return <Loading />;
    }
    const { dataURL, alertOpts } = this.state;
    const titel = classRoom.name;
    return (
      <div>
        <h2>{titel}</h2>
        <Tabs
          activeKey={this.state.key}
          onSelect={this.handleSelect}
          id="controlled-tab-example"
        >
          <Tab eventKey={1} title="Email">
            <InviteForm
              onSubmit={this.handleSubmit}
              initialValues={classRoom}
            />
          </Tab>
          <Tab eventKey={2} title="QRCode">
            <img src={dataURL} alt="" />
            <QRForm
              onSubmit={this.handleSubmitIsActiveChange}
              initialValues={classRoom}
            />
          </Tab>
        </Tabs>
        {alertOpts.show && (
          <SweetAlert
            type={alertOpts.type}
            title={alertOpts.title}
            confirmBtnBsStyle={
              alertOpts.type === 'warning' ? 'danger' : 'primary'
            }
            cancelBtnBsStyle="default"
            showCancel={alertOpts.showCancel}
            onConfirm={alertOpts.onConfirm}
            onCancel={alertOpts.onCancel}
          >
            {alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const createOrUpdateMutation = gql`
  mutation createOrUpdate($id: ID!, $patch: ClassRoomInput) {
    createOrUpdateClassRoom(id: $id, patch: $patch) {
      id
      name
      isActive
      joinToken
    }
  }
`;
const createInviteMutation = gql`
  mutation createInvitation($id: String!, $emails: [String!]) {
    createInvitation(id: $id, emails: $emails) {
      id
      name
      joinToken
      isActive
      invites {
        token
        referencedId
        email
      }
    }
  }
`;

const classRoomQuery = gql`
  query classRoomQuery($id: String!) {
    classRoom(id: $id) {
      id
      name
      joinToken
      isActive
      owner {
        id
        username
      }
      students {
        id
        username
      }
      invites {
        token
        referencedId
        email
      }
    }
  }
`;

export default compose(
  withRouter,
  graphql(createOrUpdateMutation, {
    props: ({ mutate }) => ({
      createOrUpdate: classRoom =>
        mutate({
          variables: { ...classRoom },
        }),
    }),
  }),
  graphql(createInviteMutation, {
    props: ({ mutate }) => ({
      createInvite: classRoom =>
        mutate({
          variables: { ...classRoom },
        }),
    }),
  }),
  graphql(classRoomQuery, {
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
    props: ({ data }) => ({
      classRoom: (!data.loading && data.classRoom) || {},
      loading: data.loading,
    }),
  }),
)(withStyles(s)(Invite));
