import { defineMessages } from 'react-intl';

export const messagesSet = defineMessages({
  heading: {
    id: 'set-password.heading',
    defaultMessage: 'Set New Password',
    description: 'Heading for set password form',
  },
  info: {
    id: 'set-password.info',
    defaultMessage: `
      Please enter your new password twice in order to mitigate typing errors.
    `,
    description: 'description',
  },
  password: {
    id: 'set-password.password',
    defaultMessage: 'Password',
    description: 'Password field placeholder text in set password form',
  },
  passwordRepeat: {
    id: 'set-password.passwordRepeat',
    defaultMessage: 'Repeat password',
    description: 'Password repeat field placeholder text in set password form',
  },
  submit: {
    id: 'set-password.submit',
    defaultMessage: 'Set new password',
    description: 'Submit button text in set password form',
  },
  cancel: {
    id: 'set-password.cancel',
    defaultMessage: 'Cancel',
    description: 'Cancel button text in set password form',
  },
  alertSuccessTitle: {
    id: 'set-password.alertSuccessTitle',
    defaultMessage: 'Success',
    description: 'Header for success alert dialog',
  },
  alertSuccessMessage: {
    id: 'set-password.alertSuccessMessage',
    defaultMessage: 'Password was resetted!',
    description: 'Message for success alert dialog',
  },
});

export const messagesReset = defineMessages({
  heading: {
    id: 'reset-password.heading',
    defaultMessage: 'Reset Password',
    description: 'Heading for login form',
  },
  info: {
    id: 'reset-password.info',
    defaultMessage: `
      Please enter your E-Mail Address or Username so that we can send you a link you can use to reset your password.
    `,
    description: 'description',
  },
  username: {
    id: 'reset-password.username',
    defaultMessage: 'Username or E-Mail Address',
    description: 'E-Mail field placeholder text in reset password form',
  },
  submit: {
    id: 'reset-password.submit',
    defaultMessage: 'Request reset link',
    description: 'Submit button text in reset password form',
  },
  cancel: {
    id: 'reset-password.cancel',
    defaultMessage: 'Cancel',
    description: 'Cancel button text in reset password form',
  },
  alertSuccessTitle: {
    id: 'reset-password.alertSuccessTitle',
    defaultMessage: 'Success',
    description: 'Header for success alert dialog',
  },
  alertSuccessMessage: {
    id: 'reset-password.alertSuccessMessage',
    defaultMessage: 'E-Mail was sent!',
    description: 'Message for success alert dialog',
  },
});

export default { messagesSet, messagesReset };
