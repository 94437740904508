exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1W85S{z-index:1;height:100%;background-color:#fff;margin:10px 0 0;padding:5px 0 0}@media (min-width:767px){._1W85S{display:none}}", ""]);

// exports
exports.locals = {
	"burgerNav": "_1W85S"
};