import { defineMessages } from 'react-intl';

/**
 * see https://stripe.com/docs/error-codes for error codes
 */
const messages = defineMessages({
  genericError: {
    id: 'payment.error.genericError',
    defaultMessage: 'There has been an error with your card information.',
    description: 'Generic error message for invalid card information',
  },

  no_license_selected: {
    id: 'payment.error.no_license_selected',
    defaultMessage: 'You need to select a license.',
    description: 'Error message for missing license selection',
  },

  invalid_number: {
    id: 'payment.error.invalid_number',
    defaultMessage: 'Your card number is invalid.',
    description: 'Error message for invalid card number',
  },
  incomplete_number: {
    id: 'payment.error.incomplete_number',
    defaultMessage: 'Your card number is incomplete.',
    description: 'Error message for incomplete card number',
  },
  incorrect_number: {
    id: 'payment.error.incorrect_number',
    defaultMessage: 'Your card number is incorrect.',
    description: 'Error message for incorrect card number',
  },

  invalid_expiry_year: {
    id: 'payment.error.invalid_expiry_year',
    defaultMessage: `Your card's expiration year is invalid.`,
    description: 'Error message for invalid expiry year',
  },
  invalid_expiry_year_past: {
    id: 'payment.error.invalid_expiry_year_past',
    defaultMessage: `Your card's expiration year is in the past.`,
    description: 'Error message for invalid expiry year in past',
  },
  incomplete_expiry: {
    id: 'payment.error.incomplete_expiry',
    defaultMessage: `Your card's expiration date is incomplete.`,
    description: 'Error message for incomplete expiry year',
  },

  invalid_cvc: {
    id: 'payment.error.invalid_cvc',
    defaultMessage: `Your card's security code is invalid.`,
    description: 'Error message for invalid security code',
  },
  incomplete_cvc: {
    id: 'payment.error.incomplete_cvc',
    defaultMessage: `Your card's security code is incomplete.`,
    description: 'Error message for incomplete security code',
  },
  incorrect_cvc: {
    id: 'payment.error.incorrect_cvc',
    defaultMessage: `Your card's security code is incorrect.`,
    description: 'Error message for incorrect security code',
  },

  invalid_zip: {
    id: 'payment.error.invalid_zip',
    defaultMessage: `Your card's postal code is invalid.`,
    description: 'Error message for invalid postal code',
  },
  incomplete_zip: {
    id: 'payment.error.incomplete_zip',
    defaultMessage: `Your card's postal code is incomplete.`,
    description: 'Error message for incomplete postal code',
  },
  incorrect_zip: {
    id: 'payment.error.incorrect_zip',
    defaultMessage: `Your card's postal code is incorrect.`,
    description: 'Error message for incorrect postal code',
  },
});

export default messages;
