/* global __STRIPE_PUBLIC_KEY__ */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { StripeProvider, Elements } from 'react-stripe-elements';
import SweetAlert from 'react-bootstrap-sweetalert';

import PaymentForm from './PaymentForm';
import messages from './messages';
import Loading from '../modules/common/Loading/Loading';

class StripePayment extends React.Component {
  static propTypes = {
    afterSuccess: PropTypes.func.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      me: PropTypes.shape({
        id: PropTypes.string.isRequired,
        isEligibleForAtomsTrial: PropTypes.bool.isRequired,
      }),
      purchasableLicenses: PropTypes.arrayOf(PropTypes.object),
      products: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    activateAtomsTrial: PropTypes.func.isRequired,
    createPayment: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      stripe: null,
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
      showPaymentForm: false,
    };

    this.showPaymentForm = this.showPaymentForm.bind(this);
    this.showTrialForm = this.showTrialForm.bind(this);
    this.handleTrialActivation = this.handleTrialActivation.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  componentDidMount() {
    // taken from https://github.com/stripe/react-stripe-elements#server-side-rendering-ssr
    if (window.Stripe) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ stripe: window.Stripe(__STRIPE_PUBLIC_KEY__) });
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(__STRIPE_PUBLIC_KEY__) });
      });
    }
  }

  showPaymentForm() {
    this.setState({
      showPaymentForm: true,
    });
  }

  showTrialForm() {
    this.setState({
      showPaymentForm: false,
    });
  }

  async handleTrialActivation() {
    this.hideAlert();

    const result = await this.props.activateAtomsTrial();
    if (result.data.activateAtomsTrial.success) {
      this.setState({
        alertOpts: {
          show: true,
          type: 'success',
          title: <FormattedMessage {...messages.success} />,
          msg: <FormattedMessage {...messages.trialActivated} />,
          onConfirm: () => {
            this.hideAlert();
            this.props.afterSuccess();
          },
        },
      });
    } else {
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.error} />,
          msg: result.data.errors.join('\n'),
          onConfirm: this.hideAlert,
        },
      });
    }
  }

  async handlePayment({ token, licenseId }) {
    this.hideAlert();

    const result = await this.props.createPayment({
      token,
      licenseId,
    });
    // eslint-disable-next-line no-console
    console.log('result: ', result);
    // result.createPayment.data -> { success, errors }

    if (result.data.createPayment.success) {
      this.setState({
        alertOpts: {
          show: true,
          type: 'success',
          title: <FormattedMessage {...messages.success} />,
          msg: <FormattedMessage {...messages.purchaseMessage} />,
          onConfirm: () => {
            this.hideAlert();
            this.props.afterSuccess();
          },
        },
      });
    } else {
      // TODO - get error message from result.data.createPayment.errors (will always be 1, as stripe only returns one error-code)
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.error} />,
          msg: 'TODO', // <FormattedMessage {...messages.alertDeleteSuccessMessage} />, // TODO - error messages based on error codes from backend
          onConfirm: this.hideAlert,
        },
      });
    }
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });
  }

  render() {
    if (this.props.data.loading) {
      return <Loading />;
    }

    const { me, purchasableLicenses, products } = this.props.data;

    return (
      <div>
        {/* eslint-disable-next-line prettier/prettier */}
        {(me.isEligibleForAtomsTrial && !this.state.showPaymentForm) && (
            <div>
              <h2>
                <FormattedMessage {...messages.trialLicenseHeading} />
              </h2>
              <p>
                <FormattedMessage {...messages.trialLicenseDescription} />
              </p>
              <button
                className="btn btn-primary"
                onClick={this.handleTrialActivation}
              >
                <FormattedMessage {...messages.trialLicenseButtonLabel} />
              </button>
              <button className="btn btn-link" onClick={this.showPaymentForm}>
                <FormattedMessage {...messages.showPaymentForm} />
              </button>
            </div>
          )}
        {(!me.isEligibleForAtomsTrial || this.state.showPaymentForm) && (
          <div>
            <StripeProvider stripe={this.state.stripe}>
              <Elements>
                <PaymentForm
                  handlePayment={this.handlePayment}
                  licenses={purchasableLicenses}
                  products={products}
                />
              </Elements>
            </StripeProvider>
            {me.isEligibleForAtomsTrial && (
              <button className="btn btn-link" onClick={this.showTrialForm}>
                <FormattedMessage {...messages.showTrialForm} />
              </button>
            )}
          </div>
        )}
        {this.state.alertOpts.show && (
          <SweetAlert
            type={this.state.alertOpts.type}
            title={this.state.alertOpts.title}
            confirmBtnBsStyle={
              this.state.alertOpts.type === 'warning' ? 'danger' : 'primary'
            }
            onConfirm={this.state.alertOpts.onConfirm}
          >
            {this.state.alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const licensesQuery = gql`
  query data {
    me {
      id
      isEligibleForAtomsTrial
    }
    products {
      id
      name
    }
    purchasableLicenses {
      id
      name
      price
      allowedNumberOfUsers
      validityPeriod
      enabled
      products {
        id
      }
    }
  }
`;

export const activateAtomsTrialMutation = gql`
  mutation activateAtomsTrial($userId: Int) {
    activateAtomsTrial(userId: $userId) {
      success
      errors
      user {
        id
        hasActiveAtomsLabSubscription
        hasActiveAtomsOfficeSubscription
        isEligibleForAtomsTrial
      }
    }
  }
`;

const createPaymentMutation = gql`
  mutation createPayment($data: PaymentInput!) {
    createPayment(data: $data) {
      success
      errors
      user {
        id
        hasActiveAtomsLabSubscription
        hasActiveAtomsOfficeSubscription
        isEligibleForAtomsTrial
      }
    }
  }
`;

export default compose(
  graphql(licensesQuery, {
    name: 'data',
  }),
  graphql(createPaymentMutation, {
    props: ({ mutate }) => ({
      createPayment: data =>
        mutate({
          variables: { data },
        }),
    }),
  }),
  graphql(activateAtomsTrialMutation, {
    props: ({ mutate }) => ({
      activateAtomsTrial: () => mutate({}),
    }),
  }),
)(StripePayment);
