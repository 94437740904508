import { defineMessages } from 'react-intl';

const messages = defineMessages({
  required: {
    id: 'validation.required',
    defaultMessage: 'This field is required',
    description: 'Validation error message for required fields',
  },
  usernameExists: {
    id: 'validation.usernameExists',
    defaultMessage: 'This username has already been taken',
    description:
      'Validation error message for usernames already taken by a user',
  },
  email: {
    id: 'validation.email',
    defaultMessage: 'Not a valid e-mail address',
    description: 'Validation error message for malformed e-mail addresses',
  },
  emailExists: {
    id: 'validation.emailExists',
    defaultMessage: 'This e-mail address has already been taken',
    description:
      'Validation error message for e-mail addresses already taken by a user',
  },
  password: {
    id: 'validation.password',
    defaultMessage:
      'Your password must be at least 8 characters long, containing an upper case letter, digit and special character',
    description: 'Validation error message for invalid password',
  },
  passwordConfirmation: {
    id: 'validation.passwordConfirmation',
    defaultMessage: 'Please enter the same password twice',
    description:
      'Validation error message if password and password confirmation do not match',
  },
  submit: {
    id: 'form.submit',
    defaultMessage: 'Save',
    description: 'Default submit button label',
  },
  cancel: {
    id: 'form.cancel',
    defaultMessage: 'Cancel',
    description: 'Default cancel button label',
  },
  unsavedChanges: {
    id: 'form.unsavedChanges',
    defaultMessage: 'This form contains unsaved changes',
    description: 'Info message if form contains unsaved changes',
  },
  add: {
    id: 'form.add',
    defaultMessage: 'Add',
    description: 'Default value for add (entry) buttons',
  },
  remove: {
    id: 'form.remove',
    defaultMessage: 'Delete',
    description: 'Default value for remove (entry) buttons',
  },
});

export default messages;
