import { defineMessages } from 'react-intl';

const messages = defineMessages({
  heading: {
    id: 'registration.heading',
    defaultMessage: 'Sign Up',
    description: 'Heading for login form',
  },
  info: {
    id: 'registration.info',
    defaultMessage: `
      Please fill out the form in order to sign up. Afterwards we will send you a link you can use to complete your registration.
    `,
    description: 'Info text for registration form',
  },
  email: {
    id: 'registration.email',
    defaultMessage: 'E-Mail Address',
    description: 'E-Mail field placeholder text in register form',
  },
  username: {
    id: 'registration.username',
    defaultMessage: 'Username',
    description: 'Username field placeholder text in register form',
  },
  password: {
    id: 'registration.password',
    defaultMessage: 'Password',
    description: 'Password field placeholder text in register form',
  },
  passwordRepeat: {
    id: 'registration.passwordRepeat',
    defaultMessage: 'Repeat password',
    description: 'Password repeat field placeholder text in register form',
  },
  trialLicense: {
    id: 'registration.trialLicense',
    defaultMessage:
      'Start your free 30-day trial license for Waltzing Atoms Lab',
    description: 'Trial License activation field in register form',
  },
  newsletter: {
    id: 'registration.newsletter',
    defaultMessage: 'I want to subscribe to the Waltzing Newsletter',
    description: 'Newsletter field in register form',
  },
  submit: {
    id: 'registration.submit',
    defaultMessage: 'Sign Up',
    description: 'Submit button text in register form',
  },
  haveAccount: {
    id: 'registration.haveAccount',
    defaultMessage: 'I already have an account',
    description: 'Label for login link in register form',
  },
  alertSuccessTitle: {
    id: 'registration.alertSuccessTitle',
    defaultMessage: 'Success',
    description: 'Header for success alert dialog',
  },
  alertSuccessMessage: {
    id: 'registration.alertSuccessMessage',
    defaultMessage:
      'We sent you an e-mail with an activation link. Please click on the link to finish your registration!',
    description: 'Message for success alert dialog',
  },
  alertErrorTitle: {
    id: 'registration.alertErrorTitle',
    defaultMessage: 'Error',
    description: 'Header for error alert dialog',
  },
  alertErrorMessage: {
    id: 'registration.alertErrorMessage',
    defaultMessage: 'Creating user failed, User already exists!',
    description: 'Message for error alert dialog',
  },
});

export default messages;
