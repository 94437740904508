import React from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Loading from 'components/modules/common/Loading/Loading';

import s from './StaticPageInfo.scss';

class StaticPageInfo extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      staticPage: PropTypes.object,
    }).isRequired,
  };

  render() {
    const { loading, staticPage } = this.props.data;
    if (loading) return <Loading />;

    return (
      <div>
        <h4>{staticPage.url}</h4>
        <hr />
        <div className={s.segment}>
          <h3> {staticPage.title} </h3>
        </div>
        <div className={s.segment}>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: staticPage.content }} />
        </div>
      </div>
    );
  }
}

const staticPageQuery = gql`
  query staticPage($id: Int!) {
    staticPage(id: $id) {
      id
      url
      title
      content
    }
  }
`;

export default compose(
  graphql(staticPageQuery, {
    name: 'data',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
  }),
)(withStyles(s)(StaticPageInfo));
