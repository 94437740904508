import React from 'react';
import PropTypes from 'prop-types';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import { Field, FieldArray, reduxForm } from 'redux-form';
import FaSave from 'react-icons/lib/fa/floppy-o';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './ClassRoomForm.scss';

import render from '../modules/form/renderField';
import validations from '../modules/form/validations';
import validationMessages from '../modules/form/messages';
import componentMessages from '../messages';

const messages = defineMessages({
  heading: {
    id: 'classRoom.heading',
    defaultMessage: 'Digital Classroom',
    description: 'Heading for login form',
  },
  name: {
    id: 'classRoom.name',
    defaultMessage: 'Classroom name',
    description: 'Name field placeholder text in classRoom form',
  },
  member: {
    id: 'classRoom.member',
    defaultMessage: 'Member',
    description: 'Member field placeholder text in classRoom form',
  },
  alias: {
    id: 'classRoom.member.alias',
    defaultMessage: 'Alias',
    description: 'Alias field placeholder text in classRoom form',
  },
});

const validate = values => {
  const errors = {};
  if (!validations.required(values.name)) {
    errors.name = <FormattedMessage {...validationMessages.required} />;
  }

  return errors;
};

class ClassRoomForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    closeAfterSaving: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  render() {
    const { handleSubmit, intl, errors, closeAfterSaving } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <fieldset>
            <Field
              id="name"
              name="name"
              type="text"
              placeholder={intl.formatMessage(messages.name)}
              component={render.renderInput}
            />
            <FieldArray
              name="students"
              pushValue={{
                id: '',
              }}
              component={render.renderGenericFieldArray}
              renderField={name => (
                <div>
                  <Field
                    name={`${name}.username`}
                    label={`${intl.formatMessage(messages.member)}:`}
                    type="text"
                    component={render.renderInput}
                    disabled
                  />
                  <Field
                    name={`${name}.alias`}
                    label={`${intl.formatMessage(messages.alias)}:`}
                    type="text"
                    component={render.renderInput}
                  />
                </div>
              )}
              singleRows
              useFlexLayout
              withAdd={false}
              withRemove
            />
            {errors.length === 0 ? null : (
              <ul style={{ padding: '0', listStyleType: 'none' }}>
                {errors.map(err => (
                  <li key={err} className="bg-danger">
                    {err}
                  </li>
                ))}
              </ul>
            )}
            <button
              type="submit"
              className="btn btn-primary"
              onClick={async formValues => {
                await closeAfterSaving(false);
                handleSubmit(formValues);
              }}
            >
              <FaSave className="fa--prepended" />
              <FormattedMessage {...componentMessages.save} />
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={async formValues => {
                await closeAfterSaving(true);
                handleSubmit(formValues);
              }}
            >
              <FaSave className="fa--prepended" />
              <FormattedMessage {...componentMessages.saveAndClose} />
            </button>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'classRoom',
  validate,
})(withStyles(s)(injectIntl(ClassRoomForm)));
