import _ from 'lodash';
import roles from '../../core/roles';

const gate = {
  isAllowed({ user, allowedRoles }) {
    if (!user || !user.userRoles || user.userRoles.length === 0) return false;
    return _.intersection(user.userRoles, allowedRoles).length !== 0;
  },

  hasRole({ user, role }) {
    return user && user.userRoles && user.userRoles.length > 0
      ? user.userRoles.map(r => r === role).reduce((acc, prev) => acc || prev)
      : false;
  },

  isSubscriptionOwner({ user, subscription }) {
    // eslint-disable-next-line prettier/prettier
    return parseInt(user.id, 10) === parseInt(subscription.owner && subscription.owner.id, 10);
  },

  isSubscriptionOwnerOrLicenseAdmin({ user, subscription }) {
    return (
      this.isSubscriptionOwner({ user, subscription }) ||
      [].concat(user.userRoles).some(r => r === roles.MANAGE_LICENSES)
    );
  },
  isClassRoomOwner({ user, classRoom }) {
    return user && classRoom && user.id === classRoom.ownerId;
  },
};

export default gate;
