import React from 'react';
import { Row, Col } from 'react-bootstrap';

class LoggingOut extends React.Component {
  render() {
    return (
      <Row>
        <Col xs={12}>Please wait while we are logging you out.</Col>
      </Row>
    );
  }
}

export default LoggingOut;
