import React from 'react';
// import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './Footer.scss';
import messages from './messages';

const links = [
  {
    path: '/imprint',
    url: messages.imprintUrl,
    label: <FormattedMessage {...messages.imprint} />,
  },
  {
    path: '/data-protection-information',
    url: messages.dataProtectionInformationUrl,
    label: <FormattedMessage {...messages.dataProtectionInformation} />,
  },
  {
    path: '/terms-of-service',
    url: messages.termsOfServiceUrl,
    label: <FormattedMessage {...messages.termsOfService} />,
  },
];

class Footer extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  render() {
    return (
      <div className={s.container}>
        <ul className={s.links}>
          {links.map(link => (
            <li key={link.path}>
              <a href={this.props.intl.formatMessage(link.url)} target="_blank">
                {link.label}
              </a>
              {/* <Link to={link.path}>{link.label}</Link> */}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(Footer));
