import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaSearchPlus from 'react-icons/lib/fa/search-plus';
import FaEdit from 'react-icons/lib/fa/edit';
import FaShareAlt from 'react-icons/lib/fa/share-alt';
import SweetAlert from 'react-bootstrap-sweetalert';
import roles from 'core/roles';
import gate from 'components/Auth/gate';
import messages from './messages';
import componentMessages from '../messages';
import Table from '../Table/Table';
import TableStyle from '../Table/Table.scss'; // eslint-disable-line css-modules/no-unused-class
import Loading from '../modules/common/Loading/Loading';

class SubscriptionsTable extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    me: PropTypes.PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    subscriptions: PropTypes.arrayOf(
      PropTypes.PropTypes.shape({
        id: PropTypes.string.isRequired,
        owner: PropTypes.PropTypes.shape({
          id: PropTypes.string.isRequired,
        }).isRequired,
        license: PropTypes.PropTypes.shape({
          name: PropTypes.string.isRequired,
          allowedNumberOfUsers: PropTypes.number.isRequired,
        }).isRequired,
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
        credits: PropTypes.number,
      }),
    ).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        type: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  static defaultProps = {
    me: null,
  };

  constructor(props) {
    super(props);

    this.hideAlert = this.hideAlert.bind(this);
    this.createColumns = this.createColumns.bind(this);

    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    };
  }
  createColumns() {
    const {
      me,
      match: { params },
    } = this.props;
    const columns = [
      {
        Header: <FormattedMessage {...messages.name} />,
        accessor: 'License',
        width: 350,
        Cell: ({ original }) => (
          <div>
            {/* eslint-disable-next-line prettier/prettier */}
            {(original.license && original.license.name)}
          </div>
        ),
        filterMethod: (filter, row) => {
          const filterString = filter.value.toLowerCase();
          const name = row._original.license.name.toLowerCase(); // eslint-disable-line no-underscore-dangle
          return name.indexOf(filterString) !== -1;
        },
      },
      {
        Header: <FormattedMessage {...messages.owner} />,
        accessor: 'Owner',
        width: 350,
        Cell: ({ original }) => (
          <div>
            {/* eslint-disable-next-line prettier/prettier */}
            {(original.owner && original.owner.username)}
          </div>
        ),
        filterMethod: (filter, row) => {
          const filterString = filter.value.toLowerCase();
          const name = row._original.owner.username.toLowerCase(); // eslint-disable-line no-underscore-dangle
          return name.indexOf(filterString) !== -1;
        },
      },
      {
        Header: <FormattedMessage {...messages.credits} />,
        accessor: 'Credits',
        width: 100,
        Cell: ({ original }) => (
          <div>{original.credits === -1 ? '∞' : original.credits}</div>
        ),
      },
      {
        Header: <FormattedMessage {...messages.start} />,
        accessor: 'start',
        width: 300,
      },
      {
        Header: <FormattedMessage {...messages.end} />,
        accessor: 'end',
        width: 300,
      },
      {
        Header: 'Action',
        width: 510,
        // filterable: false,
        Cell: ({ original }) => {
          const isSubscriptionOwner = gate.isSubscriptionOwner({
            user: me,
            subscription: original,
          });
          const canManageLicense = gate.hasRole({
            user: me,
            role: roles.MANAGE_LICENSES,
          });
          const canAddUsers =
            isSubscriptionOwner &&
            original.license.allowedNumberOfUsers > 1 &&
            original.users.length < original.license.allowedNumberOfUsers;
          return (
            <div>
              <Link to={`${original.id}/show`}>
                <button
                  className={
                    isSubscriptionOwner
                      ? TableStyle.buttonLeft
                      : TableStyle.buttonSingle
                  }
                >
                  <FaSearchPlus className="fa--prepended" />
                  <FormattedMessage {...componentMessages.show} />
                </button>
              </Link>
              {canManageLicense && (
                <Link to={`${params.type}/${original.id}/edit`}>
                  <button
                    className={
                      canAddUsers
                        ? TableStyle.buttonMiddle
                        : TableStyle.buttonRight
                    }
                  >
                    <FaEdit className="fa--prepended" />
                    <FormattedMessage {...componentMessages.edit} />
                  </button>
                </Link>
              )}
              {canAddUsers && (
                <Link to={`${original.id}/share`}>
                  <button className={TableStyle.buttonRight}>
                    <FaShareAlt className="fa--prepended" />
                    <FormattedMessage {...messages.inviteUsers} />
                  </button>
                </Link>
              )}
            </div>
          );
        },
      },
    ];
    return columns;
  }
  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        showCancel: false,
        onConfirm: null,
        onCancel: null,
      },
    });
  }

  render() {
    const { loading } = this.props;
    if (loading) {
      return <Loading />;
    }
    const { alertOpts } = this.state;
    const columns = this.createColumns();
    const subscriptionsData = this.props.subscriptions.map(subscription => ({
      ...subscription,
      start: subscription.start
        ? moment(subscription.start).format('DD.MM.YYYY HH:mm:ss')
        : '',
      end: subscription.end
        ? moment(subscription.end).format('DD.MM.YYYY HH:mm:ss')
        : '',
    }));
    return (
      <div>
        <Table keyField="id" data={subscriptionsData} columns={columns} />
        {alertOpts.show && (
          <SweetAlert
            type={alertOpts.type}
            title={alertOpts.title}
            confirmBtnBsStyle={
              alertOpts.type === 'warning' ? 'danger' : 'primary'
            }
            cancelBtnBsStyle="default"
            showCancel={alertOpts.showCancel}
            onConfirm={alertOpts.onConfirm}
            onCancel={alertOpts.onCancel}
          >
            {alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const subscriptionsQuery = gql`
  query subscriptions($type: String) {
    subscriptions(type: $type) {
      id
      owner {
        id
        username
      }
      license {
        id
        name
        allowedNumberOfUsers
      }
      start
      end
      credits
      users {
        id
      }
    }
  }
`;

const mapStateToProps = state => ({
  me: state.user,
});
export default compose(
  connect(mapStateToProps),
  graphql(subscriptionsQuery, {
    name: 'subscriptionsQuery',
    options: ({ match: { params } }) => ({
      variables: {
        type: params.type,
      },
      fetchPolicy: 'network-only',
    }),
    props: ({ subscriptionsQuery: { loading, subscriptions } }) => ({
      loading,
      subscriptions: (!loading && subscriptions) || [],
    }),
  }),
)(withStyles(TableStyle)(SubscriptionsTable));
