import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  defineMessages,
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape,
} from 'react-intl';

import render from '../modules/form/renderField';
import validations from '../modules/form/validations';
import validationMessages from '../modules/form/messages';
import * as normalizers from '../modules/form/normalizers';
import privacyMessages from './messages';

const messages = defineMessages({
  submit: {
    id: 'privacyAgreementForm.submit',
    defaultMessage: 'Submit',
    description: 'Submit button text in privacyAgreementForm form',
  },
});

const validate = values => {
  const errors = {};
  if (!validations.required(values.name)) {
    errors.username = <FormattedMessage {...validationMessages.required} />;
  }

  return errors;
};

class PrivacyAgreementForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <fieldset>
          <h3>
            <FormattedMessage {...privacyMessages.gdpr} />
          </h3>
          <Field
            id="privacyPolicyAccepted"
            name="privacyPolicyAccepted"
            label={
              <FormattedHTMLMessage {...privacyMessages.privacyPolicyRead} />
            }
            type="checkbox"
            component={render.renderCheckbox}
            normalize={normalizers.booleanNormalizer}
          />
          <button
            type="submit"
            className="btn btn-primary"
            disabled={this.props.submitting}
          >
            <FormattedMessage {...messages.submit} />
          </button>
        </fieldset>
      </form>
    );
  }
}

export default reduxForm({
  form: 'privacyAgreementForm',
  validate,
})(injectIntl(PrivacyAgreementForm));
