import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import Loading from 'components/modules/common/Loading/Loading';
import AddSubscriptionForm from './AddSubscriptionForm';

class AddSubscription extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      purchasableLicenses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ),
      users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          username: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    createSubscription: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      formErrors: [],
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: () => {
          this.setState({ alertOpts: { show: false } });
        },
      },
    };
  }

  async handleSubmit(formValues) {
    this.setState({ formErrors: [] });
    try {
      const payload = {
        licenseId: formValues.license.value,
        ownerId: formValues.owner.value,
      };
      const response = await this.props.createSubscription({ payload });
      if (response && response.data && response.data.createSubscription) {
        this.setState({
          alertOpts: {
            ...this.state.alertOpts,
            show: true,
            title: 'Success',
            msg: 'Subscription added',
          },
        });
      }
    } catch (e) {
      this.setState({ formErrors: [{ message: e.message }] });
    }
  }

  render() {
    const {
      data: { loading, purchasableLicenses, users },
    } = this.props;
    const { formErrors, alertOpts } = this.state;

    if (loading) return <Loading />;

    return (
      <Row>
        <Col md={4} xs={12}>
          <AddSubscriptionForm
            onSubmit={this.handleSubmit}
            licenses={purchasableLicenses}
            users={users}
            errors={formErrors}
          />
          {alertOpts.show && (
            <SweetAlert
              type={alertOpts.type}
              title={alertOpts.title}
              onConfirm={alertOpts.onConfirm}
            >
              {alertOpts.msg}
            </SweetAlert>
          )}
        </Col>
      </Row>
    );
  }
}

const dataQuery = gql`
  query dataQuery {
    purchasableLicenses {
      id
      name
    }
    users {
      id
      username
      email
      activeSubscriptions {
        id
        owner {
          id
          username
        }
      }
    }
  }
`;

const createSubscriptionMutation = gql`
  mutation createSubscriptionMutation($payload: CreateSubscriptionInput!) {
    createSubscription(payload: $payload) {
      id
      license {
        id
      }
      owner {
        id
      }
    }
  }
`;

export default compose(
  graphql(dataQuery, {
    name: 'data',
  }),
  graphql(createSubscriptionMutation, {
    props: ({ mutate }) => ({
      createSubscription: ({ payload }) =>
        mutate({
          variables: { payload },
        }),
    }),
  }),
)(AddSubscription);
