exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "h2{margin-bottom:25px;font-family:Gotham Rounded A,Gotham Rounded B,HelveticaNeue-Light,Arial,sans-serif}._3_4rs{background:#fff}._3_4rs,._3HRtA{padding:10px;border:1px solid #dcdcdc;color:#222}._3HRtA{background:#dcdcdc}._2W7jZ{font-weight:700;display:inline-block;min-width:250px}", ""]);

// exports
exports.locals = {
	"rowEven": "_3_4rs",
	"rowUneven": "_3HRtA",
	"label": "_2W7jZ"
};