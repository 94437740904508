import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
  injectStripe,
} from 'react-stripe-elements';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaError from 'react-icons/lib/fa/exclamation-triangle';

import s from './PaymentForm.scss';
import messages from './messages';
import errorMessages from './errorMessages';

const parseValidity = validity => {
  const split = validity.split(' ');
  return {
    duration: split[0].substring(1),
    unit: split[1],
  };
};

class PaymentForm extends React.Component {
  static propTypes = {
    stripe: PropTypes.shape({
      createToken: PropTypes.func.isRequired,
    }),
    handlePayment: PropTypes.func.isRequired,
    licenses: PropTypes.arrayOf(PropTypes.object).isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    stripe: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      selectedProduct: null,
      selectedLicense: null,
      paymentRequestPending: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLicenseSelect = this.handleLicenseSelect.bind(this);
  }

  async handleSubmit(evt) {
    this.setState({ error: null, paymentRequestPending: true });

    evt.preventDefault();
    const payload = await this.props.stripe.createToken();

    if (!this.state.selectedLicense) {
      this.setState({ error: { code: 'no_license_selected' } });
    } else if (!payload.error) {
      await this.props.handlePayment({
        token: payload.token.id,
        licenseId: this.state.selectedLicense.id,
      });
      this.setState({ paymentRequestPending: false });
    } else {
      this.setState({ error: payload.error, paymentRequestPending: false });
    }
  }

  handleLicenseSelect(license) {
    this.setState({ selectedLicense: license });
  }

  handleProductSelect(product) {
    this.setState({ selectedProduct: product });
  }

  render() {
    const { licenses, products } = this.props;

    // get only those products which have at least one license
    const productIDsWithLicenses = [];
    for (let i = 0; i < licenses.length; i += 1) {
      const license = licenses[i];
      for (let j = 0; j < license.products.length; j += 1) {
        if (productIDsWithLicenses.indexOf(license.products[j].id) === -1) {
          productIDsWithLicenses.push(license.products[j].id);
        }
      }
    }
    const productsWithLicenses = products.filter(
      product => productIDsWithLicenses.indexOf(product.id) !== -1,
    );

    let filteredLicenses;
    if (this.state.selectedProduct) {
      // get only those licenses which are in the selcted product
      filteredLicenses = licenses.filter(
        license =>
          license.products &&
          license.products.filter(
            product => product.id === this.state.selectedProduct.id,
          ).length > 0,
      );
    } else {
      filteredLicenses = licenses;
    }

    const productDropDownTitle = this.state.selectedProduct ? (
      this.state.selectedProduct.name
    ) : (
      <FormattedMessage {...messages.allProdcuts} />
    );

    const licenseDropDownTitle = this.state.selectedLicense ? (
      this.state.selectedLicense.name
    ) : (
      <FormattedMessage {...messages.selectLicense} />
    );

    /* eslint-disable jsx-a11y/label-has-for */
    return (
      <form className={s.paymentForm} onSubmit={this.handleSubmit}>
        <div className={s.dropDown}>
          <label>
            <FormattedMessage {...messages.license} />
          </label>
          <span>
            <FormattedMessage {...messages.product} />
          </span>
          <div className={s.dropDownMenu}>
            <DropdownButton
              bsStyle="default"
              title={productDropDownTitle}
              id="productsDropdown"
              onSelect={product => {
                this.handleProductSelect(product);
              }}
            >
              <MenuItem eventKey={null} key={-1}>
                <FormattedMessage {...messages.allProdcuts} />
              </MenuItem>
              {productsWithLicenses.map(product => (
                <MenuItem eventKey={product} key={product.id}>
                  {product.name}
                </MenuItem>
              ))}
            </DropdownButton>
          </div>
          <div className={s.spacer} />
          <span>
            <FormattedMessage {...messages.license} />
          </span>
          <div className={s.dropDownMenu}>
            <DropdownButton
              bsStyle="default"
              title={licenseDropDownTitle}
              id="licensesDropdown"
              onSelect={license => {
                this.handleLicenseSelect(license);
              }}
            >
              {filteredLicenses.map(license => (
                <MenuItem eventKey={license} key={license.id}>
                  {license.name}
                </MenuItem>
              ))}
            </DropdownButton>
          </div>
          <div className={s.spacer} />
          <span>
            <FormattedMessage {...messages.price} />
          </span>
          {this.state.selectedLicense && (
            <span className={s.dropDownMenu}>
              {this.state.selectedLicense.price} &euro; /{' '}
              <FormattedMessage
                {...messages[
                  parseValidity(this.state.selectedLicense.validityPeriod).unit
                ]}
              />
            </span>
          )}
        </div>
        <label>
          <FormattedMessage {...messages.cardNumber} />
          <CardNumberElement />
        </label>
        <label>
          <FormattedMessage {...messages.expirationDate} />
          <CardExpiryElement />
        </label>
        <label>
          <FormattedMessage {...messages.cvc} />
          <CardCVCElement />
        </label>
        <label>
          <FormattedMessage {...messages.zip} />
          <PostalCodeElement />
        </label>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={this.state.paymentRequestPending}
        >
          <FormattedMessage {...messages.buyNow} />
        </button>
        {this.state.error &&
          errorMessages[this.state.error.code] && (
            <span className={`${s.errorMessage} bg-danger`}>
              <FaError className="fa--prepended" />
              <FormattedMessage {...errorMessages[this.state.error.code]} />
            </span>
          )}
        {this.state.error &&
          !errorMessages[this.state.error.code] && (
            <span className={`${s.errorMessage} bg-danger`}>
              <FaError className="fa--prepended" />
              <FormattedMessage {...errorMessages.genericError} />
            </span>
          )}
      </form>
    );
    /* eslint-enable jsx-a11y/label-has-for */
  }
}

export default injectStripe(withStyles(s)(PaymentForm));
