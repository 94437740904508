import About from './about';
import Admin from './admin';
import Login from './login';
import LoggingOut from './loggingOut';
import Registration from './registration';
import ResetPassword from './reset-password';
import DeleteAccount from './delete-account';
import Buy from './buy';
import Dashboard from './dashboard';
import PrivacyAgreement from './privacy-agreement';
import StaticPage from '../components/StaticPages/StaticPage';

const routes = [
  {
    path: '/',
    component: Login,
    exact: true,
  },
  {
    path: '/about',
    component: About,
    exact: true,
  },
  {
    path: '/admin',
    component: Admin,
  },
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/registration',
    component: Registration,
    exact: true,
  },
  {
    path: '/delete-account',
    component: DeleteAccount,
    exact: true,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    path: '/buy',
    component: Buy,
  },
  {
    path: '/dashboard',
    component: Dashboard,
  },
  {
    path: '/privacy-agreement',
    component: PrivacyAgreement,
  },
  {
    path: '/logout/:path',
    component: LoggingOut,
  },
  {
    path: '/:staticUrl',
    component: StaticPage,
  },
];

export default routes;
