import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Link } from 'react-router-dom';
import FaPaperPlane from 'react-icons/lib/fa/paper-plane';
import FaEye from 'react-icons/lib/fa/eye';
import FaEdit from 'react-icons/lib/fa/edit';
import FaDelete from 'react-icons/lib/fa/times-circle';
import FaPlusCircle from 'react-icons/lib/fa/plus-circle';
import SweetAlert from 'react-bootstrap-sweetalert';

import s from './ClassRoomTable.scss';
import messages from './messages';
import Table from '../Table/Table';
import TableStyle from '../Table/Table.scss'; // eslint-disable-line css-modules/no-unused-class
import Loading from '../modules/common/Loading/Loading';

class ClassRoomTable extends React.Component {
  static propTypes = {
    deleteClassRoom: PropTypes.func.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      classRooms: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    };
    const { intl } = this.props;
    this.columns = [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Action',
        Cell: row => (
          <div style={{ textAlign: 'center' }}>
            <Link to={`classrooms/${row.original.id}/invite`}>
              <button
                title={intl.formatMessage(messages.inviteStudent)}
                className={TableStyle.buttonLeft}
              >
                <FaPaperPlane className="fa--prepended" />
                <div className={s.tooltip}>
                  <FormattedMessage {...messages.inviteStudent} />
                </div>
              </button>
            </Link>
            <Link to={`classrooms/${row.original.id}/invitations`}>
              <button
                title={intl.formatMessage(messages.showInvitations)}
                className={TableStyle.buttonMiddle}
              >
                <FaEye className="fa--prepended" />
                <div className={s.tooltip}>
                  <FormattedMessage {...messages.showInvitations} />
                </div>
              </button>
            </Link>
            <Link to={`classrooms/${row.original.id}/edit`}>
              <button
                title={intl.formatMessage(messages.edit)}
                className={TableStyle.buttonMiddle}
              >
                <FaEdit className="fa--prepended" />
                <div className={s.tooltip}>
                  <FormattedMessage {...messages.edit} />
                </div>
              </button>
            </Link>
            <button
              title={intl.formatMessage(messages.delete)}
              className={TableStyle.buttonRight}
              onClick={() => this.showDeleteClassRoomPropmt(row.original.id)}
            >
              <FaDelete className="fa--prepended" />
              <div className={s.tooltip}>
                <FormattedMessage {...messages.delete} />
              </div>
            </button>
          </div>
        ),
      },
    ];

    this.showDeleteClassRoomPropmt = this.showDeleteClassRoomPropmt.bind(this);
    this.deleteClassRoomById = this.deleteClassRoomById.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  showDeleteClassRoomPropmt(id) {
    this.setState({
      alertOpts: {
        show: true,
        type: 'warning',
        title: <FormattedMessage {...messages.alertWarningTitle} />,
        msg: <FormattedMessage {...messages.alertWarningMessage} />,
        showCancel: true,
        onConfirm: () => this.deleteClassRoomById(id),
        onCancel: this.hideAlert,
      },
    });
  }

  async deleteClassRoomById(id) {
    this.hideAlert();

    const result = await this.props.deleteClassRoom(id);

    if (!result || result.status === 401) {
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.alertErrorTitle} />,
          msg: <FormattedMessage {...messages.alertErrorMessage} />,
          showCancel: false,
          onConfirm: this.hideAlert,
          onCancel: null,
        },
      });
    } else {
      this.setState({
        alertOpts: {
          show: true,
          type: 'success',
          title: <FormattedMessage {...messages.alertSuccessTitle} />,
          msg: <FormattedMessage {...messages.alertSuccessMessage} />,
          showCancel: false,
          onConfirm: this.hideAlert,
          onCancel: null,
        },
      });
    }
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        showCancel: false,
        onConfirm: null,
        onCancel: null,
      },
    });
  }

  render() {
    if (this.props.data.loading) {
      return <Loading />;
    }
    return (
      <div>
        <Table
          keyField="id"
          data={this.props.data.classRooms}
          columns={this.columns}
        />
        <Link to="classrooms/new">
          <button className={TableStyle.buttonSingleLarge}>
            <FaPlusCircle className="fa--prepended" />
            <FormattedMessage {...messages.new} />
          </button>
        </Link>
        {this.state.alertOpts.show && (
          <SweetAlert
            type={this.state.alertOpts.type}
            title={this.state.alertOpts.title}
            confirmBtnBsStyle={
              this.state.alertOpts.type === 'warning' ? 'danger' : 'primary'
            }
            cancelBtnBsStyle="default"
            showCancel={this.state.alertOpts.showCancel}
            onConfirm={this.state.alertOpts.onConfirm}
            onCancel={this.state.alertOpts.onCancel}
          >
            {this.state.alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const classRoomsQuery = gql`
  query classRooms {
    classRooms {
      id
      name
    }
  }
`;

const deleteClassRoomMutation = gql`
  mutation deleteClassRoom($id: ID!) {
    deleteClassRoom(id: $id) {
      id
    }
  }
`;

export default compose(
  graphql(classRoomsQuery, {
    name: 'data',
  }),
  graphql(deleteClassRoomMutation, {
    props: ({ mutate }) => ({
      deleteClassRoom: id =>
        mutate({
          variables: { id },
          refetchQueries: [
            {
              query: classRoomsQuery,
            },
          ],
        }),
    }),
  }),
)(injectIntl(withStyles(TableStyle, s)(ClassRoomTable)));
