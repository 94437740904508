import { defineMessages } from 'react-intl';

const messages = defineMessages({
  gdpr: {
    id: 'privacyAgreement.dsgvo',
    defaultMessage: 'GDPR',
    description: 'Heading in privacy agreement',
  },
  pleaseRead: {
    id: 'privacyAgreement.pleaseRead',
    defaultMessage: `
      Please read the privacy policy and information about data use
      in detail and confirm that they have been
      understood and accepted.
    `,
    description:
      'Text in privacy agreement that requests the user to read the privacy policy',
  },
  privacyPolicyRead: {
    id: 'privacyAgreement.privacyPolicyRead',
    defaultMessage: `
      I have read the
      <a href="http://www.waltzingatoms.com/privacy" title="Privacy Policy" target="_blank">privacy policy</a>
      and fully accept it.
    `,
    description: 'Label for privacy policy field in privacy agreement',
  },
  dataUseRead: {
    id: 'privacyAgreement.dateUseRead',
    defaultMessage:
      'I have read the information about data use and fully accept it.',
    description: 'Label for data use field in privacy agreement',
  },
  accepted: {
    id: 'privacyAgreement.accepted',
    defaultMessage: 'Accepted',
    description: 'Label for accepted column in privacy table',
  },
  name: {
    id: 'privacyAgreement.name',
    defaultMessage: 'Name',
    description: 'Label for name column in privacy table',
  },
  decline: {
    id: 'privacyAgreement.decline',
    defaultMessage: 'decline',
    description: 'Label for decline button in privacy table',
  },
  yes: {
    id: 'privacyAgreement.yes',
    defaultMessage: 'Yes',
    description: 'Yes label for privacy table',
  },
  no: {
    id: 'privacyAgreement.no',
    defaultMessage: 'No',
    description: 'No label for privacy table',
  },
  alertSuccessTitle: {
    id: 'privacyAgreement.alertSuccessTitle',
    defaultMessage: 'Success',
    description: 'Header for success alert dialog',
  },
  alertSuccessMessage: {
    id: 'privacyAgreement.alertSuccessMessage',
    defaultMessage: 'Privacy agreement successfully declined!',
    description: 'Message for success alert dialog',
  },
  alertErrorTitle: {
    id: 'privacyAgreement.alertErrorTitle',
    defaultMessage: 'Error',
    description: 'Header for error alert dialog',
  },
  alertErrorMessage: {
    id: 'privacyAgreement.alertErrorMessage',
    defaultMessage: 'Failed to decline privacy agreement.',
    description: 'Message for error alert dialog',
  },
  privacyAggreementHeading: {
    id: 'privacyAgreement.privacyAggreementHeading',
    defaultMessage: 'Privacy Policy Settings',
    description: 'Heading message of the privacy agreement settings table',
  },
});

export default messages;
