exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._24uAH{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;-ms-flex-direction:column;flex-direction:column;position:absolute;top:0;left:0;width:100%;min-height:100%;background:rgba(0,0,0,.5);z-index:9999;overflow:hidden}._3L1pG,._24uAH span{z-index:10000}._3L1pG{display:block;width:100%}._3L1pG [class^=Spinner-]{border-top:1.1em solid hsla(0,0%,100%,.5);border-right:1.1em solid hsla(0,0%,100%,.5);border-bottom:1.1em solid hsla(0,0%,100%,.5);border-left:1.1em solid #46145a}._32fdV{display:block;width:100%;color:#fff;text-align:center}", ""]);

// exports
exports.locals = {
	"loading": "_24uAH",
	"spinnerWrapper": "_3L1pG",
	"loadingText": "_32fdV"
};