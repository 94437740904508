/* eslint-disable import/prefer-default-export, prettier/prettier */

/**
 * Wrappes a promise, resolves the success response to an array with the return data as second item.
 * And the Error received from the catch as the first.
 *
 * @param {promise} promise A promise to resolve or catch
 * @return {Array} A array with catched error as first element and resloved response as second.
 */
export const to = promise =>
  promise.then(data => [null, data]).catch(err => [err]);

export const getRandomDigits = (length = 20, min = 0, max = 9) =>
  Array.from({ length }, () => Math.floor(Math.random() * ((max - min) + 1)) + min).join('');

/**
 * Adds a form with hidden inputs to the site and calls submit on it
 * this is done to perform a post request with the browser and follow redirects
 * 
 * TODO: add nested object and array support
 * @param {string} path the url to perform the request against
 * @param {object} params A object containing the formData to send
 */
export const post = (path, params) => {
  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', path);

  Object.keys(params).forEach((key =>  {
    const hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', key);
    hiddenField.setAttribute('value', params[key]);

    form.appendChild(hiddenField);
  }));

  document.body.appendChild(form);
  form.submit();
}