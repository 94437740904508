import { defineMessages } from 'react-intl';

const messages = defineMessages({
  id: {
    id: 'license.id',
    defaultMessage: 'Id',
    description: 'Id label for license form',
  },
  name: {
    id: 'license.name',
    defaultMessage: 'Name',
    description: 'Name label for license form',
  },
  shortDescription: {
    id: 'license.shortDescription',
    defaultMessage: 'Short description',
    description: 'Short Description label for license form',
  },
  description: {
    id: 'license.description',
    defaultMessage: 'Description',
    description: 'Description label for license form',
  },
  price: {
    id: 'license.price',
    defaultMessage: 'Price',
    description: 'Price label for license form',
  },
  allowedNumberOfUsers: {
    id: 'license.allowedNumberOfUsers',
    defaultMessage: 'Allowed number of users',
    description: 'License Count label for license form',
  },
  credits: {
    id: 'license.credits',
    defaultMessage: 'Credits',
    description: 'Credits label for license form',
  },
  validityPeriod: {
    id: 'license.validityPeriod',
    defaultMessage: 'Validity period',
    description: 'Validity Period label for license form',
  },
  product: {
    id: 'license.product',
    defaultMessage: 'Product {number}',
    description: 'Product label for license form',
  },
  rolesRequired: {
    id: 'license.rolesRequired',
    defaultMessage: 'Required roles',
    description: 'Roles Required label for license form',
  },
  visible: {
    id: 'license.visible',
    defaultMessage: 'Visible',
    description: 'Visible label for license form',
  },
  enabled: {
    id: 'license.enabled',
    defaultMessage: 'Enabled',
    description: 'Enabled label for license form',
  },
  chooseProduct: {
    id: 'license.chooseProduct',
    defaultMessage: 'Choose the products this license should be valid for.',
    description: 'Description label for products in license form',
  },
  add: {
    id: 'license.add',
    defaultMessage: 'Add',
    description: 'Add label for license form submit button',
  },
  save: {
    id: 'license.save',
    defaultMessage: 'Save',
    description: 'Save label for license form submit button',
  },
  saveAndClose: {
    id: 'license.saveAndClose',
    defaultMessage: 'Save and close',
    description: 'Save and close label for license form submit button',
  },
  delete: {
    id: 'license.delete',
    defaultMessage: 'Delete',
    description: 'Delete label for license form button',
  },
  yes: {
    id: 'license.yes',
    defaultMessage: 'Yes',
    description: 'Yes label for license form',
  },
  no: {
    id: 'license.no',
    defaultMessage: 'No',
    description: 'No label for license form',
  },
  show: {
    id: 'license.show',
    defaultMessage: 'Show',
    description: 'Show label for license form button',
  },
  edit: {
    id: 'license.edit',
    defaultMessage: 'Edit',
    description: 'Edit label for license form button',
  },
  addTitle: {
    id: 'license.addTitle',
    defaultMessage: 'Add License',
    description: 'Header for add license form',
  },
  showTitle: {
    id: 'license.showTitle',
    defaultMessage: 'License Details',
    description: 'Header for show license form',
  },
  editTitle: {
    id: 'license.editTitle',
    defaultMessage: 'Edit License',
    description: 'Header for edit license form',
  },
  alertSuccessTitle: {
    id: 'license.alertSuccessTitle',
    defaultMessage: 'Success',
    description: 'Header for success alert dialog',
  },
  alertSuccessMessage: {
    id: 'license.alertSuccessMessage',
    defaultMessage: 'License successfully updated!',
    description: 'Message for success alert dialog',
  },
  alertErrorTitle: {
    id: 'license.alertErrorTitle',
    defaultMessage: 'Error',
    description: 'Header for error alert dialog',
  },
  alertErrorMessage: {
    id: 'license.alertErrorMessage',
    defaultMessage: 'There has been an error while updating the license!',
    description: 'Message for error alert dialog',
  },
  alertDeleteSuccessMessage: {
    id: 'license.alertDeleteSuccessMessage',
    defaultMessage: 'License successfully deleted!',
    description: 'Message for success delete alert dialog',
  },
  alertDeleteErrorMessage: {
    id: 'license.alertDeleteErrorMessage',
    defaultMessage: 'There has been an error while deleting the license!',
    description: 'Message for error delete alert dialog',
  },
  alertDeleteWarningMessage: {
    id: 'license.alertDeleteWarningMessage',
    defaultMessage: 'Do you really want to delete this license?',
    description: 'Message for warning delete alert dialog',
  },
  alertWarningTitle: {
    id: 'license.alertWarningTitle',
    defaultMessage: 'Warning',
    description: 'Header for warning alert dialog',
  },
});

export default messages;
