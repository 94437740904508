import { defineMessages } from 'react-intl';

const messages = defineMessages({
  show: {
    id: 'staticPages.show',
    defaultMessage: 'Show',
    description: 'Show label for static pages form button',
  },
  edit: {
    id: 'staticPages.edit',
    defaultMessage: 'Edit',
    description: 'Edit label for static pages form button',
  },
  delete: {
    id: 'staticPages.delete',
    defaultMessage: 'Delete',
    description: 'Delete label for static pages form button',
  },
  new: {
    id: 'staticPages.new',
    defaultMessage: 'New',
    description: 'New label for static pages form button',
  },
  warningTitle: {
    id: 'staticPages.alert.warningTitle',
    defaultMessage: 'Warning',
    description: 'Title for static page warning prompt',
  },
  deleteWarningMessage: {
    id: 'staticPages.alert.deleteWarningMessage',
    defaultMessage: 'Do you really want to delete this static page entry?',
    description: 'Message for delete static page warning prompt',
  },
  successTitle: {
    id: 'staticPages.alert.successTitle',
    defaultMessage: 'Success',
    description: 'Title for static page success prompt',
  },
  deleteSuccessMessage: {
    id: 'staticPages.alert.deleteSuccessMessage',
    defaultMessage: 'Static page entry successfully deleted!',
    description: 'Message for delete static page success prompt',
  },
  errorTitle: {
    id: 'staticPages.alert.errorTitle',
    defaultMessage: 'Error',
    description: 'Title for static page error prompt',
  },
  deleteErrorMessage: {
    id: 'staticPages.alert.deleteErrorMessage',
    defaultMessage:
      'There has been an error while deleting the static page entry!',
    description: 'Message for delete static page error prompt',
  },
  add: {
    id: 'staticPages.add',
    defaultMessage: 'Add',
    description: 'Add label for new static page form',
  },
  addAndClose: {
    id: 'staticPages.addAndClose',
    defaultMessage: 'Add and go back to overview',
    description: 'Add and close label for new static page form',
  },
  save: {
    id: 'staticPages.save',
    defaultMessage: 'Save',
    description: 'Save label for edit static page form',
  },
  saveAndClose: {
    id: 'staticPages.saveAndClose',
    defaultMessage: 'Save and go back to overview',
    description: 'Save and close label for edit static page form',
  },
  url: {
    id: 'staticPages.url',
    defaultMessage: 'URL',
    description: 'URL label for static page form',
  },
  title: {
    id: 'staticPages.title',
    defaultMessage: 'Title',
    description: 'Title label for static page form',
  },
  content: {
    id: 'staticPages.content',
    defaultMessage: 'Content',
    description: 'Content label for static page form',
  },
  createSuccessMessage: {
    id: 'staticPages.alert.createSuccessMessage',
    defaultMessage: 'Static page entry successfully created.',
    description: 'Message for create static page success prompt',
  },
  createErrorMessage: {
    id: 'staticPages.alert.createErrorMessage',
    defaultMessage:
      'There has been an error while creating the static page entry!',
    description: 'Message for create static page error prompt',
  },
  updateSuccessMessage: {
    id: 'staticPages.alert.updateSuccessMessage',
    defaultMessage: 'Static page entry successfully updated.',
    description: 'Message for update static page success prompt',
  },
  updateErrorMessage: {
    id: 'staticPages.alert.updateErrorMessage',
    defaultMessage:
      'There has been an error while updating the static page entry!',
    description: 'Message for update static page error prompt',
  },
});

export default messages;
