exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "h2{margin-bottom:25px;font-family:Gotham Rounded A,Gotham Rounded B,HelveticaNeue-Light,Arial,sans-serif}._8JNMR{background:#fff}._8JNMR,._43IKY{padding:10px;border:1px solid #dcdcdc;color:#222}._43IKY{background:#dcdcdc}._3LjXW{font-weight:700;display:inline-block;min-width:250px}", ""]);

// exports
exports.locals = {
	"rowEven": "_8JNMR",
	"rowUneven": "_43IKY",
	"label": "_3LjXW"
};