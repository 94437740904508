import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from 'react-datepicker/dist/react-datepicker.css';

class DatePicker extends React.Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.shape({
        id: PropTypes.string,
      }),
      warning: PropTypes.bool,
    }).isRequired,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    placeholder: '',
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    this.props.input.onChange(moment(date).format('DD.MM.YYYY'));
  }

  render() {
    const {
      input,
      placeholder,
      meta: { touched, error, warning },
    } = this.props;

    return (
      <div>
        <ReactDatePicker
          {...input}
          placeholder={placeholder}
          dateFormat="DD.MM.YYYY"
          selected={input.value ? moment(input.value, 'DD.MM.YYYY') : null}
          onChange={this.handleChange}
        />
        {touched &&
          ((error && <p className="bg-danger">{error}</p>) ||
            (warning && <p className="bg-warning">{warning}</p>))}
      </div>
    );
  }
}

export default withStyles(s)(DatePicker);
