import React from 'react';

import PrivacyAgreement from 'components/PrivacyAgreement';

class LoginWrapper extends React.Component {
  render() {
    return (
      <div>
        <PrivacyAgreement />
      </div>
    );
  }
}

export default LoginWrapper;
