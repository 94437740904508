/* eslint-disable import/prefer-default-export */

import {
  SET_LOGGED_IN,
  SET_PREVIOUS_URL,
  CLEAR_PREVIOUS_URL,
} from '../constants';

export function setLoggedIn(user) {
  return {
    type: SET_LOGGED_IN,
    payload: user,
  };
}

export function setPreviousUrl(url) {
  return {
    type: SET_PREVIOUS_URL,
    payload: { url },
  };
}

export function clearPreviousUrl() {
  return {
    type: CLEAR_PREVIOUS_URL,
  };
}
