/* eslint-disable no-shadow */

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import { Nav, NavDropdown, MenuItem } from 'react-bootstrap';

import { setLocale } from '../../actions/intl';
import s from './LanguageSwitcher.scss';

const localeDict = {
  /* @intl-code-template '${lang}-${COUNTRY}': '${Name}', */
  'de-DE': 'de',
  'en-US': 'en',
  'fa-IR': 'فا',
  /* @intl-code-template-end */
};

class LanguageSwitcher extends React.Component {
  static defaultProps = {
    className: null,
    pullRight: false,
  };
  static propTypes = {
    currentLocale: PropTypes.string.isRequired,
    availableLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
    setLocale: PropTypes.func.isRequired,
    className: PropTypes.string,
    pullRight: PropTypes.bool,
  };

  render() {
    const { currentLocale, availableLocales, setLocale } = this.props;
    const isSelected = locale => locale === currentLocale;
    const localeName = locale => localeDict[locale] || locale;

    return (
      <Nav
        className={this.props.className || s.languageSwitcher}
        pullRight={this.props.pullRight}
        onSelect={e => {
          setLocale({ locale: e.slice('lang.'.length) });
        }}
      >
        <NavDropdown
          eventKey="lang"
          title={localeName(currentLocale)}
          id="locale-nav-dropdown"
        >
          {availableLocales.filter(l => !isSelected(l)).map(locale => (
            <MenuItem key={locale} eventKey={`lang.${locale}`}>
              {localeName(locale)}
            </MenuItem>
          ))}
        </NavDropdown>
      </Nav>
    );
  }
}

const mapState = state => ({
  availableLocales: state.runtime.availableLocales,
  currentLocale: state.intl.locale,
});

const mapDispatch = {
  setLocale,
};

export default connect(mapState, mapDispatch)(withStyles(s)(LanguageSwitcher));
