exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".eCrXr{margin:15px 0;padding-left:0;list-style-type:none}.eCrXr li{padding:10px 0;border-top:1px solid #dcdcdc}.eCrXr li span{position:relative;top:5px}.eCrXr li button{float:right}.eCrXr li button,.eCrXr li button:active,.eCrXr li button:focus,.eCrXr li button:hover{color:#ff320f;border:0;background:none}.eCrXr li:last-child{border-bottom:1px solid #dcdcdc}", ""]);

// exports
exports.locals = {
	"shares": "eCrXr"
};