import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { withRouter } from 'react-router';

import s from './StaticPage.scss';
import NotFound from '../../routes/notFound';
import Loading from '../modules/common/Loading/Loading';

class StaticPage extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      staticPages: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  };

  render() {
    const { loading, staticPages } = this.props.data;
    if (loading) return <Loading />;

    const { pathname } = this.props.location;

    let staticPage = null;
    for (let i = 0; i < staticPages.length; i += 1) {
      if (pathname === staticPages[i].url) {
        staticPage = staticPages[i];
        break;
      }
    }

    if (staticPage === null) return <NotFound />;

    return (
      <div className={s.staticPageBody}>
        <h3> {staticPage.title} </h3>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: staticPage.content }} />
      </div>
    );
  }
}

const staticPagesQuery = gql`
  query staticPages {
    staticPages {
      id
      url
      title
      content
    }
  }
`;

export default compose(
  graphql(staticPagesQuery, {
    name: 'data',
  }),
)(withRouter(withStyles(s)(StaticPage)));
