exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2b5Q9{width:100%;z-index:1;height:100%;background-color:#fff;margin-top:65px;padding-top:10px}@media (max-width:767px){._2b5Q9{display:none}}", ""]);

// exports
exports.locals = {
	"sideNav": "_2b5Q9"
};