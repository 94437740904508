import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import FaDecline from 'react-icons/lib/fa/times-circle';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

import Table from '../Table/Table';
import Loading from '../modules/common/Loading/Loading';
import messages from './messages';
import componentMessages from '../messages';
import TableStyle from '../Table/Table.scss'; // eslint-disable-line css-modules/no-unused-class

class PrivacyTable extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    declinePrivacyAgreement: PropTypes.func.isRequired,
    user: PropTypes.shape({
      products: PropTypes.arrayOf(PropTypes.object),
    }),
  };

  static defaultProps = {
    user: {
      products: [],
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
      closeAfterSaving: false,
    };

    this.columns = [
      {
        Header: <FormattedMessage {...messages.name} />,
        accessor: 'name',
        minWidth: 300,
      },
      {
        Header: <FormattedMessage {...messages.accepted} />,
        accessor: 'accepted',
        minWidth: 100,
        Cell: row => {
          const {
            original: { accepted },
          } = row;
          // eslint-disable-next-line prettier/prettier
          const labelStyle = accepted ? TableStyle.labelYes : TableStyle.labelNo;
          const label = (
            <FormattedMessage {...(accepted ? messages.yes : messages.no)} />
          );
          return <div className={labelStyle}> {label} </div>;
        },
      },
      {
        Header: <FormattedMessage {...componentMessages.actionHeader} />,
        width: 150,
        Cell: row => (
          <div>
            <button
              className={TableStyle.buttonRight}
              onClick={() => this.handleDecline(row)}
            >
              <FaDecline className="fa--prepended" />
              <FormattedMessage {...messages.decline} />
            </button>
          </div>
        ),
      },
    ];
    this.hideAlert = this.hideAlert.bind(this);
    this.handleDecline = this.handleDecline.bind(this);
  }

  async handleDecline(row) {
    const { original } = row;

    const result = await this.props.declinePrivacyAgreement({
      productId: original.id,
    });
    if (result.data.declinePrivacyAgreement) {
      this.setState({
        alertOpts: {
          show: true,
          type: 'success',
          title: <FormattedMessage {...messages.alertSuccessTitle} />,
          msg: <FormattedMessage {...messages.alertSuccessMessage} />,
          onConfirm: this.state.closeAfterSaving
            ? this.hideAlertAndClose
            : this.hideAlert,
        },
      });
    } else {
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.alertErrorTitle} />,
          msg: <FormattedMessage {...messages.alertErrorMessage} />,
          onConfirm: this.hideAlert,
        },
      });
    }
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });
  }

  render() {
    const { loading, user } = this.props;
    if (loading || !user) {
      return <Loading />;
    }
    const { alertOpts } = this.state;

    return (
      <div>
        <h2>
          <FormattedMessage {...messages.privacyAggreementHeading} />
        </h2>
        <Table
          isCheckable
          keyField="id"
          data={user.products.map(p => ({
            ...p,
            accepted: p.ProductUser.privacyAgreementAccepted,
          }))}
          columns={this.columns}
        />
        {alertOpts.show && (
          <SweetAlert
            type={alertOpts.type}
            title={alertOpts.title}
            onConfirm={alertOpts.onConfirm}
          >
            {alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const userQuery = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      username
      email
      userRoles
      products {
        id
        name
        ProductUser {
          privacyAgreementAccepted
        }
      }
    }
  }
`;

const mapStateToProps = state => ({
  me: state.user,
});
const declinePrivacyAgreementMutation = gql`
  mutation declinePrivacyAgreement($data: DeclinePrivacyAgreementInput!) {
    declinePrivacyAgreement(data: $data)
  }
`;

export default compose(
  connect(mapStateToProps),
  graphql(userQuery, {
    name: 'userQuery',
    options: ({ me }) => ({
      variables: {
        id: me.id,
      },
    }),
    props: ({ userQuery: { loading, user } }) => ({
      loading,
      user,
    }),
  }),
  graphql(declinePrivacyAgreementMutation, {
    props: ({ mutate }) => ({
      declinePrivacyAgreement: data =>
        mutate({
          variables: { data },
        }),
    }),
  }),
)(PrivacyTable);
