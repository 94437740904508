import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import s from './Sidebar.scss';
import PanelNavTree from './../PanelNavTree';

class Sidebar extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      userRoles: PropTypes.array.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const { me } = this.props;
    const { userRoles } = me;

    return (
      <div className={s.sideNav}>
        <PanelNavTree me={me} userRoles={userRoles} panelId="sideNav" />
      </div>
    );
  }
}

export default AuthenticationWrapper(withStyles(s)(Sidebar));
