/* eslint-disable import/prefer-default-export */

import { SET_PAGE_INFO } from '../constants';

export function setPageInfo({ path, page, size }) {
  const pageInfo = { path, page, size };
  if (!size) delete pageInfo.size;
  return {
    type: SET_PAGE_INFO,
    payload: pageInfo,
  };
}
