import { defineMessages } from 'react-intl';

const messages = defineMessages({
  imprint: {
    id: 'footer.imprint',
    defaultMessage: 'Imprint',
    description: 'Label for imprint link in footer',
  },
  dataProtectionInformation: {
    id: 'footer.dataProtectionInformation',
    defaultMessage: 'Data Protection Information',
    description: 'Label for data protection information link in footer',
  },
  termsOfService: {
    id: 'footer.termsOfService',
    defaultMessage: 'Terms of Service',
    description: 'Label for terms of service link in footer',
  },
  imprintUrl: {
    id: 'footer.imprintUrl',
    defaultMessage: 'http://www.waltzingatoms.com/3datax',
    description: 'Url for imprint link in footer',
  },
  dataProtectionInformationUrl: {
    id: 'footer.dataProtectionInformationUrl',
    defaultMessage: 'http://www.waltzingatoms.com/privacy',
    description: 'Label for data protection information link in footer',
  },
  termsOfServiceUrl: {
    id: 'footer.termsOfServiceUrl',
    defaultMessage: 'http://www.waltzingatoms.com/terms-conditions',
    description: 'Label for terms of service link in footer',
  },
});

export default messages;
