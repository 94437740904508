import { defineMessages } from 'react-intl';

const messages = defineMessages({
  add: {
    id: 'component.add',
    defaultMessage: 'Add',
    description: 'Add label for component',
  },
  show: {
    id: 'component.show',
    defaultMessage: 'Details',
    description: 'Show label for component',
  },
  edit: {
    id: 'component.edit',
    defaultMessage: 'Edit',
    description: 'Edit label for component',
  },
  delete: {
    id: 'component.delete',
    defaultMessage: 'Delete',
    description: 'Delete label for component',
  },
  actionHeader: {
    id: 'component.actionHeader',
    defaultMessage: 'Action',
    description: 'Action label for component form',
  },
  save: {
    id: 'component.save',
    defaultMessage: 'Save',
    description: 'Save label for component form',
  },
  saveAndClose: {
    id: 'component.saveAndClose',
    defaultMessage: 'Save and close',
    description: 'Save and close label for component form',
  },
  chooseOption: {
    id: 'component.chooseOption',
    defaultMessage: 'Please choose ...',
    description: 'Choose label for input form',
  },
});

export default messages;
