import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import baseStyles from '../../styles/app.scss';
import s from './Layout.scss';
import Header from '../Header';
import Footer from '../Footer';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return [
      <Header key="app-header" />,
      <div key="app-content" className="app-content">
        <div className="container">{this.props.children}</div>
      </div>,
      <Footer key="app-footer" />,
    ];
  }
}

export default withStyles(baseStyles, s)(Layout);
