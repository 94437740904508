import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import FaSignOut from 'react-icons/lib/fa/sign-out';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { withLogout } from 'components/modules/auth/withLogout';

import s from './Header.scss';
import LanguageSwitcher from '../LanguageSwitcher';

class Header extends React.Component {
  static propTypes = {
    user: PropTypes.shape({
      isLoggedIn: PropTypes.bool.isRequired,
    }),
    logout: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: null,
  };

  render() {
    const { user, logout } = this.props;

    return (
      <div className={`container ${s.header}`}>
        <Row>
          <Col xs={12}>
            <span className={s.heading} />
            <div className={`${s.nav} clearfix`}>
              <LanguageSwitcher />
              {user &&
                user.isLoggedIn && (
                  <Link
                    to={`/admin/users/${user.id}/edit/`}
                    className={`btn btn-link ${s.userProfile}`}
                  >
                    {user.username}
                  </Link>
                )}
              {user &&
                user.isLoggedIn && (
                  <Button
                    className={`btn btn-link pull-right ${s.logoutButton}`}
                    onClick={logout}
                  >
                    <FaSignOut />
                  </Button>
                )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(withLogout(withStyles(s)(Header)));
