import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Link } from 'react-router-dom';
import FaSearchPlus from 'react-icons/lib/fa/search-plus';
import FaEdit from 'react-icons/lib/fa/edit';
import FaDelete from 'react-icons/lib/fa/times-circle';
import FaPlusCircle from 'react-icons/lib/fa/plus-circle';
import SweetAlert from 'react-bootstrap-sweetalert';

import messages from './messages';
import Table from '../Table/Table';
import TableStyle from '../Table/Table.scss'; // eslint-disable-line css-modules/no-unused-class
import Loading from '../modules/common/Loading/Loading';

class StaticPagesTable extends React.Component {
  static propTypes = {
    deleteStaticPage: PropTypes.func.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      staticPages: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    };

    this.columns = [
      {
        Header: 'URL',
        accessor: 'url',
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Action',
        Cell: row => (
          <div>
            <Link to={`staticpages/${row.original.id}/show`}>
              <button className={TableStyle.buttonLeft}>
                <FaSearchPlus className="fa--prepended" />
                <FormattedMessage {...messages.show} />
              </button>
            </Link>
            <Link to={`staticpages/${row.original.id}/edit`}>
              <button className={TableStyle.buttonMiddle}>
                <FaEdit className="fa--prepended" />
                <FormattedMessage {...messages.edit} />
              </button>
            </Link>
            <button
              className={TableStyle.buttonRight}
              onClick={() => this.showDeleteStaticPagePropmt(row.original.id)}
            >
              <FaDelete className="fa--prepended" />
              <FormattedMessage {...messages.delete} />
            </button>
          </div>
        ),
      },
    ];

    this.showDeleteStaticPagePropmt = this.showDeleteStaticPagePropmt.bind(
      this,
    );
    this.deleteStaticPageById = this.deleteStaticPageById.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  showDeleteStaticPagePropmt(id) {
    this.setState({
      alertOpts: {
        show: true,
        type: 'warning',
        title: <FormattedMessage {...messages.warningTitle} />,
        msg: <FormattedMessage {...messages.deleteWarningMessage} />,
        showCancel: true,
        onConfirm: () => this.deleteStaticPageById(id),
        onCancel: this.hideAlert,
      },
    });
  }

  async deleteStaticPageById(id) {
    this.hideAlert();

    const result = await this.props.deleteStaticPage(id);

    if (!result || result.status === 401) {
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.errorTitle} />,
          msg: <FormattedMessage {...messages.deleteErrorMessage} />,
          showCancel: false,
          onConfirm: this.hideAlert,
          onCancel: null,
        },
      });
    } else {
      this.setState({
        alertOpts: {
          show: true,
          type: 'success',
          title: <FormattedMessage {...messages.successTitle} />,
          msg: <FormattedMessage {...messages.deleteSuccessMessage} />,
          showCancel: false,
          onConfirm: this.hideAlert,
          onCancel: null,
        },
      });
    }
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        showCancel: false,
        onConfirm: null,
        onCancel: null,
      },
    });
  }

  render() {
    if (this.props.data.loading) {
      return <Loading />;
    }

    return (
      <div>
        <Table
          keyField="id"
          data={this.props.data.staticPages}
          columns={this.columns}
        />
        <Link to="staticpages/new">
          <button className={TableStyle.buttonSingleLarge}>
            <FaPlusCircle className="fa--prepended" />
            <FormattedMessage {...messages.new} />
          </button>
        </Link>
        {this.state.alertOpts.show && (
          <SweetAlert
            type={this.state.alertOpts.type}
            title={this.state.alertOpts.title}
            confirmBtnBsStyle={
              this.state.alertOpts.type === 'warning' ? 'danger' : 'primary'
            }
            cancelBtnBsStyle="default"
            showCancel={this.state.alertOpts.showCancel}
            onConfirm={this.state.alertOpts.onConfirm}
            onCancel={this.state.alertOpts.onCancel}
          >
            {this.state.alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const staticPagesQuery = gql`
  query staticPages {
    staticPages {
      id
      url
      title
    }
  }
`;

const deleteStaticPageMutation = gql`
  mutation deleteStaticPage($id: ID!) {
    deleteStaticPage(id: $id) {
      id
    }
  }
`;

export default compose(
  graphql(staticPagesQuery, {
    name: 'data',
  }),
  graphql(deleteStaticPageMutation, {
    props: ({ mutate }) => ({
      deleteStaticPage: id =>
        mutate({
          variables: { id },
          refetchQueries: [
            {
              query: staticPagesQuery,
            },
          ],
        }),
    }),
  }),
)(withStyles(TableStyle)(StaticPagesTable));
