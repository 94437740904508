import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './LicenseInfo.scss';
import TableStyle from '../Table/Table.scss'; // eslint-disable-line css-modules/no-unused-class
import Loading from '../modules/common/Loading/Loading';
import messages from './messages';

class MSLicenseInfo extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      license: PropTypes.object,
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  render() {
    if (this.props.data.loading) {
      return <Loading />;
    }

    const { license } = this.props.data;
    const productLength = license.products.length;

    const products = license.products.map((e, i) => (
      <div className={i % 2 == 0 ? s.rowEven : s.rowUneven} key={`product${i}`}> {/* eslint-disable-line */}
        <span className={s.label}>
          <FormattedMessage
            {...messages.product}
            values={{ number: `${productLength === 1 ? '' : i + 1}` }}
          />
        </span>
        {e.name}
      </div>
    ));

    const labelStyle = license.enabled
      ? TableStyle.labelYes
      : TableStyle.labelNo;
    const label = (
      <FormattedMessage {...(license.enabled ? messages.yes : messages.no)} />
    );

    return (
      <div>
        <h2>
          <FormattedMessage {...messages.showTitle} />
        </h2>
        <div className={s.rowEven}>
          <span className={s.label}>
            <FormattedMessage {...messages.id} />
          </span>
          {license.id}
        </div>
        <div className={s.rowUneven}>
          <span className={s.label}>
            <FormattedMessage {...messages.name} />
          </span>
          {license.name}
        </div>
        <div className={s.rowEven}>
          <span className={s.label}>
            <FormattedMessage {...messages.shortDescription} />
          </span>
          {license.shortDescription}
        </div>
        <div className={s.rowUneven}>
          <span className={s.label}>
            <FormattedMessage {...messages.description} />
          </span>
          {license.description}
        </div>
        <div className={s.rowEven}>
          <span className={s.label}>
            <FormattedMessage {...messages.price} />
          </span>
          {license.price}
        </div>
        <div className={s.rowUneven}>
          <span className={s.label}>
            <FormattedMessage {...messages.credits} />
          </span>
          {license.credits}
        </div>
        <div className={s.rowEven}>
          <span className={s.label}>
            <FormattedMessage {...messages.validityPeriod} />
          </span>
          {license.validityPeriod}
        </div>
        <div className={s.rowUneven}>
          <span className={s.label}>
            <FormattedMessage {...messages.enabled} />
          </span>
          <div className={labelStyle}> {label} </div>
        </div>
        {products}
      </div>
    );
  }
}

const licenseQuery = gql`
  query license($id: Int!) {
    license(id: $id) {
      id
      name
      shortDescription
      description
      price
      validityPeriod
      credits
      enabled
      products {
        id
        name
      }
    }
  }
`;

export default compose(
  graphql(licenseQuery, {
    name: 'data',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
  }),
)(withStyles(s, TableStyle)(MSLicenseInfo));
