import React from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Loading from 'components/modules/common/Loading/Loading';
import gate from 'components/Auth/gate';

import s from './SubscriptionInfo.scss';
import messages from './messages';

class SubscriptionInfo extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      me: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
      subscription: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
  };

  render() {
    const { loading, me, subscription } = this.props.data;
    if (loading) return <Loading />;

    const isOwner = gate.isSubscriptionOwner({ user: me, subscription });

    return (
      <div>
        <h3>{subscription.license.name}</h3>
        <div className={s.segment}>
          <h4>
            <FormattedMessage {...messages.type} />
          </h4>
          {isOwner ? (
            <FormattedMessage {...messages.typeOwner} />
          ) : (
            <FormattedMessage {...messages.typeShared} />
          )}
          <hr />
        </div>
        {subscription.credits !== null && (
          <div className={s.segment}>
            <h4>
              <FormattedMessage {...messages.credits} />
            </h4>
            {subscription.credits === -1 ? '∞' : subscription.credits}
            <hr />
          </div>
        )}
        <div className={s.segment}>
          <h4>
            <FormattedMessage {...messages.start} />
          </h4>
          {subscription.start}
          <hr />
        </div>
        <div className={s.segment}>
          <h4>
            <FormattedMessage {...messages.end} />
          </h4>
          {subscription.end}
          <hr />
        </div>
        {isOwner && (
          <div>
            <h4>
              <FormattedMessage {...messages.sharedUsers} />
            </h4>
            <ul className={s.shares}>
              {subscription.users.length > 0 &&
                subscription.users.map(user => (
                  <li key={user.id} className="clearfix">
                    <span>{user.email}</span>
                  </li>
                ))}
              {subscription.users.length === 0 && (
                <div>
                  <FormattedMessage {...messages.noEntries} />
                  <hr />
                </div>
              )}
            </ul>
            <h4>
              <FormattedMessage {...messages.pendingInvitations} />
            </h4>
            <ul className={s.shares}>
              {subscription.invites.length > 0 &&
                subscription.invites.map(invite => (
                  <li key={invite} className="clearfix">
                    <span>{invite}</span>
                  </li>
                ))}
              {subscription.invites.length === 0 && (
                <div>
                  <FormattedMessage {...messages.noEntries} />
                  <hr />
                </div>
              )}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

const subscriptionQuery = gql`
  query subscription($id: Int!) {
    me {
      id
      userRoles
    }
    subscription(id: $id) {
      id
      owner {
        id
      }
      license {
        id
        name
        allowedNumberOfUsers
      }
      start
      end
      credits
      users {
        id
      }
      users {
        id
        username
        email
      }
      invites
    }
  }
`;

export default compose(
  graphql(subscriptionQuery, {
    name: 'data',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
  }),
)(withStyles(s)(SubscriptionInfo));
