import { SET_PAGE_INFO } from '../constants';

const initialState = {
  pageCache: {},
};

export default function tablePage(state = initialState, action) {
  switch (action.type) {
    case SET_PAGE_INFO:
      return {
        ...state,
        pageCache: {
          ...state.pageCache,
          [action.payload.path]: Object.assign(
            {},
            state.pageCache[action.payload.path],
            action.payload,
          ),
        },
      };
    default:
      return state;
  }
}
