import { defineMessages } from 'react-intl';

const messages = defineMessages({
  id: {
    id: 'subscription.id',
    defaultMessage: 'Id',
    description: 'Id label for subscription form',
  },
  name: {
    id: 'subscription.name',
    defaultMessage: 'Subscription',
    description: 'Name label for subscription form',
  },
  owner: {
    id: 'subscription.owner',
    defaultMessage: 'Owner',
    description: 'Owner label for subscription form',
  },
  start: {
    id: 'subscription.start',
    defaultMessage: 'Start',
    description: 'Start label for subscription form',
  },
  end: {
    id: 'subscription.end',
    defaultMessage: 'End',
    description: 'End label for subscription form',
  },
  credits: {
    id: 'subscription.credits',
    defaultMessage: 'Credits',
    description: 'Credits label for subscription form',
  },
  active: {
    id: 'subscription.active',
    defaultMessage: 'Active',
    description: 'Active Period label for subscription form',
  },
  showUsers: {
    id: 'subscription.showUsers',
    defaultMessage: 'Show users',
    description: 'Show users label for subscription form',
  },
  inviteUsers: {
    id: 'subscription.inviteUsers',
    defaultMessage: 'Add additional users',
    description: 'Label for invite users button in subscription table',
  },
  subscriptionHeading: {
    id: 'subscription.heading',
    defaultMessage: 'Subscription for {licenseName}',
    description: 'Heading for subscription edit form',
  },
  sharedUsers: {
    id: 'subscription.sharedUsers',
    defaultMessage: 'This subscription is shared with the following users:',
    description: 'Label for shared users in subscription edit form',
  },
  pendingInvitations: {
    id: 'subscription.pendingInvitations',
    defaultMessage: 'Pending invitations',
    description: 'Label for pending invitations in subscription edit form',
  },
  noEntries: {
    id: 'subscription.noEntries',
    defaultMessage: 'No entries.',
    description: 'No entries text in subscription edit form',
  },
  type: {
    id: 'subscription.type',
    defaultMessage: 'Type',
    description: 'Type text in subscription info form',
  },
  typeOwner: {
    id: 'subscription.typeOwner',
    defaultMessage: 'Owner',
    description: 'Owner text in subscription info form',
  },
  typeShared: {
    id: 'subscription.typeShared',
    defaultMessage: 'Shared',
    description: 'Shared text in subscription info form',
  },
  save: {
    id: 'subscription.save',
    defaultMessage: 'Save',
    description: 'Save label for subscription form submit button',
  },
  saveAndClose: {
    id: 'subscription.saveAndClose',
    defaultMessage: 'Save and close',
    description: 'Save and close label for subscription form submit button',
  },
  alertSuccessTitle: {
    id: 'subscription.alertSuccessTitle',
    defaultMessage: 'Success',
    description: 'Header for success alert dialog',
  },
  alertSuccessMessage: {
    id: 'subscription.alertSuccessMessage',
    defaultMessage: 'Subscription successfully updated!',
    description: 'Message for success alert dialog',
  },
  alertErrorTitle: {
    id: 'subscription.alertErrorTitle',
    defaultMessage: 'Error',
    description: 'Header for error alert dialog',
  },
  alertErrorMessage: {
    id: 'subscription.alertErrorMessage',
    defaultMessage: 'There has been an error while updating the subscription!',
    description: 'Message for error alert dialog',
  },
  syncHeading: {
    id: 'subscription.syncHeading',
    defaultMessage: 'Subscription sync settings:',
    description: 'Heading for subscription sync in subscription sync form',
  },
  buySubscription: {
    id: 'subscription.buySubscription',
    defaultMessage: 'Add a subscription',
    description: 'Label for add subscription button',
  },
});

export default messages;
