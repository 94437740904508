/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_PREVIOUS_URL = 'SET_PREVIOUS_URL';
export const CLEAR_PREVIOUS_URL = 'CLEAR_PREVIOUS_URL';
export const SET_PAGE_INFO = 'SET_PAGE_INFO';
