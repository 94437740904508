import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import SweetAlert from 'react-bootstrap-sweetalert';

import messages from './messages';
import s from './EditProduct.scss'; // eslint-disable-line css-modules/no-unused-class
import Loading from '../modules/common/Loading/Loading';
import EditProductForm from './EditProductForm';

class EditProduct extends React.Component {
  static propTypes = {
    updateProduct: PropTypes.func.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      product: PropTypes.object,
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
      closeAfterSaving: false,
    };

    this.submitProductChanges = this.submitProductChanges.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertAndClose = this.hideAlertAndClose.bind(this);
    this.closeAfterSaving = this.closeAfterSaving.bind(this);
  }

  async submitProductChanges(formValues) {
    this.hideAlert();

    const result = await this.props.updateProduct(this.props.match.params.id, {
      key: formValues.key,
      name: formValues.name,
      shortDescription: formValues.shortDescription,
      description: formValues.description,
    });

    if (!result || result.status === 401) {
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.alertErrorTitle} />,
          msg: <FormattedMessage {...messages.alertErrorMessage} />,
          onConfirm: this.hideAlert,
        },
      });
    } else {
      this.setState({
        alertOpts: {
          show: true,
          type: 'success',
          title: <FormattedMessage {...messages.alertSuccessTitle} />,
          msg: <FormattedMessage {...messages.alertSuccessMessage} />,
          onConfirm: this.state.closeAfterSaving
            ? this.hideAlertAndClose
            : this.hideAlert,
        },
      });
    }
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });
  }

  hideAlertAndClose() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });

    if (this.state.closeAfterSaving) {
      this.props.history.push({
        pathname: '/admin/products',
      });
    }
  }

  closeAfterSaving(shouldClose) {
    this.setState({
      closeAfterSaving: shouldClose,
    });
  }

  render() {
    if (this.props.data.loading) {
      return <Loading />;
    }

    const { product } = this.props.data;

    const title = <FormattedMessage {...messages.title} />;

    return (
      <div>
        <h2>{title}</h2>
        <EditProductForm
          onSubmit={this.submitProductChanges}
          closeAfterSaving={this.closeAfterSaving}
          initialValues={product}
        />
        {this.state.alertOpts.show && (
          <SweetAlert
            type={this.state.alertOpts.type}
            title={this.state.alertOpts.title}
            onConfirm={this.state.alertOpts.onConfirm}
          >
            {this.state.alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const productQuery = gql`
  query product($id: Int!) {
    product(id: $id) {
      id
      key
      name
      shortDescription
      description
    }
  }
`;

const updateProductMutation = gql`
  mutation updateProduct($id: ID!, $patch: ProductInput!) {
    createOrUpdateProduct(id: $id, patch: $patch) {
      id
      key
      name
      shortDescription
      description
    }
  }
`;

export default compose(
  graphql(productQuery, {
    name: 'data',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
  }),
  graphql(updateProductMutation, {
    props: ({ mutate }) => ({
      updateProduct: (id, patch) =>
        mutate({
          variables: { id, patch },
        }),
    }),
  }),
)(withStyles(s)(EditProduct));
