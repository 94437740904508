import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import SweetAlert from 'react-bootstrap-sweetalert';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import messages from './messages';
import s from './EditLicense.scss';
import Loading from '../modules/common/Loading/Loading';
import AddLicenseForm from './AddLicenseForm';

class AddLicense extends React.Component {
  static propTypes = {
    createLicense: PropTypes.func.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      products: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
      closeAfterSaving: false,
      licenseId: null,
    };

    this.submitLicenseChanges = this.submitLicenseChanges.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertAndClose = this.hideAlertAndClose.bind(this);
    this.closeAfterSaving = this.closeAfterSaving.bind(this);
  }

  async submitLicenseChanges(formValues) {
    this.hideAlert();
    const productIds = formValues.products.filter(
      v => !_.isEmpty(v) && /^\d+$/.test(v),
    );

    const validityPeriod = `+${formValues.validityNumber} ${
      formValues.validityPeriod
    }`;

    const result = await this.props.createLicense(this.state.licenseId, {
      name: formValues.name,
      shortDescription: formValues.shortDescription,
      description: formValues.description,
      price: formValues.price,
      allowedNumberOfUsers: formValues.allowedNumberOfUsers,
      credits: formValues.credits,
      validityPeriod,
      visible: formValues.visible,
      enabled: formValues.enabled,
      productIds,
    });

    if (!result || result.status === 401) {
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.alertErrorTitle} />,
          msg: <FormattedMessage {...messages.alertErrorMessage} />,
          onConfirm: this.hideAlert,
        },
      });
    } else {
      const { id } = result.data.createOrUpdateLicense;
      this.setState({
        alertOpts: {
          show: true,
          type: 'success',
          title: <FormattedMessage {...messages.alertSuccessTitle} />,
          msg: <FormattedMessage {...messages.alertSuccessMessage} />,
          onConfirm: this.state.closeAfterSaving
            ? this.hideAlertAndClose
            : this.hideAlert,
        },
        licenseId: id,
      });
    }
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });
  }

  hideAlertAndClose() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });

    if (this.state.closeAfterSaving) {
      this.props.history.push({
        pathname: `/admin/licenses/`,
      });
    }
  }

  closeAfterSaving(shouldClose) {
    this.setState({
      closeAfterSaving: shouldClose,
    });
  }

  render() {
    if (this.props.data.loading) {
      return <Loading />;
    }

    const { products } = this.props.data;

    const title = <FormattedMessage {...messages.addTitle} />;

    return (
      <div>
        <h2>{title}</h2>
        <AddLicenseForm
          onSubmit={this.submitLicenseChanges}
          closeAfterSaving={this.closeAfterSaving}
          initialValues={{ products: [{}], visible: false, enabled: false }}
          products={products}
        />
        {this.state.alertOpts.show && (
          <SweetAlert
            type={this.state.alertOpts.type}
            title={this.state.alertOpts.title}
            onConfirm={this.state.alertOpts.onConfirm}
          >
            {this.state.alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const productsQuery = gql`
  query products {
    products {
      id
      name
    }
  }
`;

const createLicenseMutation = gql`
  mutation createLicense($id: ID, $patch: LicenseInput!) {
    createOrUpdateLicense(id: $id, patch: $patch) {
      id
      name
      shortDescription
      description
      allowedNumberOfUsers
      price
      validityPeriod
      credits
      visible
      enabled
    }
  }
`;

export default compose(
  graphql(productsQuery, {
    name: 'data',
  }),
  graphql(createLicenseMutation, {
    props: ({ mutate }) => ({
      createLicense: (id, patch) =>
        mutate({
          variables: { id, patch },
        }),
    }),
  }),
)(withStyles(s)(AddLicense));
