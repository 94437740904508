import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { setLoggedIn } from '../../../actions/user';

function delCookie(name) {
  document.cookie = `${name}=; expires=Thu, 2 Aug 2001 20:47:11 UTC; path=/;`;
}

function withLogout(WrappedComponent) {
  class LogoutWrapper extends React.Component {
    static contextTypes = {
      client: PropTypes.object.isRequired,
    };

    static propTypes = {
      dispatch: PropTypes.func.isRequired,
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    };

    constructor(props) {
      super(props);

      this.logout = this.logout.bind(this);
    }

    logout() {
      delCookie('id_token');
      this.props.dispatch(
        setLoggedIn({
          isLoggedIn: false,
        }),
      );
      this.props.history.push({ pathname: '/logout/login' });
      // eslint-disable-next-line no-restricted-globals
      location.reload(true);
    }

    render() {
      return <WrappedComponent logout={this.logout} {...this.props} />;
    }
  }

  return withRouter(connect()(LogoutWrapper));
}

// eslint-disable-next-line import/prefer-default-export
export { withLogout };
