import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaSave from 'react-icons/lib/fa/floppy-o';
import FaList from 'react-icons/lib/fa/list';

import s from './StaticPageForm.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';
import render from '../modules/form/renderField';
import validations from '../modules/form/validations';
import validationMessages from '../modules/form/messages';

const validate = values => {
  const errors = {};

  if (!validations.required(values.url)) {
    errors.url = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.required(values.title)) {
    errors.title = <FormattedMessage {...validationMessages.required} />;
  }

  return errors;
};

class EditStaticPageForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    closeAfterSaving: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      id: PropTypes.id,
      url: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
    }).isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  render() {
    return (
      <div>
        <form onSubmit={this.props.handleSubmit}>
          <fieldset>
            <Field
              id="url"
              name="url"
              label={<FormattedMessage {...messages.url} />}
              type="text"
              component={render.renderInput}
            />

            <Field
              id="title"
              name="title"
              label={<FormattedMessage {...messages.title} />}
              type="text"
              component={render.renderInput}
            />

            <Field
              id="content"
              name="content"
              label={<FormattedMessage {...messages.content} />}
              component={render.renderTextarea}
            />

            {this.props.errors.length === 0 ? null : (
              <ul style={{ padding: '0', listStyleType: 'none' }}>
                {this.props.errors.map(err => (
                  <li key={err} className="bg-danger">
                    {err}
                  </li>
                ))}
              </ul>
            )}

            <button
              type="submit"
              className="btn btn-primary"
              onClick={formValues => {
                this.props.closeAfterSaving(false);
                this.props.handleSubmit(formValues);
              }}
            >
              <FaSave className="fa--prepended" />
              <FormattedMessage {...messages.save} />
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={formValues => {
                this.props.closeAfterSaving(true);
                this.props.handleSubmit(formValues);
              }}
            >
              <FaSave className="fa--prepended" />
              <FaList className="fa--prepended" />
              <FormattedMessage {...messages.saveAndClose} />
            </button>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'staticPage',
  validate,
  pure: false, // this is necessary to trigger form re-renders if the locale changes
})(injectIntl(withStyles(s)(EditStaticPageForm)));
