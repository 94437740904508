import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import SweetAlert from 'react-bootstrap-sweetalert';

import messages from './messages';
import s from './EditSubscription.scss'; // eslint-disable-line css-modules/no-unused-class
import Loading from '../modules/common/Loading/Loading';
import EditSubscriptionForm from './EditSubscriptionForm';

class EditSubscription extends React.Component {
  static propTypes = {
    updateSubscription: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    subscription: PropTypes.shape({
      license: PropTypes.object,
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        type: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
      closeAfterSaving: false,
    };

    this.submitLicenseChanges = this.submitLicenseChanges.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertAndClose = this.hideAlertAndClose.bind(this);
    this.closeAfterSaving = this.closeAfterSaving.bind(this);
  }

  async submitLicenseChanges(formValues) {
    this.hideAlert();
    const {
      updateSubscription,
      match: { params },
    } = this.props;
    const patch = {
      start: formValues.start.toString(),
      end: formValues.end.toString(),
      credits: formValues.credits,
    };
    const result = await updateSubscription(params.id, patch);

    if (!result || result.status === 401) {
      this.setState({
        alertOpts: {
          show: true,
          type: 'error',
          title: <FormattedMessage {...messages.alertErrorTitle} />,
          msg: <FormattedMessage {...messages.alertErrorMessage} />,
          onConfirm: this.hideAlert,
        },
      });
    } else {
      this.setState({
        alertOpts: {
          show: true,
          type: 'success',
          title: <FormattedMessage {...messages.alertSuccessTitle} />,
          msg: <FormattedMessage {...messages.alertSuccessMessage} />,
          onConfirm: this.state.closeAfterSaving
            ? this.hideAlertAndClose
            : this.hideAlert,
        },
      });
    }
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });
  }

  hideAlertAndClose() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    });
    const { closeAfterSaving } = this.state;
    const {
      history,
      match: { params },
    } = this.props;

    if (closeAfterSaving) {
      history.push({
        pathname: `/admin/subscriptions/${params.type}`,
      });
    }
  }

  closeAfterSaving(shouldClose) {
    this.setState({
      closeAfterSaving: shouldClose,
    });
  }

  render() {
    const {
      loading,
      subscription,
      match: { params },
    } = this.props;

    if (loading || !subscription) {
      return <Loading />;
    }

    let title;
    if (params.type === 'ms') {
      title = 'MS-Office365-AddIn';
    } else if (params.type === 'wal') {
      title = 'WAL-Teacher-Remote';
    }
    const initialValues = {
      ...subscription,
      start: moment(subscription.start),
      end: moment(subscription.end),
    };
    if (subscription.license && subscription.license.name) {
      initialValues.name = subscription.license.name;
    }
    const { alertOpts } = this.state;
    return (
      <div>
        <h2>{title}</h2>
        <EditSubscriptionForm
          onSubmit={this.submitLicenseChanges}
          closeAfterSaving={this.closeAfterSaving}
          initialValues={initialValues}
        />
        {alertOpts.show && (
          <SweetAlert
            type={alertOpts.type}
            title={alertOpts.title}
            onConfirm={alertOpts.onConfirm}
          >
            {alertOpts.msg}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const subscriptionQuery = gql`
  query subscription($id: Int!) {
    subscription(id: $id) {
      id
      owner {
        id
        username
      }
      license {
        id
        name
        allowedNumberOfUsers
      }
      start
      end
      credits
      users {
        id
      }
    }
  }
`;

const updateSubscriptionMutation = gql`
  mutation updateSubscription($id: ID!, $patch: SubscriptionInput!) {
    updateSubscription(id: $id, patch: $patch) {
      id
      owner {
        id
        username
      }
      license {
        id
        name
        allowedNumberOfUsers
      }
      start
      end
      credits
      users {
        id
      }
    }
  }
`;

export default compose(
  graphql(subscriptionQuery, {
    name: 'data',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
    props: ({ data: { subscription, loading } }) => ({
      subscription,
      loading,
    }),
  }),
  graphql(updateSubscriptionMutation, {
    props: ({ mutate }) => ({
      updateSubscription: (id, patch) =>
        mutate({
          variables: { id, patch },
        }),
    }),
  }),
)(withStyles(s)(EditSubscription));
