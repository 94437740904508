import { defineMessages } from 'react-intl';

const messages = defineMessages({
  cardNumber: {
    id: 'payment.cc.number',
    defaultMessage: 'Credit card number',
    description: 'Label for credit card number field in payment form',
  },
  expirationDate: {
    id: 'payment.cc.expirationDate',
    defaultMessage: 'Expiration date',
    description: 'Label for expiration date field in payment form',
  },
  cvc: {
    id: 'payment.cc.cvc',
    defaultMessage: 'CVC',
    description: 'Label for cvc field in payment form',
  },
  zip: {
    id: 'payment.cc.zip',
    defaultMessage: 'Zip',
    description: 'Label for zip field in payment form',
  },
  buyNow: {
    id: 'payment.buyNow',
    defaultMessage: 'Buy now',
    description: 'Label for buy field in payment form',
  },
  license: {
    id: 'payment.license',
    defaultMessage: 'License',
    description: 'Label for license label in payment form',
  },
  product: {
    id: 'payment.product',
    defaultMessage: 'Product',
    description: 'Label for Product filter label in payment form',
  },
  allProdcuts: {
    id: 'payment.allProdcuts',
    defaultMessage: 'All',
    description: 'Label for All Product filter in payment form',
  },
  selectLicense: {
    id: 'payment.selectLicense',
    defaultMessage: 'Select license',
    description: 'Label for license selection in payment form',
  },
  price: {
    id: 'payment.price',
    defaultMessage: 'Price',
    description: 'Label for license price in payment form',
  },
  days: {
    id: 'payment.validityDays',
    defaultMessage: 'Days',
    description: 'Label for days in payment form',
  },
  months: {
    id: 'payment.validityMonths',
    defaultMessage: 'Months',
    description: 'Label for months in payment form',
  },
  year: {
    id: 'payment.validityYear',
    defaultMessage: 'Year',
    description: 'Label for year in payment form',
  },
  years: {
    id: 'payment.validtyYears',
    defaultMessage: 'Years',
    description: 'Label for years in payment form',
  },
  success: {
    id: 'payment.success',
    defaultMessage: 'Success',
    description: 'Label for success header in payment form',
  },
  error: {
    id: 'payment.error',
    defaultMessage: 'Error',
    description: 'Label for error header in payment form',
  },
  purchaseMessage: {
    id: 'payment.purchaseMessage',
    defaultMessage: 'License successfully purchased.',
    description: 'Message for successful license purchase in payment form',
  },
  trialLicenseHeading: {
    id: 'payment.trialLicenseHeading',
    defaultMessage: 'Get your free 30-day trial!',
    description: 'Heading for trial license',
  },
  trialLicenseDescription: {
    id: 'payment.trialLicenseDescription',
    defaultMessage: `
      Click the button below to activate your free 30-day trial license for Waltzing Atoms!
      After 30 days you will have the option to simply upgrade to one of our paid licenses.
    `,
    description: 'description',
  },
  trialLicenseButtonLabel: {
    id: 'payment.trialLicenseButtonLabel',
    defaultMessage: 'Activate 30-day trial',
    description: 'Label for activate trial button',
  },
  trialActivated: {
    id: 'payment.trialActivated',
    defaultMessage: 'Your 30-day trial has been activated successfully!',
    description: 'Success for message for trial activation',
  },
  showPaymentForm: {
    id: 'payment.showPaymentForm',
    defaultMessage: 'I want to purchase a license anyways.',
    description: 'Label for show payment form button',
  },
  showTrialForm: {
    id: 'payment.showTrialForm',
    defaultMessage: 'I want to redeem my free trial license.',
    description: 'Label for show trial form',
  },
});

export default messages;
