import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';

import products from 'core/product';
import { clearPreviousUrl } from 'actions/user';
import { meQuery } from 'components/modules/auth/withLogin';

import PrivacyAgreementForm from './PrivacyAgreementForm';

class PrivacyAgreement extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    user: PropTypes.shape({
      previousUrl: PropTypes.string,
    }).isRequired,
    clearPreviousUrl: PropTypes.func.isRequired,
    acceptPrivacyAgreement: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(formValues) {
    const { privacyPolicyAccepted } = formValues;

    if (privacyPolicyAccepted) {
      const result = await this.props.acceptPrivacyAgreement({
        productId: products.dashboard.id,
      });
      if (result.data.acceptPrivacyAgreement) {
        await this.context.client.query({
          query: meQuery,
          name: 'meData',
          fetchPolicy: 'network-only',
        });
        const {
          user: { previousUrl },
          history,
        } = this.props;
        this.props.clearPreviousUrl();
        history.push(previousUrl || '/dashboard');
      }
    }
  }

  render() {
    return <PrivacyAgreementForm onSubmit={this.handleSubmit} />;
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = {
  clearPreviousUrl,
};

const acceptPrivacyAgreementMutation = gql`
  mutation acceptPrivacyAgreement($data: AcceptPrivacyAgreementInput!) {
    acceptPrivacyAgreement(data: $data)
  }
`;

export default withRouter(
  compose(
    graphql(acceptPrivacyAgreementMutation, {
      props: ({ mutate }) => ({
        acceptPrivacyAgreement: data =>
          mutate({
            variables: { data },
          }),
      }),
    }),
    connect(mapStateToProps, mapDispatchToProps),
  )(PrivacyAgreement),
);
